import CurrencyInput from '@components/CurrencyInput';
import InfoItem from '@components/Licitacao/InfoItem';
import { ESFERAS, PORTAL_NAMES } from '@constants';
import {
  AccountBalance,
  AttachMoney,
  Business,
  Category,
  Description,
  Dns,
  Event,
  EventAvailable,
  ListAlt,
  LocationOn,
  OpenInNew,
  Public as PublicIcon,
  Today,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextareaAutosize,
  Typography,
  useTheme,
} from '@mui/material';
import { useKanbanStore } from '@store/kanbanStore';
import { KanbanLicitacao } from '@types';
import { formatCurrency, formatIsoDate, getLicitacaoStatus } from '@utils';
import React, { useState } from 'react';

interface LicitacaoDetailsModalProps {
  open: boolean;
  onClose: () => void;
  licitacao: KanbanLicitacao;
}

const LicitacaoDetailsModal: React.FC<LicitacaoDetailsModalProps> = ({
  open,
  onClose,
  licitacao,
}) => {
  const theme = useTheme();
  const { updateKanbanLicitacao } = useKanbanStore();
  const [bidValue, setBidValue] = useState(licitacao.bidValue?.toString() || '');
  const [importance, setImportance] = useState(licitacao.importance || '');
  const [notes, setNotes] = useState(licitacao.notes || '');

  const currentStatus = getLicitacaoStatus(
    licitacao.status,
    licitacao.abertura_datetime,
    licitacao.fechamento_datetime,
  );

  const handleSave = () => {
    updateKanbanLicitacao(licitacao.id_licitacao, {
      bidValue: bidValue ? parseFloat(bidValue) : undefined,
      importance: importance as 'low' | 'medium' | 'high' | undefined,
      notes: notes || undefined,
    });
    onClose();
  };

  const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(event.target.value);
  };

  const handleNotesClick = (event: React.MouseEvent<HTMLTextAreaElement>) => {
    event.stopPropagation();
  };

  const handleBidValueChange = (value: string) => {
    setBidValue(value);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {licitacao.titulo}
        <Box
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
          }}
        >
          <Link
            href={`/licitacao/${licitacao.id_licitacao}`}
            target="_blank"
            rel="noopener noreferrer"
            display="flex"
            alignItems="center"
          >
            <OpenInNew fontSize="small" sx={{ mr: 0.5 }} />
            Ver detalhes completos
          </Link>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <InfoItem icon={AccountBalance} label="Órgão" value={licitacao.orgao} />
            <InfoItem
              icon={LocationOn}
              label="Local"
              value={`${licitacao.municipio} - ${licitacao.uf}`}
            />
            <InfoItem
              icon={Business}
              label="Portal"
              value={
                licitacao.portal ||
                (licitacao.id_portal && PORTAL_NAMES[licitacao.id_portal]) ||
                'N/A'
              }
            />
            <InfoItem icon={Category} label="Código CNAE" value={licitacao.cnae || 'N/A'} />
            <InfoItem icon={Business} label="Código UASG" value={licitacao.codigo_uasg || 'N/A'} />
            <InfoItem
              icon={ListAlt}
              label="Número do edital"
              value={licitacao.numero_edital || 'N/A'}
            />
            <InfoItem
              icon={PublicIcon}
              label="Esfera"
              value={ESFERAS[licitacao.esfera] || 'Não informada'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InfoItem
              icon={AttachMoney}
              label="Valor estimado"
              value={licitacao.valor > 0 ? formatCurrency(licitacao.valor) : 'N/A'}
            />
            <InfoItem
              icon={Today}
              label="Data publicação"
              value={formatIsoDate(licitacao.created_at)}
            />
            <InfoItem
              icon={EventAvailable}
              label="Data de abertura"
              value={formatIsoDate(licitacao.abertura_datetime)}
            />
            <InfoItem
              icon={Event}
              label="Data de fechamento"
              value={formatIsoDate(licitacao.fechamento_datetime)}
            />
            <InfoItem icon={Dns} label="Status" value={currentStatus} />
            <InfoItem
              icon={Description}
              label="ID Licitou"
              value={licitacao.id_licitacao}
              copyable
            />
          </Grid>
        </Grid>
        {licitacao.notes && (
          <Box mt={2}>
            <Typography variant="h6" color="primary" gutterBottom>
              Anotações
            </Typography>
            <Typography variant="body1" color="text.primary">
              {licitacao.notes}
            </Typography>
          </Box>
        )}
        <Box mt={2}>
          <Typography variant="h6" gutterBottom>
            Campos personalizados
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CurrencyInput
                fullWidth
                label="Valor do lance"
                value={bidValue}
                onChange={handleBidValueChange}
                InputProps={{
                  startAdornment: <AttachMoney />,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Importância</InputLabel>
                <Select
                  value={importance}
                  onChange={(e) => setImportance(e.target.value as string)}
                  label="Importância"
                >
                  <MenuItem value="">Nenhuma</MenuItem>
                  <MenuItem value="low">Baixa</MenuItem>
                  <MenuItem value="medium">Média</MenuItem>
                  <MenuItem value="high">Alta</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Anotações
              </Typography>
              <TextareaAutosize
                minRows={4}
                placeholder="Adicione uma anotação para esta licitação"
                value={notes}
                onClick={handleNotesClick}
                onChange={handleNotesChange}
                style={{
                  width: '100%',
                  padding: '10px',
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  borderColor: theme.palette.primary.main,
                  borderRadius: '4px',
                  fontFamily: 'inherit',
                  fontSize: 'inherit',
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSave} color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(LicitacaoDetailsModal);
