import { ApiService } from '@services/api';
import { auth } from '@services/firebase';
import { useAuthStore } from '@store/authStore';
import { useProfileStore } from '@store/profileStore';
import { FirebaseError } from 'firebase/app';
import { User } from 'firebase/auth';
import React, { createContext, useCallback, useContext, useEffect, useRef } from 'react';

export interface AuthContextType {
  user: User | null;
  isLoading: boolean;
  error: Error | FirebaseError | null;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  signInWithToken: (token: string) => Promise<void>;
  signOut: () => Promise<void>;
  clearError: () => void;
  getIdToken: (forceRefresh?: boolean) => Promise<string | null>;
  getUser: () => User | null;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    user,
    isLoading,
    error,
    signIn,
    signUp,
    logout,
    clearError,
    setUser,
    getUser,
    signInWithGoogle,
    signInWithToken,
  } = useAuthStore();
  const { loadUserData, setCurrentProfile } = useProfileStore();
  const getIdToken = useCallback(
    async (forceRefresh: boolean = false) => {
      try {
        const currentUser = auth.currentUser;
        if (currentUser) {
          const token = await currentUser.getIdToken(forceRefresh);
          localStorage.setItem('authToken', token);
          return token;
        } else {
          throw new Error('No authenticated user');
        }
      } catch (error) {
        console.error('Error getting ID token:', error);
        await logout();
        return null;
      }
    },
    [logout],
  );

  const initialFetchDone = useRef(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        setUser(firebaseUser);
        try {
          const token = await getIdToken(true);
          if (token && !initialFetchDone.current) {
            initialFetchDone.current = true;
            const userData = await loadUserData();
            if (userData && userData.profiles && userData.profiles.length > 0) {
              const storedProfileId = localStorage.getItem('currentProfileId');
              let profileIdToSet = storedProfileId;

              // If there's no stored profile ID or it doesn't exist in the user's profiles, use the first profile
              if (!profileIdToSet || !userData.profiles.some((p) => p.id === profileIdToSet)) {
                profileIdToSet = userData.profiles[0].id;
              }

              await setCurrentProfile(profileIdToSet);
            }
          }
        } catch (error) {
          console.error('Error during auth state change:', error);
          setUser(null);
          localStorage.removeItem('authToken');
          localStorage.removeItem('currentProfileId');
          initialFetchDone.current = false;
        }
      } else {
        setUser(null);
        localStorage.removeItem('authToken');
        localStorage.removeItem('currentProfileId');
        initialFetchDone.current = false;
      }
    });

    return () => {
      unsubscribe();
      ApiService.setAuthFunctions(null);
    };
  }, [setUser, loadUserData, setCurrentProfile, getIdToken]);

  const value: AuthContextType = {
    user,
    isLoading,
    error,
    signIn,
    signUp,
    signInWithGoogle,
    signOut: logout,
    signInWithToken,
    clearError,
    getIdToken,
    getUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
