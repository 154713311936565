import BackToTop from '@components/BackToTop';
import Header from '@components/Layout/Header';
import LicitacaoCard from '@components/Licitacao/LicitacaoCard';
import LicitacoesSearchBox from '@components/Licitacao/LicitacoesSearchBox';
import OnboardingTour from '@components/Onboarding/OnboardingTour';
import { useLicitacoes } from '@hooks/useLicitacoes';
import { useOnboarding } from '@hooks/useOnboarding';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { Licitacao, MyLicitacoes } from '@types';
import React, { useEffect, useState } from 'react';

const Licitacoes: React.FC = () => {
  const theme = useTheme();
  const {
    licitacoes,
    isLoading,
    error,
    hasMore,
    totalLicitacoes,
    refreshing,
    initialSearchValues,
    isSearching,
    searchKeywords,
    handleLoadMore,
    handleRefresh,
    handleSearch,
    clearAllFilters,
  } = useLicitacoes();

  const [rerenderTrigger, setRerenderTrigger] = useState(0);

  useEffect(() => {
    setRerenderTrigger((prev) => prev + 1);
  }, [searchKeywords]);

  const { runTour, handleJoyrideCallback, steps } = useOnboarding();

  return (
    <Box
      sx={{
        minHeight: '100vh',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.default,
      }}
    >
      <OnboardingTour run={runTour} steps={steps} callback={handleJoyrideCallback} />
      <Container maxWidth="lg">
        <Header className="header-menu" dataTourValue="config-button" />
        <LicitacoesSearchBox
          handleSearch={handleSearch}
          clearAllFilters={clearAllFilters}
          isLoading={isLoading || isSearching}
          initialValues={initialSearchValues}
          className="search-box"
        />
        <Box display="flex" justifyContent="space-between" alignItems="center" my={4}>
          {isLoading ? (
            <CircularProgress color="primary" />
          ) : (
            <Typography variant="subtitle1" sx={{ mb: 2, color: theme.palette.text.secondary }}>
              {totalLicitacoes === 10000
                ? 'Mais de 10.000 licitações encontradas'
                : `${totalLicitacoes} licitações encontradas`}
            </Typography>
          )}
          <IconButton
            onClick={handleRefresh}
            disabled={isLoading || refreshing}
            sx={{ color: theme.palette.primary.main, marginLeft: 'auto' }}
          >
            <RefreshIcon />
          </IconButton>
        </Box>
        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
        <Grid container spacing={2}>
          {licitacoes.map((item: Licitacao | MyLicitacoes, index: number) => (
            <LicitacaoCard
              key={`${item.id_licitacao}-${rerenderTrigger}`}
              item={item}
              isFirst={index === 0}
              searchKeywords={searchKeywords}
            />
          ))}
        </Grid>
        {(isLoading || isSearching) && (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress color="primary" />
          </Box>
        )}
        {hasMore && !isLoading && (
          <Box display="flex" justifyContent="center" mt={2}>
            <Button
              variant="contained"
              onClick={handleLoadMore}
              className="load-more-button"
              disabled={isLoading}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
            >
              Carregar Mais
            </Button>
          </Box>
        )}
        {!hasMore && (
          <Box display="flex" justifyContent="center" mt={2}>
            <Typography color="info" sx={{ mt: 2 }}>
              Você chegou ao fim dos resultados.
            </Typography>
          </Box>
        )}
        <BackToTop />
      </Container>
    </Box>
  );
};

export default Licitacoes;
