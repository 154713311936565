import { useTheme } from '@mui/material';
import React from 'react';
import Joyride, { CallBackProps } from 'react-joyride';

interface OnboardingTourProps {
  run: boolean;
  steps: any[];
  callback: (data: CallBackProps) => void;
}

const OnboardingTour: React.FC<OnboardingTourProps> = ({ run, steps, callback }) => {
  const theme = useTheme();

  return (
    <Joyride
      steps={steps}
      run={run}
      continuous={true}
      showSkipButton={true}
      showProgress={true}
      callback={callback}
      locale={{
        back: 'Voltar',
        close: 'Fechar',
        last: 'Último',
        next: 'Próximo',
        nextLabelWithProgress: 'Próximo ({step} de {steps})',
        open: 'Abrir o diálogo',
        skip: 'Pular',
      }}
      styles={{
        options: {
          primaryColor: theme.palette.primary.main,
        },
      }}
    />
  );
};

export default OnboardingTour;
