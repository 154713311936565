import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { useFollowingStore } from '@store/followingStore';
import { useKanbanStore } from '@store/kanbanStore';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

interface AddLicitacaoModalProps {
  open: boolean;
  onClose: () => void;
  columnId: string;
}

const AddLicitacaoModal: React.FC<AddLicitacaoModalProps> = ({ open, onClose, columnId }) => {
  const { followingLicitacoes, fetchFollowingLicitacoes } = useFollowingStore();
  const { addKanbanLicitacao, board } = useKanbanStore();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLicitacoes, setSelectedLicitacoes] = useState<string[]>([]);

  useEffect(() => {
    if (open) {
      fetchFollowingLicitacoes();
    }
  }, [open, fetchFollowingLicitacoes]);

  // Get all licitacoes currently on the board
  const boardLicitacoes = useMemo(() => {
    const licitacoesSet = new Set<string>();
    board.columns.forEach((column) => {
      column.licitacoes?.forEach((licitacao) => {
        licitacoesSet.add(licitacao.id_licitacao);
      });
    });
    return licitacoesSet;
  }, [board.columns]);

  // Filter out licitacoes that are already on the board and match the search term
  const filteredLicitacoes = useMemo(() => {
    return followingLicitacoes.filter(
      (licitacao) =>
        !boardLicitacoes.has(licitacao.id_licitacao) &&
        licitacao.titulo.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [followingLicitacoes, boardLicitacoes, searchTerm]);

  const handleAddLicitacoes = useCallback(async () => {
    for (const licitacaoId of selectedLicitacoes) {
      await addKanbanLicitacao(columnId, licitacaoId, {});
    }
    onClose();
    setSelectedLicitacoes([]);
    setSearchTerm('');
  }, [selectedLicitacoes, addKanbanLicitacao, onClose, columnId]);

  const handleToggleLicitacao = useCallback((licitacaoId: string) => {
    setSelectedLicitacoes((prev) =>
      prev.includes(licitacaoId) ? prev.filter((id) => id !== licitacaoId) : [...prev, licitacaoId],
    );
  }, []);

  const handleClose = useCallback(() => {
    onClose();
    setSelectedLicitacoes([]);
    setSearchTerm('');
  }, [onClose]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Adicionar Licitação ao Quadro Kanban</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <InfoIcon color="info" sx={{ mr: 1 }} />
          <Typography variant="body2" color="info.main">
            Adicione licitações que você está acompanhando ao quadro Kanban.
          </Typography>
        </Box>
        {filteredLicitacoes.length === 0 ? (
          <Box sx={{ textAlign: 'center', py: 4 }}>
            <Typography variant="h6" color="text.secondary" gutterBottom>
              Não há licitações acompanhadas disponíveis para adicionar.
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Todas as licitações que você está acompanhando já estão no quadro ou não correspondem
              à pesquisa.
            </Typography>
          </Box>
        ) : (
          <>
            <TextField
              autoFocus
              margin="dense"
              label="Pesquisar Licitações"
              type="text"
              fullWidth
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <List>
              {filteredLicitacoes.map((licitacao) => (
                <ListItem key={licitacao.id_licitacao}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedLicitacoes.includes(licitacao.id_licitacao)}
                        onChange={() => handleToggleLicitacao(licitacao.id_licitacao)}
                      />
                    }
                    label={<ListItemText primary={licitacao.titulo} secondary={licitacao.objeto} />}
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button
          onClick={handleAddLicitacoes}
          color="primary"
          disabled={selectedLicitacoes.length === 0}
        >
          Adicionar Selecionados
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(AddLicitacaoModal);
