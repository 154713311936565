import { Alert, Button, Snackbar } from '@mui/material';
import { useProfileStore } from '@store/profileStore';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const OnboardingReminder: React.FC = () => {
  const { company, userPreferences, userData } = useProfileStore();
  const [open, setOpen] = React.useState(true);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const isCompanyInfoComplete = company && company.cnaePrincipal && company.cnaePrincipal.id;
  const isPreferencesComplete = userPreferences && userPreferences.companySummary;

  let message = '';
  let link = '';

  if (userData?.onboardingComplete) {
    return null;
  }

  if (!isCompanyInfoComplete) {
    message = 'Complete suas informações de empresa para melhorar sua experiência.';
    link = '/configuracoes/empresa?onboarding=1';
  } else if (!isPreferencesComplete) {
    message = 'Configure suas preferências para obter recomendações personalizadas.';
    link = '/configuracoes/preferencias?onboarding=1';
  }

  return (
    <Snackbar open={open} autoHideDuration={30000} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity="info"
        sx={{ width: '100%' }}
        action={
          <Button color="inherit" size="small" component={RouterLink} to={link}>
            Completar
          </Button>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default OnboardingReminder;
