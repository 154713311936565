import Api from '@services/api';
import { Bulletin, LicitacoesAbertasResponse, LicitacoesSearchParams } from '@types';
import { create } from 'zustand';

interface BulletinState {
  bulletins: Bulletin[];
  currentBulletinLicitacoes: LicitacoesAbertasResponse | null;
  isLoading: boolean;
  error: string | null;
  fetchBulletins: (date?: string) => Promise<void>;
  createBulletin: (name: string, searchParams: LicitacoesSearchParams) => Promise<void>;
  fetchBulletinLicitacoes: (
    bulletinId: string,
    date?: string,
    page?: number,
    licitacoesPerPage?: number,
  ) => Promise<void>;
  updateBulletin: (
    bulletinId: string,
    updates: Partial<{ name: string; description: string; searchParams: LicitacoesSearchParams }>,
  ) => Promise<void>;
  deleteBulletin: (bulletinId: string) => Promise<void>;
  resetStore: () => void;
}

export const useBulletinStore = create<BulletinState>((set, get) => ({
  bulletins: [], // Initialize with an empty array
  currentBulletinLicitacoes: null,
  isLoading: false,
  error: null,
  fetchBulletins: async (date?: string) => {
    if (!get().isLoading) {
      set({ isLoading: true, error: null });
      try {
        const bulletins = await Api.getBulletins(date);
        if (Array.isArray(bulletins)) {
          set({ bulletins: bulletins || [], isLoading: false });
        } else {
          console.error('Unexpected response format:', bulletins);
          set({ error: 'Formato de resposta inesperado', isLoading: false, bulletins: [] });
        }
      } catch (error) {
        console.error('Error fetching bulletins:', error);
        set({ error: 'Falha ao buscar boletins', isLoading: false, bulletins: [] });
      }
    }
  },
  createBulletin: async (name: string, searchParams: LicitacoesSearchParams) => {
    set({ isLoading: true, error: null });
    try {
      const newBulletin = await Api.createBulletin(name, searchParams);
      set((state) => ({
        bulletins: [...state.bulletins, newBulletin],
        isLoading: false,
      }));

      // After creating a new bulletin, fetch all bulletins to ensure the list is up-to-date
      await get().fetchBulletins();
    } catch (error) {
      console.error('Error creating bulletin:', error);
      set({ error: 'Falha ao criar boletim', isLoading: false });
    }
  },
  fetchBulletinLicitacoes: async (
    bulletinId: string,
    date?: string,
    page?: number,
    licitacoesPerPage?: number,
  ) => {
    set({ isLoading: true, error: null });
    try {
      const licitacoes = await Api.getBulletinLicitacoes(bulletinId, date, page, licitacoesPerPage);
      set({ currentBulletinLicitacoes: licitacoes, isLoading: false });
    } catch (error) {
      console.error('Error fetching bulletin licitacoes:', error);
      set({ error: 'Falha ao buscar licitações do boletim', isLoading: false });
    }
  },
  updateBulletin: async (
    bulletinId: string,
    updates: Partial<{ name: string; description: string; searchParams: LicitacoesSearchParams }>,
  ) => {
    set({ isLoading: true, error: null });
    try {
      const updatedBulletin = await Api.updateBulletin(bulletinId, updates);
      set((state) => ({
        bulletins: state.bulletins.map((b) => (b.id === bulletinId ? updatedBulletin : b)),
        isLoading: false,
      }));
    } catch (error) {
      console.error('Error refreshing licitacoes:', error);
      set({ error: 'Falha ao atualizar boletim', isLoading: false });
    }
  },
  deleteBulletin: async (bulletinId: string) => {
    set({ isLoading: true, error: null });
    try {
      await Api.deleteBulletin(bulletinId);
      set((state) => ({
        bulletins: state.bulletins.filter((b) => b.id !== bulletinId),
        isLoading: false,
      }));
    } catch (error) {
      console.error('Error deleting bulletin:', error);
      set({ error: 'Falha ao deletar boletim', isLoading: false });
    }
  },
  resetStore: () =>
    set({
      bulletins: [],
      currentBulletinLicitacoes: null,
      isLoading: false,
      error: null,
    }),
}));
