import Header from '@components/Layout/Header';
import { Box, Container, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { useFavoritesStore } from '@store/favoritesStore';
import { useFollowingStore } from '@store/followingStore';
import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const MinhasLicitacoes: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { favorites } = useFavoritesStore();
  const { following } = useFollowingStore();

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    navigate(`/minhas-licitacoes/${newValue}`);
  };

  const value = location.pathname.split('/').pop() || 'favoritas';

  const TabLabel: React.FC<{ label: string; count: number }> = ({ label, count }) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {label}
      <Box
        component="span"
        sx={{
          marginLeft: '8px',
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          borderRadius: '12px',
          padding: '2px 6px',
          fontSize: '0.75rem',
          minWidth: '20px',
          textAlign: 'center',
          lineHeight: 1,
        }}
      >
        {count}
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Container maxWidth="lg">
        <Header />
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom color="primary">
            Minhas Licitações
          </Typography>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="minhas licitacoes tabs">
              <Tab
                label={<TabLabel label="Favoritas" count={favorites.length} />}
                value="favoritas"
              />
              <Tab
                label={<TabLabel label="Acompanhando" count={following.length} />}
                value="acompanhando"
              />
              <Tab label="Agenda" value="agenda" />
            </Tabs>
          </Box>
          <Box sx={{ p: 3 }}>
            <Outlet />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default MinhasLicitacoes;
