import { Box, TextareaAutosize } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFollowingStore } from '@store/followingStore';
import React, { useEffect, useState } from 'react';

interface FollowingAnnotationProps {
  licitacaoId: string;
}

const FollowingAnnotation: React.FC<FollowingAnnotationProps> = ({ licitacaoId }) => {
  const theme = useTheme();
  const { isFollowing, updateFollowingAnnotation, getFollowingAnnotation } = useFollowingStore();
  const [annotation, setAnnotation] = useState('');

  useEffect(() => {
    setAnnotation(getFollowingAnnotation(licitacaoId));
  }, [licitacaoId, getFollowingAnnotation]);

  const handleAnnotationChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAnnotation(event.target.value);
  };

  const handleAnnotationBlur = () => {
    if (isFollowing(licitacaoId)) {
      updateFollowingAnnotation(licitacaoId, annotation);
    }
  };

  const handleAnnotationClick = (event: React.MouseEvent<HTMLTextAreaElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  if (!isFollowing(licitacaoId)) {
    return null;
  }

  return (
    <Box sx={{ mt: 2 }}>
      <TextareaAutosize
        minRows={2}
        placeholder="Adicione uma anotação para esta licitação"
        value={annotation}
        onClick={handleAnnotationClick}
        onChange={handleAnnotationChange}
        onBlur={handleAnnotationBlur}
        style={{
          width: '100%',
          padding: '10px',
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          borderColor: theme.palette.primary.main,
          borderRadius: '4px',
          fontFamily: 'inherit',
          fontSize: 'inherit',
        }}
      />
    </Box>
  );
};

export default FollowingAnnotation;
