import { useAuth } from '@hooks/useAuth';
import BusinessIcon from '@mui/icons-material/Business';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PaymentIcon from '@mui/icons-material/Payment';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import TuneIcon from '@mui/icons-material/Tune';
import { Avatar, Box, Divider, Menu, MenuItem, Switch, Typography } from '@mui/material';
import { useColorScheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const UserMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const { mode, setMode } = useColorScheme();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    handleClose();
  };

  const handleColorModeToggle = () => {
    setMode(mode === 'light' ? 'dark' : 'light');
  };

  const handleLogout = () => {
    signOut();
    handleClose();
  };

  return (
    <>
      <Box onClick={handleMenu} sx={{ cursor: 'pointer' }}>
        <Avatar src={user?.photoURL || undefined} alt={user?.email || 'Usuário'}>
          {!user?.photoURL && <PersonIcon />}
        </Avatar>
      </Box>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem disabled>
          <Typography variant="body2">{user?.email}</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleNavigation('/configuracoes/preferencias')}>
          <TuneIcon fontSize="small" style={{ marginRight: 8 }} />
          Preferências
        </MenuItem>
        <MenuItem onClick={() => handleNavigation('/configuracoes/empresa')}>
          <BusinessIcon fontSize="small" style={{ marginRight: 8 }} />
          Empresa
        </MenuItem>
        <MenuItem onClick={() => handleNavigation('/configuracoes/pagamento')}>
          <PaymentIcon fontSize="small" style={{ marginRight: 8 }} />
          Pagamento
        </MenuItem>
        <MenuItem onClick={() => handleNavigation('/configuracoes/perfil')}>
          <PersonIcon fontSize="small" style={{ marginRight: 8 }} />
          Perfil
        </MenuItem>
        <MenuItem>
          <DarkModeIcon fontSize="small" style={{ marginRight: 8 }} />
          Modo escuro
          <Switch
            checked={mode === 'dark'}
            onChange={handleColorModeToggle}
            color="primary"
            size="small"
            style={{ marginLeft: 'auto' }}
          />
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleNavigation('/configuracoes')}>
          <SettingsIcon fontSize="small" style={{ marginRight: 8 }} />
          Configurações
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ExitToAppIcon fontSize="small" style={{ marginRight: 8 }} />
          Sair
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
