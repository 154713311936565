import { LicitacoesOnboardingSteps } from '@constants';
import { useProfileStore } from '@store/profileStore';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useOnboarding = () => {
  const [runTour, setRunTour] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { setOnboardingComplete } = useProfileStore();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('onboarding') === '1') {
      setRunTour(true);
    }
  }, [location.search]);

  const handleJoyrideCallback = useCallback(
    (data: any) => {
      const { status } = data;
      if (status === 'finished' || status === 'skipped') {
        console.log('Tour finished');

        // Set onboarding as complete
        setOnboardingComplete();

        // Remove the onboarding parameter from the URL
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete('onboarding');

        // Construct the new URL
        const newSearch = searchParams.toString();
        const newPath = newSearch ? `${location.pathname}?${newSearch}` : location.pathname;

        // Replace the current URL without the onboarding parameter
        navigate(newPath, { replace: true });
      }
    },
    [location.pathname, location.search, navigate, setOnboardingComplete],
  );

  return {
    runTour,
    handleJoyrideCallback,
    steps: LicitacoesOnboardingSteps,
  };
};
