import NewBulletin from '@components/Bulletin/NewBulletin';
import Header from '@components/Layout/Header';
import LicitacaoCard from '@components/Licitacao/LicitacaoCard';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { useBulletinStore } from '@store/bulletinStore';
import { useProfileStore } from '@store/profileStore';
import { LicitacoesSearchParams } from '@types';
import React, { useCallback, useEffect, useState } from 'react';

const LICITACOES_PER_PAGE = 20;

const Bulletin: React.FC = () => {
  const theme = useTheme();
  const {
    bulletins,
    currentBulletinLicitacoes,
    isLoading,
    error,
    fetchBulletins,
    fetchBulletinLicitacoes,
    createBulletin,
  } = useBulletinStore();
  const [selectedBulletinId, setSelectedBulletinId] = useState<string | null>(null);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const { userPreferences, company } = useProfileStore();
  const [initialSearchParams, setInitialSearchParams] = useState<LicitacoesSearchParams | null>(
    null,
  );

  const fetchBulletinsData = useCallback(() => {
    fetchBulletins(selectedDate);
  }, [fetchBulletins, selectedDate]);

  useEffect(() => {
    fetchBulletinsData();
  }, [fetchBulletinsData]);

  useEffect(() => {
    if (selectedBulletinId) {
      fetchBulletinLicitacoes(selectedBulletinId, selectedDate, 1, LICITACOES_PER_PAGE);
      setCurrentPage(1);
    }
  }, [selectedBulletinId, fetchBulletinLicitacoes, selectedDate]);

  const handleRefresh = () => {
    fetchBulletinsData();
    if (selectedBulletinId) {
      fetchBulletinLicitacoes(selectedBulletinId, selectedDate, 1, LICITACOES_PER_PAGE);
      setCurrentPage(1);
    }
  };

  const handleLoadMore = () => {
    if (selectedBulletinId && currentBulletinLicitacoes) {
      const nextPage = currentPage + 1;
      fetchBulletinLicitacoes(selectedBulletinId, selectedDate, nextPage, LICITACOES_PER_PAGE);
      setCurrentPage(nextPage);
    }
  };

  const hasMore = currentBulletinLicitacoes
    ? currentBulletinLicitacoes.licitacoes.length < currentBulletinLicitacoes.totalLicitacoes
    : false;

  const handleCreateBulletin = async (name: string, searchParams: LicitacoesSearchParams) => {
    await createBulletin(name, searchParams);
    setIsCreateDialogOpen(false);
  };

  const handleCreateFromPreferences = () => {
    if (userPreferences) {
      let cnaes: string[] = [];
      if (company?.cnaePrincipal) {
        cnaes = [company.cnaePrincipal.id];
        if (company.cnaesSecundarias) {
          cnaes = [...cnaes, ...company.cnaesSecundarias.map((cnae) => cnae.id)];
        }
      }
      const searchParams: LicitacoesSearchParams = {
        palavra_chave: userPreferences.keywords,
        cnae: cnaes,
        uf: userPreferences.states,
        municipio_ibge: userPreferences.cities,
        modalidade: userPreferences.modalidades,
        id_portal: userPreferences.portals,
        esfera: userPreferences.esferas,
      };
      setInitialSearchParams(searchParams);
      setIsCreateDialogOpen(true);
    }
  };

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: theme.palette.background.default }}>
      <Container maxWidth="lg">
        <Header />
        <Box display="flex" justifyContent="space-between" alignItems="center" my={4}>
          <Typography variant="h4" component="h1" color="primary">
            Boletins
          </Typography>
          <Box>
            <input
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              style={{ marginRight: '1rem' }}
            />
            <IconButton
              onClick={handleRefresh}
              disabled={isLoading}
              sx={{ color: theme.palette.primary.main }}
            >
              <RefreshIcon />
            </IconButton>
            <Button
              startIcon={<AddIcon />}
              onClick={() => setIsCreateDialogOpen(true)}
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
            >
              Criar Boletim
            </Button>
          </Box>
        </Box>
        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
        {isLoading ? (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress color="primary" />
          </Box>
        ) : !bulletins || bulletins.length === 0 ? (
          <Paper
            elevation={3}
            sx={{
              p: 3,
              mt: 4,
              backgroundColor: theme.palette.background.paper,
              borderRadius: 2,
            }}
          >
            <Box display="flex" alignItems="center" mb={2}>
              <InfoIcon color="info" sx={{ mr: 2, fontSize: 40 }} />
              <Typography variant="h6" color="info.main">
                Nenhum boletim encontrado
              </Typography>
            </Box>
            <Typography color="text.secondary" paragraph>
              Você ainda não criou nenhum boletim. Os boletins ajudam a organizar e acompanhar
              licitações de seu interesse.
            </Typography>
            <Typography color="text.secondary" paragraph>
              Ao criar um boletim, você poderá definir critérios de busca e visualizar licitações
              relevantes para datas específicas.
            </Typography>
            <Typography color="text.secondary">
              Crie seu primeiro boletim para começar a acompanhar oportunidades de forma mais
              eficiente.
            </Typography>
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Button
                startIcon={<AddIcon />}
                onClick={() => setIsCreateDialogOpen(true)}
                variant="contained"
                color="primary"
              >
                Criar Novo Boletim
              </Button>
              <Button
                onClick={handleCreateFromPreferences}
                variant="outlined"
                color="secondary"
                disabled={!userPreferences}
              >
                Criar Boletim com Minhas Preferências
              </Button>
            </Box>
          </Paper>
        ) : (
          <Grid container spacing={2}>
            {bulletins.map((bulletin, index) =>
              bulletin && bulletin.id ? (
                <Grid item xs={12} sm={6} md={4} key={bulletin.id}>
                  <Paper
                    elevation={3}
                    sx={{
                      p: 2,
                      cursor: 'pointer',
                      backgroundColor:
                        selectedBulletinId === bulletin.id
                          ? theme.palette.action.selected
                          : theme.palette.background.paper,
                    }}
                    onClick={() => setSelectedBulletinId(bulletin.id)}
                  >
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                      <Typography variant="h6">{bulletin.name}</Typography>
                      <Chip
                        label={`${bulletin.licitacoesCount} licitações`}
                        color="primary"
                        size="small"
                      />
                    </Box>
                  </Paper>
                </Grid>
              ) : (
                <Grid item xs={12} key={index}>
                  <Typography color="error">Invalid bulletin data at index {index}</Typography>
                </Grid>
              ),
            )}
          </Grid>
        )}
        {selectedBulletinId && currentBulletinLicitacoes && (
          <Box mt={4}>
            <Typography variant="h5" mb={2}>
              Licitações do Boletim
            </Typography>
            <Grid container spacing={2}>
              {currentBulletinLicitacoes.licitacoes.map((item, index) => (
                <LicitacaoCard
                  key={item.id_licitacao}
                  item={item}
                  isFirst={index === 0}
                  searchKeywords={[]}
                />
              ))}
            </Grid>
            {hasMore && !isLoading && (
              <Box display="flex" justifyContent="center" mt={2}>
                <Button
                  variant="contained"
                  onClick={handleLoadMore}
                  disabled={isLoading}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    '&:hover': {
                      backgroundColor: theme.palette.primary.dark,
                    },
                  }}
                >
                  Carregar Mais
                </Button>
              </Box>
            )}
            {!hasMore && (
              <Box display="flex" justifyContent="center" mt={2}>
                <Typography color="info" sx={{ mt: 2 }}>
                  Você chegou ao fim dos resultados.
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Container>
      <NewBulletin
        open={isCreateDialogOpen}
        onClose={() => {
          setIsCreateDialogOpen(false);
          setInitialSearchParams(null);
        }}
        onCreateBulletin={handleCreateBulletin}
        initialSearchParams={initialSearchParams}
      />
    </Box>
  );
};

export default Bulletin;
