import { useLicitacoesStore } from '@store/licitacoesStore';
import { useProfileStore } from '@store/profileStore';
import { LicitacoesSearchParams } from '@types';
import { parseSearchParams } from '@utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
export const useLicitacoes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { licitacoes, isLoading, error, hasMore, totalLicitacoes, debouncedFetchLicitacoes } =
    useLicitacoesStore();
  const { userPreferences } = useProfileStore();

  const [refreshing, setRefreshing] = useState(false);
  const [searchParams, setSearchParams] = useState<LicitacoesSearchParams>({});
  const [initialSearchValues, setInitialSearchValues] = useState<Partial<LicitacoesSearchParams>>(
    {},
  );
  const [isSearching, setIsSearching] = useState(false);
  const [searchKeywords, setSearchKeywords] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const initialLoadDone = useRef(false);
  const lastLoadedStateRef = useRef<{ params: LicitacoesSearchParams; page: number } | null>(null);

  const loadLicitacoes = useCallback(
    (pageNum: number, params: LicitacoesSearchParams, shouldRefresh: boolean = false) => {
      const cleanParams = {
        ...params,
        pagina: pageNum,
        licitacoesPorPagina: 20,
      };

      setIsSearching(true);
      debouncedFetchLicitacoes(cleanParams, shouldRefresh);

      const newState = {
        licitacoesState: {
          page: pageNum,
          searchParams: params,
        },
      };
      window.history.replaceState(newState, '', window.location.pathname + window.location.search);

      setPage(pageNum);
      setIsSearching(false);
    },
    [debouncedFetchLicitacoes],
  );

  // Handle clicking load more button

  const handleLoadMore = useCallback(() => {
    if (!isLoading && hasMore && !error) {
      const nextPage = page + 1;
      const newParams = { ...searchParams, pagina: nextPage };
      debouncedFetchLicitacoes(newParams, false);
      setPage(nextPage);
    }
  }, [isLoading, hasMore, error, page, searchParams, debouncedFetchLicitacoes]);

  // Handle clicking refresh button

  const handleRefresh = useCallback(async () => {
    setRefreshing(true);
    await loadLicitacoes(1, searchParams, true);
    setRefreshing(false);
  }, [loadLicitacoes, searchParams]);

  // Modify the handleSearch function

  const handleSearch = useCallback(
    async (params: Partial<LicitacoesSearchParams>) => {
      setSearchParams(params as LicitacoesSearchParams);
      lastLoadedStateRef.current = null;

      if (params.palavra_chave) {
        setSearchKeywords(
          Array.isArray(params.palavra_chave) ? params.palavra_chave : [params.palavra_chave],
        );
      } else {
        setSearchKeywords(userPreferences?.keywords || []);
      }

      try {
        await loadLicitacoes(1, params as LicitacoesSearchParams, true);

        const searchParams = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((v) => searchParams.append(key, v.toString()));
          } else if (value) {
            searchParams.append(key, value.toString());
          }
        });
        navigate({ search: searchParams.toString() }, { replace: true });
      } catch (error) {
        console.error('Error searching licitacoes:', error);
      }
    },
    [loadLicitacoes, navigate, userPreferences?.keywords],
  );

  // Clear all filters

  const clearAllFilters = useCallback(() => {
    setSearchParams({});
    setInitialSearchValues({});
    loadLicitacoes(1, {}, true);
    navigate({ search: '' });
  }, [loadLicitacoes, navigate]);

  // Modify the effect that handles initial load

  useEffect(() => {
    if (initialLoadDone.current) return;

    const historyState = window.history.state?.licitacoesState;
    const urlParams = parseSearchParams(location.search);

    let initialParams: Partial<LicitacoesSearchParams> = {};

    if (historyState?.searchParams && Object.keys(historyState.searchParams).length > 0) {
      initialParams = historyState.searchParams;
      setPage(historyState.page);
    } else if (Object.keys(urlParams).length > 0) {
      initialParams = urlParams as LicitacoesSearchParams;
    } else if (userPreferences) {
      initialParams = {
        uf: userPreferences.states || [],
        municipio_ibge: userPreferences.cities || [],
        modalidade: userPreferences.modalidades || [],
        palavra_chave: userPreferences.keywords || [],
        id_portal: userPreferences.portals || [],
        esfera: userPreferences.esferas || [], // Add this line
      };
    }

    setInitialSearchValues(initialParams);
    setSearchParams(initialParams as LicitacoesSearchParams);
    setSearchKeywords(initialParams.palavra_chave || userPreferences?.keywords || []);
    loadLicitacoes(historyState?.page || 1, initialParams as LicitacoesSearchParams, false);

    initialLoadDone.current = true;
  }, [location.search, userPreferences, loadLicitacoes]);

  return {
    licitacoes,
    isLoading,
    error,
    hasMore,
    totalLicitacoes,
    refreshing,
    searchParams,
    initialSearchValues,
    isSearching,
    searchKeywords,
    page,
    handleLoadMore,
    handleRefresh,
    handleSearch,
    clearAllFilters,
  };
};
