import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { IconButton, SxProps, Tooltip } from '@mui/material';
import { useFavoritesStore } from '@store/favoritesStore';
import React from 'react';

interface FavoriteButtonProps {
  licitacaoId: string;
  isAlreadyFavorite?: boolean;
  className?: string;
  sx?: SxProps;
}

const FavoriteButton: React.FC<FavoriteButtonProps> = ({
  licitacaoId,
  isAlreadyFavorite,
  className,
  sx,
}) => {
  const { isFavorite, addFavorite, removeFavorite } = useFavoritesStore();
  const isFav = !isAlreadyFavorite ? isFavorite(licitacaoId) : true;

  const handleToggleFavorite = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (isFav) {
      removeFavorite(licitacaoId);
    } else {
      addFavorite(licitacaoId);
    }
  };

  return (
    <Tooltip title={isFav ? 'Remover dos favoritos' : 'Adicionar aos favoritos'} sx={sx || {}}>
      <IconButton onClick={handleToggleFavorite} size="small" className={className}>
        {isFav ? <StarIcon sx={{ color: 'yellow' }} /> : <StarBorderIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default FavoriteButton;
