import cnaes from '@data/CNAE.json';
import estados from '@data/estados.json';
import cidades from '@data/municipios.json';
import {
  Bulletin,
  City,
  CNAE,
  Company,
  Favorite,
  FirestoreUser,
  Following,
  KanbanBoard,
  KanbanLicitacao,
  Licitacao,
  LicitacoesAbertasResponse,
  LicitacoesSearchParams,
  MyLicitacoesResponse,
  PaymentInfo,
  UASG,
  UserPreferences,
  UserProfile,
} from '@types';
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
  isAxiosError,
} from 'axios';

const API_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL.replace(/\/+$/, '')
  : '';

export interface AuthFunctions {
  getIdToken: (forceRefresh?: boolean) => Promise<string | null>;
  signOut: () => Promise<void>;
}

class Api {
  private api: AxiosInstance;
  private static authFunctions: AuthFunctions | null = null;
  private currentProfileId: string | null = null;

  constructor() {
    this.api = axios.create({
      baseURL: `${API_URL}/api`,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.setupInterceptors();
  }

  private setupInterceptors() {
    this.api.interceptors.request.use(
      async (config: InternalAxiosRequestConfig) => {
        const token = localStorage.getItem('authToken');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }

        if (config.params && config.params.profileId) {
          config.headers['X-Profile-ID'] = config.params.profileId;
          delete config.params.profileId;
        } else if (this.currentProfileId) {
          config.headers['X-Profile-ID'] = this.currentProfileId;
        }

        return config;
      },
      (error: any) => {
        return Promise.reject(error);
      },
    );
  }

  static setAuthFunctions(functions: AuthFunctions | null) {
    Api.authFunctions = functions;
  }

  private async refreshToken(): Promise<string | null> {
    if (Api.authFunctions) {
      try {
        const newToken = await Api.authFunctions.getIdToken(true);
        if (newToken) {
          localStorage.setItem('authToken', newToken);
          return newToken;
        }
      } catch (error) {
        console.error('Error refreshing token:', error);
        await Api.authFunctions.signOut();
      }
    }
    return null;
  }

  private async handleResponse<T>(response: AxiosResponse<T> | any): Promise<any> {
    if (response && response.status >= 200 && response.status < 300) {
      return response.data;
    } else if (isAxiosError(response)) {
      const config = response.config as AxiosRequestConfig;
      if (
        response.response?.status === 401 &&
        config.headers &&
        config.headers['X-Retry-Request'] !== 'true'
      ) {
        console.log('Received 401 error, attempting to refresh token');
        try {
          const newToken = await this.refreshToken();
          if (newToken) {
            console.log('Token refreshed successfully, retrying request');
            this.api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;

            // Set a custom header to indicate this is a retry

            config.headers['X-Retry-Request'] = 'true';

            // Add a small delay before retrying

            await new Promise((resolve) => setTimeout(resolve, 1000));
            return this.api(config);
          } else {
            console.log('Failed to refresh token');
          }
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
        }
      }
      const errorMessage =
        response.response?.data?.error || `Erro na chamada da API: ${response.response?.status}`;
      throw new Error(errorMessage);
    }
    throw new Error('Erro inesperado na chamada da API');
  }

  setCurrentProfileId(profileId: string) {
    this.currentProfileId = profileId;
  }

  async getLicitacoes(
    params: LicitacoesSearchParams,
    profileId?: string,
  ): Promise<LicitacoesAbertasResponse> {
    const response = await this.api.post<LicitacoesAbertasResponse>('/licitacoes/search', params, {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async getLicitacao(id: string, profileId?: string): Promise<Licitacao> {
    const response = await this.api.get<Licitacao>(`/licitacoes/${id}`, {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async getCompany(
    cnpj: string,
    profileId?: string,
  ): Promise<Company | { error: string; status?: number; details?: string }> {
    const response = await this.api.get<Company>(`/company/check/${cnpj}`, {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async saveUserCompany(
    companyInfo: Company,
    isOnboarding: boolean = false,
    profileId?: string,
  ): Promise<void> {
    const response = await this.api.post(
      '/profile/save',
      { companyInfo, isOnboarding },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result;
  }

  async saveUserData(user: FirestoreUser, profileId?: string): Promise<void> {
    const response = await this.api.post(
      '/auth/save-user-data',
      { userData: user },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result;
  }

  async createProfile(profile: Partial<UserProfile>): Promise<UserProfile> {
    const response = await this.api.post('/profile/create', profile);
    const result = await this.handleResponse(response);
    return result.profile;
  }

  async updateProfile(profileId: string, updates: Partial<UserProfile>): Promise<UserProfile> {
    const response = await this.api.post(`/profile/update/${profileId}`, updates);
    const result = await this.handleResponse(response);
    return result;
  }

  async deleteProfile(profileId: string): Promise<void> {
    const response = await this.api.delete(`/profile/delete/${profileId}`);
    const result = await this.handleResponse(response);
    return result;
  }

  async getUserProfiles(): Promise<UserProfile[]> {
    const response = await this.api.get('/profile/profiles');
    const result = await this.handleResponse(response);
    return result;
  }

  async searchUASG(query: string, profileId?: string): Promise<UASG[]> {
    const response = await this.api.get<{ uasgs: UASG[] }>('/company/uasg/search', {
      params: { query, profileId },
    });
    const result = await this.handleResponse(response);
    return result.uasgs as UASG[];
  }

  async searchUASGByCity(municipio: string, profileId?: string): Promise<UASG[]> {
    const response = await this.api.get<{ uasgs: UASG[] }>('/company/uasg/search', {
      params: { municipio, profileId },
    });
    const result = await this.handleResponse(response);
    return result.uasgs as UASG[];
  }

  async saveUserPreferences(
    preferences: UserPreferences,
    isOnboarding: boolean = false,
    profileId?: string,
  ): Promise<void> {
    const response = await this.api.post(
      '/profile/save',
      { preferences, isOnboarding },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result;
  }

  async getAllStates(): Promise<any[]> {
    return estados;
  }

  async getCitiesByStateId(id: number): Promise<any> {
    return cidades.filter((cidade) => cidade.codigo_uf === id);
  }

  getCitiesByStateUF(stateUF: string): City[] {
    return cidades.filter((cidade) => cidade.uf === stateUF);
  }

  getCitiesByMunicipioIBGE(municipioIBGE: number): City {
    return cidades.filter((cidade) => cidade.codigo_ibge === municipioIBGE)[0] as City;
  }

  async getCnaes(): Promise<CNAE[]> {
    return cnaes;
  }

  async getMyLicitacoes(profileId?: string): Promise<MyLicitacoesResponse> {
    const response = await this.api.get<MyLicitacoesResponse>('/my-licitacoes', {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async getUserData(profileId?: string): Promise<FirestoreUser> {
    const response = await this.api.get<FirestoreUser>('/auth/user-data', {
      params: { profileId: profileId || this.currentProfileId },
    });
    return this.handleResponse(response);
  }

  async getLicitacaoDetails(id: string, profileId?: string): Promise<Licitacao> {
    const response = await this.api.get<Licitacao>(`/licitacoes/details/${id}`, {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async getFavorites(profileId?: string): Promise<Favorite[]> {
    const response = await this.api.get<{ favorites: Favorite[] }>('/favorites', {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result.favorites;
  }

  async addFavorite(licitacaoId: string, profileId?: string): Promise<Favorite> {
    const response = await this.api.post<{ favorite: Favorite }>(
      '/favorites',
      {
        licitacaoId,
      },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.favorite;
  }

  async removeFavorite(licitacaoId: string, profileId?: string): Promise<void> {
    const response = await this.api.delete(`/favorites/${licitacaoId}`, {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async checkFavoriteStatus(licitacaoId: string, profileId?: string): Promise<boolean> {
    const response = await this.api.get<{ isFavorite: boolean }>(`/favorites/${licitacaoId}`, {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result.isFavorite;
  }

  async getFavoritesLicitacoes(profileId?: string): Promise<Licitacao[]> {
    const response = await this.api.get<{ favorites: Licitacao[] }>('/favorites/licitacoes', {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result.favorites;
  }

  async getFollowing(profileId?: string): Promise<Following[]> {
    const response = await this.api.get<{ following: Following[] }>('/following', {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result.following;
  }

  async addFollowing(licitacaoId: string, profileId?: string): Promise<Following> {
    const response = await this.api.post<{ following: Following }>(
      '/following',
      {
        licitacaoId,
      },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.following;
  }

  async removeFollowing(licitacaoId: string, profileId?: string): Promise<void> {
    const response = await this.api.delete(`/following/${licitacaoId}`, {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async checkFollowingStatus(licitacaoId: string, profileId?: string): Promise<boolean> {
    const response = await this.api.get<{ isFollowing: boolean }>(`/following/${licitacaoId}`, {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result.isFollowing;
  }

  async getFollowingLicitacoes(profileId?: string): Promise<Licitacao[]> {
    const response = await this.api.get<{ following: Licitacao[] }>('/following/licitacoes', {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result.following;
  }

  async updateFollowingAnnotation(
    licitacaoId: string,
    annotation: string,
    profileId?: string,
  ): Promise<void> {
    const response = await this.api.post(
      `/following/${licitacaoId}/annotation`,
      {
        annotation,
      },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result;
  }

  async searchLicitacoesByUASG(uasgCode: string, profileId?: string): Promise<Licitacao[]> {
    const response = await this.api.get<{ licitacoes: Licitacao[] }>(
      `/licitacoes/uasg/${uasgCode}`,
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.licitacoes;
  }

  async searchLicitacoesByItem(
    item: string,
    state: string = '',
    profileId?: string,
  ): Promise<Licitacao[]> {
    const response = await this.api.get<{ licitacoes: Licitacao[] }>('/licitacoes/search-item', {
      params: { itemKeyword: item, state, profileId },
    });
    const result = await this.handleResponse(response);
    return result.licitacoes;
  }

  async getPaymentInfo(profileId?: string): Promise<PaymentInfo> {
    const response = await this.api.get<PaymentInfo>('/payments/info', {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async cancelSubscription(
    subscriptionId: string,
    profileId?: string,
  ): Promise<{ status: string; cancelAtPeriodEnd: boolean }> {
    const response = await this.api.post<{ status: string; cancelAtPeriodEnd: boolean }>(
      '/payments/cancel-subscription',
      { subscriptionId },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result;
  }

  async updatePaymentMethod(paymentMethodId: string, profileId?: string): Promise<void> {
    const response = await this.api.post(
      '/payments/update-method',
      { paymentMethodId },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result;
  }

  async reactivateSubscription(
    subscriptionId: string,
    profileId?: string,
  ): Promise<{ success: boolean; subscription: any }> {
    const response = await this.api.post<{ success: boolean; subscription: any }>(
      '/payments/reactivate-subscription',
      { subscriptionId },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result;
  }

  async setOnboardingComplete(profileId?: string): Promise<void> {
    const response = await this.api.post('/auth/set-onboarding-complete', null, {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async updateFavoriteAnnotation(
    licitacaoId: string,
    annotation: string,
    profileId?: string,
  ): Promise<void> {
    const response = await this.api.post(
      `/favorites/${licitacaoId}/annotation`,
      {
        annotation,
      },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result;
  }

  async checkUserPlanHistory(profileId?: string): Promise<boolean> {
    const response = await this.api.get<{ hasHadActivePlan: boolean }>(
      '/payments/check-plan-history',
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.hasHadActivePlan;
  }

  async addPaymentMethod(paymentMethodId: string, profileId?: string): Promise<void> {
    const response = await this.api.post(
      '/payments/add-method',
      { paymentMethodId },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result;
  }

  async getBulletins(date?: string, profileId?: string): Promise<Bulletin[]> {
    const response = await this.api.get<{ bulletins: Bulletin[] }>('/bulletin', {
      params: { date, profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async createBulletin(
    name: string,
    searchParams: LicitacoesSearchParams,
    profileId?: string,
  ): Promise<Bulletin> {
    const response = await this.api.post<{ bulletin: Bulletin }>(
      '/bulletin',
      {
        name,
        searchParams,
      },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.bulletin;
  }

  async getBulletinLicitacoes(
    bulletinId: string,
    date?: string,
    page?: number,
    licitacoesPerPage?: number,
    profileId?: string,
  ): Promise<LicitacoesAbertasResponse> {
    const response = await this.api.get<LicitacoesAbertasResponse>(
      `/bulletin/${bulletinId}/licitacoes`,
      {
        params: {
          date,
          pagina: page,
          licitacoesPorPagina: licitacoesPerPage,
          profileId,
        },
      },
    );
    const result = await this.handleResponse(response);
    return result;
  }

  async updateBulletin(
    bulletinId: string,
    updates: Partial<{ name: string; description: string; searchParams: LicitacoesSearchParams }>,
    profileId?: string,
  ): Promise<Bulletin> {
    const response = await this.api.put<{ bulletin: Bulletin }>(
      `/bulletin/${bulletinId}`,
      updates,
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.bulletin;
  }

  async deleteBulletin(bulletinId: string, profileId?: string): Promise<void> {
    const response = await this.api.delete(`/bulletin/${bulletinId}`, {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  // Kanban Board methods

  async getKanbanBoard(profileId?: string): Promise<KanbanBoard> {
    const response = await this.api.get<{ board: KanbanBoard }>('/kanban/board', {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result.board;
  }

  async addKanbanLicitacao(
    columnId: string,
    licitacaoId: string,
    extraData: Partial<KanbanLicitacao>,
    profileId?: string,
  ): Promise<KanbanBoard> {
    const response = await this.api.post<{ board: KanbanBoard }>(
      '/kanban/licitacao',
      {
        columnId,
        licitacaoId,
        ...extraData,
      },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.board;
  }

  async updateKanbanLicitacao(
    licitacaoId: string,
    updates: Partial<KanbanLicitacao>,
    profileId?: string,
  ): Promise<KanbanBoard> {
    const response = await this.api.put<{ board: KanbanBoard }>(
      `/kanban/licitacao/${licitacaoId}`,
      updates,
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.board;
  }

  async removeKanbanLicitacao(licitacaoId: string, profileId?: string): Promise<KanbanBoard> {
    const response = await this.api.delete<{ board: KanbanBoard }>(
      `/kanban/licitacao/${licitacaoId}`,
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.board;
  }

  async moveKanbanLicitacao(
    licitacaoId: string,
    newColumnId: string,
    newPosition: number,
    profileId?: string,
  ): Promise<KanbanBoard> {
    const response = await this.api.post<{ board: KanbanBoard }>(
      `/kanban/licitacao/${licitacaoId}/move`,
      {
        newColumnId,
        newPosition,
      },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.board;
  }
}

const api = new Api();
export { Api as ApiService };
export default api;
