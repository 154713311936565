import api from '@services/api';
import { CurrentPlan, PaymentInfo, PaymentMethod } from '@types';
import { create } from 'zustand';

interface PaymentState {
  currentPlans: CurrentPlan[];
  nextBillingDates: string[];
  paymentHistory: PaymentInfo['paymentHistory'];
  paymentMethods: PaymentMethod[];
  isLoading: boolean;
  error: string | null;
  setError: (error: string | null) => void;
  fetchPaymentInfo: () => Promise<void>;
  updatePaymentMethod: (paymentMethodId: string) => Promise<void>;
  addPaymentMethod: (paymentMethodId: string) => Promise<void>;
  cancelSubscription: (subscriptionId: string) => Promise<void>;
  reactivateSubscription: (subscriptionId: string) => Promise<void>;
  resetStore: () => void;
}

export const formatStatus = (status: string, cancelAtPeriodEnd: boolean): string => {
  switch (status) {
    case 'trialing':
    case 'active':
      return cancelAtPeriodEnd ? 'Ativo (Cancelamento agendado)' : 'Ativo';
    case 'canceled':
      return 'Cancelado';
    case 'incomplete':
      return 'Incompleto';
    case 'incomplete_expired':
      return 'Expirado';
    case 'past_due':
      return 'Pagamento atrasado';
    // case 'trialing':
    //   return 'Em período de teste';
    case 'unpaid':
      return 'Não pago';
    default:
      return 'Desconhecido';
  }
};

export const usePaymentStore = create<PaymentState>((set) => ({
  currentPlans: [],
  nextBillingDates: [],
  paymentHistory: [],
  paymentMethods: [],
  isLoading: false,
  error: null,
  setError: (error) => set({ error }),

  fetchPaymentInfo: async () => {
    set({ isLoading: true, error: null });
    try {
      const paymentInfo = await api.getPaymentInfo();
      set({
        currentPlans: paymentInfo.currentPlans,
        nextBillingDates: paymentInfo.nextBillingDates,
        paymentHistory: paymentInfo.paymentHistory,
        paymentMethods: paymentInfo.paymentMethods,
        isLoading: false,
      });
    } catch (error) {
      console.error('Error fetching payment info:', error);
      set({ error: 'Erro ao buscar informações de pagamento', isLoading: false });
    }
  },

  updatePaymentMethod: async (paymentMethodId: string) => {
    set({ isLoading: true, error: null });
    try {
      await api.updatePaymentMethod(paymentMethodId);
      await usePaymentStore.getState().fetchPaymentInfo();
      set({ isLoading: false });
    } catch (error) {
      console.error('Error updating payment method:', error);
      set({
        error: error instanceof Error ? error.message : 'Erro ao atualizar o método de pagamento',
        isLoading: false,
      });
    }
  },

  addPaymentMethod: async (paymentMethodId: string) => {
    set({ isLoading: true, error: null });
    try {
      await api.addPaymentMethod(paymentMethodId);
      await usePaymentStore.getState().fetchPaymentInfo();
      set({ isLoading: false });
    } catch (error) {
      console.error('Error adding payment method:', error);
      set({
        error: error instanceof Error ? error.message : 'Erro ao adicionar o método de pagamento',
        isLoading: false,
      });
    }
  },

  cancelSubscription: async (subscriptionId: string) => {
    set({ isLoading: true, error: null });
    try {
      const result = await api.cancelSubscription(subscriptionId);
      set((state) => ({
        currentPlans: state.currentPlans.map((plan) =>
          plan.id === subscriptionId
            ? {
                ...plan,
                status: result.status,
                cancelAtPeriodEnd: result.status === 'active',
              }
            : plan,
        ),
        isLoading: false,
      }));
    } catch (error) {
      console.log('error', error);
      set({ error: 'Erro ao cancelar a assinatura', isLoading: false });
    }
  },

  reactivateSubscription: async (subscriptionId: string) => {
    set({ isLoading: true, error: null });
    try {
      await api.reactivateSubscription(subscriptionId);
      await usePaymentStore.getState().fetchPaymentInfo();
      set({ isLoading: false });
    } catch (error) {
      console.log('error', error);
      set({ error: 'Erro ao reativar a assinatura', isLoading: false });
    }
  },

  resetStore: () =>
    set({
      currentPlans: [],
      nextBillingDates: [],
      paymentHistory: [],
      paymentMethods: [],
      isLoading: false,
      error: null,
    }),
}));
