import { Box, Button, Typography, useTheme } from '@mui/material';
import { useFollowingStore } from '@store/followingStore';
import { Licitacao } from '@types';
import { addHours, format, getDay, parse, startOfWeek } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useEffect } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useNavigate } from 'react-router-dom';

const locales = {
  'pt-BR': ptBR,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const AgendaLicitacoes: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { followingLicitacoes, fetchFollowingLicitacoes, isLoading, error } = useFollowingStore();

  useEffect(() => {
    fetchFollowingLicitacoes();
  }, [fetchFollowingLicitacoes]);

  // Convert followingLicitacoes to events for the calendar
  const events = followingLicitacoes.map((licitacao: Licitacao) => ({
    id: licitacao.id_licitacao,
    title: licitacao.titulo,
    start: new Date(licitacao.abertura_datetime || ''),
    end: addHours(new Date(licitacao.abertura_datetime || ''), 1),
    allDay: false,
    resource: licitacao.id_licitacao, // Store the ID here for navigation
  }));

  const handleEventClick = (event: any) => {
    navigate(`/licitacao/${event.resource}`);
  };

  if (isLoading) {
    return <Typography>Carregando...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ height: 'calc(100vh - 200px)', mt: 2 }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '100%' }}
        views={['month']}
        defaultView="month"
        onSelectEvent={handleEventClick}
        eventPropGetter={() => ({
          style: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          },
        })}
        messages={{
          next: 'Próximo',
          previous: 'Anterior',
          today: 'Hoje',
          month: 'Mês',
          week: 'Semana',
          day: 'Dia',
        }}
        components={{
          toolbar: (props) => (
            <Box
              sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Box>
                <Button
                  onClick={() => props.onNavigate('PREV')}
                  variant="outlined"
                  size="small"
                  sx={{ mr: 1 }}
                >
                  Anterior
                </Button>
                <Button onClick={() => props.onNavigate('NEXT')} variant="outlined" size="small">
                  Próximo
                </Button>
              </Box>
              <Typography variant="h6" color="primary">
                {props.label}
              </Typography>
              <Button onClick={() => props.onNavigate('TODAY')} variant="contained" size="small">
                Hoje
              </Button>
            </Box>
          ),
        }}
        formats={{
          dayFormat: 'dd',
          monthHeaderFormat: (date) => format(date, 'MMMM yyyy', { locale: ptBR }),
          dayHeaderFormat: (date) => format(date, 'cccc', { locale: ptBR }),
          dayRangeHeaderFormat: ({ start, end }) =>
            `${format(start, 'dd MMM', { locale: ptBR })} - ${format(end, 'dd MMM', { locale: ptBR })}`,
        }}
        dayPropGetter={(date) => {
          const isToday = date.toDateString() === new Date().toDateString();
          return {
            style: {
              backgroundColor: isToday
                ? theme.palette.action.selected
                : theme.palette.background.paper,
              color: theme.palette.text.primary,
            },
          };
        }}
        slotPropGetter={() => ({
          style: {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.divider}`,
          },
        })}
      />
    </Box>
  );
};

export default AgendaLicitacoes;
