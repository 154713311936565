import { getModalidadeName } from '@constants';
import { Event as EventIcon } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import { Licitacao, MyLicitacoes } from '@types';
import React, { useState } from 'react';

type CombinedLicitacao = Licitacao & MyLicitacoes;

interface CalendarButtonProps {
  licitacao: CombinedLicitacao;
}

const getGoogleCalendarLink = (licitacao: CombinedLicitacao) => {
  const text = encodeURIComponent(`Licitação: ${licitacao.titulo}`);
  const details = encodeURIComponent(
    `Órgão: ${licitacao.orgao}\nModalidade: ${licitacao.tipo || getModalidadeName(licitacao.id_tipo)}\nObjeto: ${licitacao.objeto}`,
  );

  let dates = '';
  if (licitacao.abertura_datetime) {
    try {
      const date = new Date(licitacao.abertura_datetime);
      if (!isNaN(date.getTime())) {
        const formattedDate = date.toISOString().replace(/-|:|\.\d\d\d/g, '');
        dates = encodeURIComponent(`${formattedDate}/${formattedDate}`);
      }
    } catch (error) {
      console.error('Error parsing abertura_datetime:', error);
    }
  }

  const location = encodeURIComponent(`${licitacao.municipio} - ${licitacao.uf}`);
  return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${text}&details=${details}&dates=${dates}&location=${location}`;
};

const getOutlookCalendarLink = (licitacao: CombinedLicitacao) => {
  const startDate = new Date(licitacao.abertura_datetime || new Date());
  const endDate = new Date(startDate.getTime() + 60 * 60 * 1000); // Assume 1 hour duration
  const subject = encodeURIComponent(`Licitação: ${licitacao.titulo}`);
  const body = encodeURIComponent(`Detalhes da licitação: ${window.location.href}`);
  const location = encodeURIComponent(`${licitacao.municipio} - ${licitacao.uf}`);

  return `https://outlook.live.com/calendar/0/deeplink/compose?subject=${subject}&body=${body}&startdt=${startDate.toISOString()}&enddt=${endDate.toISOString()}&location=${location}&path=/calendar/action/compose&rru=addevent`;
};

const CalendarButton: React.FC<CalendarButtonProps> = ({ licitacao }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button variant="contained" color="primary" startIcon={<EventIcon />} onClick={handleClick}>
        Adicionar à agenda
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            window.open(getGoogleCalendarLink(licitacao), '_blank');
            handleClose();
          }}
        >
          Google Calendar
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open(getOutlookCalendarLink(licitacao), '_blank');
            handleClose();
          }}
        >
          Outlook Calendar
        </MenuItem>
      </Menu>
    </>
  );
};

export default CalendarButton;
