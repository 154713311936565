import LicitacaoCard from '@components/Licitacao/LicitacaoCard';
import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { useFavoritesStore } from '@store/favoritesStore';
import React, { useCallback, useEffect, useState } from 'react';

const FavoriteLicitacoes: React.FC = () => {
  const theme = useTheme();
  const { favoriteLicitacoes, fetchFavoritesLicitacoes, isLoading, error } = useFavoritesStore();
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    fetchFavoritesLicitacoes();
  }, [fetchFavoritesLicitacoes]);

  const handleRefresh = useCallback(async () => {
    setRefreshing(true);
    await fetchFavoritesLicitacoes();
    setRefreshing(false);
  }, [fetchFavoritesLicitacoes]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6" component="h2" color="primary">
          Licitações Favoritas
        </Typography>
        <IconButton
          onClick={handleRefresh}
          disabled={isLoading || refreshing}
          sx={{ color: theme.palette.primary.main }}
        >
          <RefreshIcon />
        </IconButton>
      </Box>
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      <Grid container spacing={2}>
        {favoriteLicitacoes.map((item, index) => (
          <LicitacaoCard
            key={item.id_licitacao}
            item={item}
            isFirst={index === 0}
            searchKeywords={[]}
          />
        ))}
      </Grid>
      {(isLoading || refreshing) && (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress color="primary" />
        </Box>
      )}
      {!isLoading && !error && favoriteLicitacoes.length === 0 && (
        <Paper
          elevation={3}
          sx={{
            p: 3,
            mt: 4,
            backgroundColor: theme.palette.background.paper,
            borderRadius: 2,
          }}
        >
          <Box display="flex" alignItems="center" mb={2}>
            <InfoIcon color="info" sx={{ mr: 2, fontSize: 40 }} />
            <Typography variant="h6" color="info.main">
              Você ainda não tem licitações favoritas
            </Typography>
          </Box>
          <Typography color="text.secondary" paragraph>
            Ao navegar pelas licitações, você pode marcar as que mais lhe interessam como favoritas.
          </Typography>
          <Typography color="text.secondary">
            Isso facilitará o acompanhamento das oportunidades mais relevantes para você. Explore as
            licitações disponíveis e comece a criar sua lista de favoritos!
          </Typography>
        </Paper>
      )}
    </Box>
  );
};

export default FavoriteLicitacoes;
