import { Box, CircularProgress } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { useEffect, useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';

import { useColorScheme } from '@mui/material/styles';
import AgendaLicitacoes from '@pages/AgendaLicitacoes';
import Bulletin from '@pages/Bulletin'; // Add this import at the top of the file
import CompanyInfo from '@pages/CompanyInfo';
import FavoriteLicitacoes from '@pages/FavoriteLicitacoes';
import FollowingLicitacoes from '@pages/FollowingLicitacoes';
import KanbanBoard from '@pages/KanbanBoard';
import LicitacaoDetails from '@pages/LicitacaoDetails';
import Licitacoes from '@pages/Licitacoes';
import Login from '@pages/Login';
import MinhasLicitacoes from '@pages/MinhasLicitacoes';
import Payments from '@pages/Payments';
import Preferences from '@pages/Preferences';
import RecommendedLicitacoes from '@pages/RecommendedLicitacoes';
import Settings from '@pages/Settings';
import UserProfile from '@pages/UserProfile';

import PrivateRoute from '@components/PrivateRoute';
import { AuthProvider, useAuth } from '@contexts/AuthContext';
import AppTheme from '@theme/AppTheme';

function AppContent() {
  const { user, isLoading, signInWithToken, getUser } = useAuth();
  const [isProcessingToken, setIsProcessingToken] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleSignInWithToken = async () => {
      const urlParams = new URLSearchParams(location.search);
      const token = urlParams.get('token');
      if (token && !isProcessingToken) {
        setIsProcessingToken(true);
        try {
          console.log('signInWithToken');
          await signInWithToken(token);
          console.warn('signInWithToken done');
        } catch (error) {
          console.error('Error signing in with token:', error);
        } finally {
          console.log('setIsProcessingToken(false)');
          setIsProcessingToken(false);
        }
      }
    };

    handleSignInWithToken();
  }, [location.search, signInWithToken, isProcessingToken]);

  if (isLoading || isProcessingToken) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <Routes>
      <Route path="/login" element={user ? <Navigate replace to="/licitacoes" /> : <Login />} />
      <Route
        path="/configuracoes"
        element={
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        }
      >
        <Route path="preferencias" element={<Preferences />} />
        <Route path="empresa" element={<CompanyInfo />} />
        <Route path="pagamento" element={<Payments />} />
        <Route path="perfil" element={<UserProfile />} />
        <Route index element={<Navigate replace to="preferencias" />} />
      </Route>
      <Route
        path="/licitacoes"
        element={
          <PrivateRoute>
            <Licitacoes />
          </PrivateRoute>
        }
      />
      <Route
        path="/recomendadas"
        element={
          <PrivateRoute>
            <RecommendedLicitacoes />
          </PrivateRoute>
        }
      />
      <Route
        path="/minhas-licitacoes"
        element={
          <PrivateRoute>
            <MinhasLicitacoes />
          </PrivateRoute>
        }
      >
        <Route path="favoritas" element={<FavoriteLicitacoes />} />
        <Route path="acompanhando" element={<FollowingLicitacoes />} />
        <Route path="agenda" element={<AgendaLicitacoes />} />
        <Route index element={<Navigate replace to="favoritas" />} />
      </Route>
      <Route
        path="/licitacao/:id_licitacao"
        element={
          <PrivateRoute>
            <LicitacaoDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/boletins"
        element={
          <PrivateRoute>
            <Bulletin />
          </PrivateRoute>
        }
      />
      <Route
        path="/kanban"
        element={
          <PrivateRoute>
            <KanbanBoard />
          </PrivateRoute>
        }
      />
      <Route
        path="/"
        element={
          isProcessingToken ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
              <CircularProgress size={60} />
            </Box>
          ) : (
            <Navigate replace to={getUser() ? '/licitacoes' : '/login'} />
          )
        }
      />
    </Routes>
  );
}

function AppWithRouter() {
  const { mode } = useColorScheme();
  const location = useLocation();

  const isLoginPage = location.pathname === '/login';
  const themeMode = isLoginPage ? 'dark' : mode;

  return (
    <AppTheme mode={themeMode}>
      <AppContent />
    </AppTheme>
  );
}

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AuthProvider>
        <Router>
          <AppWithRouter />
        </Router>
      </AuthProvider>
    </LocalizationProvider>
  );
}

export default App;
