import {
  Add as AddIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Check as CheckIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  TextField,
} from '@mui/material';
import { useProfileStore } from '@store/profileStore';
import { UserProfile } from '@types';
import React, { useEffect, useState } from 'react';

const ProfileSelect: React.FC = () => {
  const { profiles, currentProfileId, setCurrentProfile, addProfile, updateProfile } =
    useProfileStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [newProfileName, setNewProfileName] = useState('');
  const [isAddingProfile, setIsAddingProfile] = useState(false);
  const [editingProfileId, setEditingProfileId] = useState<string | null>(null);
  const [selectedProfileId, setSelectedProfileId] = useState<string | null>(currentProfileId);

  useEffect(() => {
    setSelectedProfileId(currentProfileId);
  }, [currentProfileId]);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
    setIsAddingProfile(false);
    setEditingProfileId(null);
    setNewProfileName('');
  };

  const handleProfileChange = (profileId: string) => {
    setCurrentProfile(profileId);
    handleProfileMenuClose();
  };

  const handleAddProfileClick = () => {
    setIsAddingProfile(true);
  };

  const handleNewProfileNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewProfileName(event.target.value);
  };

  const handleNewProfileSubmit = () => {
    if (newProfileName.trim()) {
      const newProfile = { id: Date.now().toString(), name: newProfileName.trim() };
      addProfile(newProfile as UserProfile);
      handleProfileMenuClose();
    }
  };

  const handleEditProfileClick = (profileId: string, profileName: string) => {
    setEditingProfileId(profileId);
    setNewProfileName(profileName);
  };

  const handleEditProfileSubmit = () => {
    if (editingProfileId && newProfileName.trim()) {
      updateProfile(editingProfileId, { name: newProfileName.trim() });
      setEditingProfileId(null);
      setNewProfileName('');
    }
  };

  const currentProfile = profiles.find((profile) => profile.id === selectedProfileId);

  return (
    <Box sx={{ position: 'relative', minWidth: 120, mr: 2 }}>
      <Button
        onClick={handleProfileMenuOpen}
        endIcon={<ArrowDropDownIcon />}
        sx={{ textTransform: 'none' }}
      >
        {currentProfile?.name || 'Selecionar Perfil'}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleProfileMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List sx={{ minWidth: 200 }}>
          {profiles.map((profile) => (
            <ListItem key={profile.id} disablePadding>
              {editingProfileId === profile.id ? (
                <Box display="flex" alignItems="center" sx={{ p: 1 }}>
                  <TextField
                    value={newProfileName}
                    onChange={handleNewProfileNameChange}
                    placeholder="Nome do perfil"
                    size="small"
                    autoFocus
                  />
                  <IconButton onClick={handleEditProfileSubmit} size="small">
                    <CheckIcon color="success" />
                  </IconButton>
                </Box>
              ) : (
                <ListItemButton
                  onClick={() => handleProfileChange(profile.id)}
                  selected={profile.id === selectedProfileId}
                >
                  <ListItemText primary={profile.name} />
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditProfileClick(profile.id, profile.name);
                    }}
                    size="small"
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </ListItemButton>
              )}
            </ListItem>
          ))}
          <ListItem disablePadding>
            {isAddingProfile ? (
              <Box display="flex" alignItems="center" sx={{ p: 1 }}>
                <TextField
                  value={newProfileName}
                  onChange={handleNewProfileNameChange}
                  placeholder="Nome do novo perfil"
                  size="small"
                  autoFocus
                />
                <IconButton onClick={handleNewProfileSubmit} size="small">
                  <CheckIcon color="success" />
                </IconButton>
              </Box>
            ) : (
              <ListItemButton onClick={handleAddProfileClick}>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary="Adicionar Perfil" />
              </ListItemButton>
            )}
          </ListItem>
        </List>
      </Popover>
    </Box>
  );
};

export default ProfileSelect;
