import CNAES from '@data/CNAE.json';
import Api from '@services/api';
import { useBulletinStore } from '@store/bulletinStore';
import { useFavoritesStore } from '@store/favoritesStore';
import { useFollowingStore } from '@store/followingStore';
import { useKanbanStore } from '@store/kanbanStore';
import { CNAE, Company, FirestoreUser, UserPreferences, UserProfile } from '@types';
import { isEqual } from 'lodash';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export const formatCNAE = (cnae: string): string => {
  if (cnae.length !== 7) return cnae;
  return `${cnae.slice(0, 4)}-${cnae.slice(4, 5)}/${cnae.slice(5)}`;
};

export const cnaes: CNAE[] = CNAES;

interface ProfileState {
  userData: FirestoreUser | null;
  company: Company | null;
  userPreferences: UserPreferences | null;
  fetchCompany: (
    cnpj: string,
  ) => Promise<Company | { error: string; status?: number; details?: string }>;
  setCompany: (company: Company) => Promise<void>;
  setUserPreferences: (preferences: UserPreferences) => Promise<void>;
  saveUserCompany: (companyInfo: Company) => Promise<void>;
  saveUserPreferences: (preferences: UserPreferences) => Promise<void>;
  saveUser: (user: FirestoreUser) => Promise<void>;
  loadUserData: (profileId?: string) => Promise<FirestoreUser | null>;
  isLoading: boolean;
  resetStore: () => void;
  setOnboardingPreferences: (preferences: Partial<UserPreferences> | null) => void;
  setOnboardingComplete: () => Promise<void>;
  checkUserPlanHistory: () => Promise<void>;
  profiles: UserProfile[];
  currentProfileId: string | null;
  setCurrentProfile: (profileId: string) => void;
  addProfile: (profile: UserProfile) => void;
  updateProfile: (profileId: string, updates: Partial<UserProfile>) => void;
  deleteProfile: (profileId: string) => void;
}

const storage = {
  getItem: (name: string) => {
    const str = localStorage.getItem(name);
    if (str) return JSON.parse(str);
    return null;
  },
  setItem: (name: string, value: any) => {
    localStorage.setItem(name, JSON.stringify(value));
  },
  removeItem: (name: string) => localStorage.removeItem(name),
  setCurrentProfileId: (profileId: string | null) => {
    localStorage.setItem('currentProfileId', profileId || '');
  },
  getCurrentProfileId: (): string | null => {
    return localStorage.getItem('currentProfileId') || null;
  },
};

export const useProfileStore = create<ProfileState>()(
  persist(
    (set, get) => ({
      company: null,
      userData: null,
      userPreferences: null,
      isLoading: false,
      cnaes: CNAES,
      profiles: [],
      currentProfileId: storage.getCurrentProfileId(),
      setCompany: async (newCompany: Company) => {
        const currentCompany = get().company;
        if (!isEqual(currentCompany, newCompany)) {
          set({ company: newCompany });
          await get().saveUserCompany(newCompany);
        }
      },
      setUserPreferences: async (newPreferences: UserPreferences) => {
        const currentPreferences = get().userPreferences;
        if (!isEqual(currentPreferences, newPreferences)) {
          set({ userPreferences: newPreferences });
          await get().saveUserPreferences(newPreferences);
        }
      },
      fetchCompany: async (cnpj: string) => {
        try {
          const response = await Api.getCompany(cnpj);
          if ('error' in response) {
            return response;
          }
          return response;
        } catch (error) {
          console.error('Error fetching company:', error);
          if (error instanceof Error) {
            return {
              error: error.message || 'Ocorreu um erro ao buscar a empresa.',
              status: 500,
            };
          } else {
            return {
              error: 'Ocorreu um erro ao buscar a empresa.',
              status: 500,
            };
          }
        }
      },
      saveUserCompany: async (companyInfo: Company) => {
        const isOnboarding = !get().company;
        await Api.saveUserCompany(companyInfo, isOnboarding);
        set({ company: companyInfo });
      },
      saveUserPreferences: async (preferences: UserPreferences) => {
        const isOnboarding = !get().userPreferences;
        await Api.saveUserPreferences(preferences, isOnboarding);
        set({ userPreferences: preferences });
      },
      saveUser: async (user: FirestoreUser) => {
        await Api.saveUserData(user);
        set((state) => ({
          userData: { ...state.userData, ...user },
        }));
      },
      resetStore: () =>
        set({
          company: null,
          userPreferences: null,
          isLoading: false,
          userData: null,
          profiles: [],
          currentProfileId: null,
        }),
      loadUserData: async (profileId?: string) => {
        set({ isLoading: true });
        let userData: FirestoreUser | null = null;
        try {
          const storedProfileId = storage.getCurrentProfileId();
          userData = await Api.getUserData(profileId || storedProfileId || '');
          if (userData) {
            set({
              userData: userData,
              profiles: userData.profiles || [],
              currentProfileId:
                profileId ||
                storedProfileId ||
                (userData.profiles && userData.profiles[0]?.id) ||
                null,
            });

            const currentProfileId =
              profileId || storedProfileId || (userData.profiles && userData.profiles[0]?.id);
            if (currentProfileId) {
              const currentProfile = userData.profiles?.find((p) => p.id === currentProfileId);
              if (currentProfile) {
                set({
                  company: currentProfile.companyInfo || null,
                  userPreferences: currentProfile.userPreferences || null,
                });
                storage.setCurrentProfileId(currentProfileId);
              }
            }

            if (userData.hasHadActivePlan === undefined) {
              get().checkUserPlanHistory();
            }
          }
        } catch (error) {
          console.error('Error loading user data:', error);
        } finally {
          set({ isLoading: false });
        }
        return userData;
      },
      setOnboardingPreferences: (preferences: Partial<UserPreferences> | null) =>
        set({ userPreferences: preferences as UserPreferences }),
      setOnboardingComplete: async () => {
        try {
          await Api.setOnboardingComplete();
          set((state) => ({
            userData: state.userData ? { ...state.userData, onboardingComplete: true } : null,
          }));
        } catch (error) {
          console.error('Failed to set onboarding complete:', error);
        }
      },
      checkUserPlanHistory: async () => {
        const userData = get().userData;
        if (userData && userData.hasHadActivePlan === undefined) {
          try {
            const hasHadActivePlan = await Api.checkUserPlanHistory();
            set((state) => ({
              userData: state.userData ? { ...state.userData, hasHadActivePlan } : null,
            }));
          } catch (error) {
            console.error('Error checking user plan history:', error);
          }
        }
      },
      setCurrentProfile: async (profileId: string) => {
        set({ isLoading: true });
        try {
          Api.setCurrentProfileId(profileId);
          set({ currentProfileId: profileId });
          storage.setCurrentProfileId(profileId); // Save to localStorage

          const { profiles } = get();
          const currentProfile = profiles.find((p) => p.id === profileId);

          if (currentProfile) {
            set({
              company: currentProfile.companyInfo || null,
              userPreferences: currentProfile.userPreferences || null,
            });
          } else {
            console.warn(`Profile with id ${profileId} not found in the current store.`);
          }

          useFavoritesStore.getState().resetStore();
          useFollowingStore.getState().resetStore();
          useKanbanStore.getState().resetStore();
          useBulletinStore.getState().resetStore();

          await Promise.all([
            useFavoritesStore.getState().fetchFavorites(),
            useFollowingStore.getState().fetchFollowing(),
          ]);
        } catch (error) {
          console.error('Error setting current profile:', error);
        } finally {
          set({ isLoading: false });
        }
      },
      addProfile: async (profile: Partial<UserProfile>) => {
        set({ isLoading: true });
        try {
          const newProfile = await Api.createProfile(profile);

          set((state) => ({
            profiles: [...state.profiles, newProfile],
            currentProfileId: newProfile.id,
          }));

          await get().setCurrentProfile(newProfile.id);
        } catch (error) {
          console.error('Error adding new profile:', error);
        } finally {
          set({ isLoading: false });
        }
      },
      updateProfile: async (profileId: string, updates: Partial<UserProfile>) => {
        set({ isLoading: true });
        try {
          const newProfile = await Api.updateProfile(profileId, updates);
          set((state) => ({
            profiles: state.profiles.map((p) => (p.id === profileId ? newProfile : p)),
          }));

          await get().setCurrentProfile(newProfile.id);
        } catch (error) {
          console.error('Error updating profile:', error);
        } finally {
          set({ isLoading: false });
        }
      },
      deleteProfile: async (profileId: string) => {
        set({ isLoading: true });
        try {
          await Api.deleteProfile(profileId);
          set((state) => ({
            profiles: state.profiles.filter((p) => p.id !== profileId),
            currentProfileId: state.currentProfileId === profileId ? null : state.currentProfileId,
          }));
        } catch (error) {
          console.error('Error deleting profile:', error);
        } finally {
          set({ isLoading: false });
        }
      },
    }),
    {
      name: 'profile-storage',
      storage: createJSONStorage(() => storage),
      partialize: (state) => ({
        ...state,
        profiles: state.profiles,
        currentProfileId: state.currentProfileId,
      }),
    },
  ),
);
