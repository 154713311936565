import Header from '@components/Layout/Header';
import { Box, CircularProgress, Container, Typography, useTheme } from '@mui/material';
import React from 'react';

interface LoadingProps {
  withHeader?: boolean;
}

const Loading: React.FC<LoadingProps> = ({ withHeader = true }) => {
  const theme = useTheme();
  return (
    <Box sx={{ backgroundColor: theme.palette.background.default, minHeight: '100vh', py: 4 }}>
      <Container maxWidth="lg">
        {withHeader && <Header />}
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom color="primary">
            <CircularProgress color="primary" />
            Carregando informações...
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Loading;
