import React from 'react';
import { Licitacao, Portal } from '../types';
import BanrisulDetails from './Details/BanrisulDetails';
import BllBncDetails from './Details/BllBncDetails';
import ComprasbrDetails from './Details/ComprasbrDetails';
import ComprasbrDispensaDetails from './Details/ComprasbrDispensaDetails';
import ComprasnetDetails from './Details/ComprasnetDetails';
import DouDetails from './Details/DouDetails';
import LicitanetDetails from './Details/LicitanetDetails';
import LicitarDigitalDetails from './Details/LicitardigitalDetails';
import PcpDetails from './Details/PcpDetails';
import PncpDetails from './Details/PncpDetails';
import ProdespDetails from './Details/ProdespDetails';
interface PortalDetailsProps {
  portalData: any;
  licitacao: Licitacao;
}

const PortalDetails: React.FC<PortalDetailsProps> = ({ portalData, licitacao }) => {
  const licitacaoId = licitacao.id_licitacao;
  const portalId = licitacao.id_portal.toString();
  switch (portalId) {
    case Portal.PortalNacionalDeComprasPublicas:
    case Portal.BBMNET:
    case Portal.PortalDeComprasPublicas:
      if (licitacaoId?.startsWith('PCP-')) {
        return <PcpDetails details={portalData} />;
      }
      if (
        licitacao.linkExterno.startsWith('https://bnccompras.com/') ||
        licitacao.linkExterno.startsWith('https://bllcompras.com/')
      ) {
        return <BllBncDetails details={portalData} licitacao={licitacao} />;
      }
      return <PncpDetails details={portalData} licitacao={licitacao} />;
    case Portal.ComprasGovernamentais:
      if (licitacaoId?.startsWith('CN-')) {
        return <ComprasnetDetails details={portalData} licitacao={licitacao} />;
      }
      return <PncpDetails details={portalData} licitacao={licitacao} />;
    case Portal.PRODESP:
      return <ProdespDetails details={portalData} />;
    case Portal.BLLCompras:
    case Portal.BNCCompras:
      return <BllBncDetails details={portalData} licitacao={licitacao} />;
    case Portal.LicitaNET:
      return <LicitanetDetails details={portalData} />;
    case Portal.ComprasBR:
      if (licitacaoId?.startsWith('CBR-D-')) {
        return <ComprasbrDispensaDetails details={portalData} />;
      }
      return <ComprasbrDetails details={portalData} />;
    case Portal.LicitacoesEBancoDoBrasil:
    case Portal.BECSP:
      return null;
    case Portal.DemaisPortais:
    case Portal.DOU:
    case Portal.Banrisul:
    case Portal.LicitarDigital:
    default:
      if (licitacaoId?.startsWith('BRS-') || licitacaoId?.startsWith('RS-')) {
        return <BanrisulDetails details={portalData} licitacao={licitacao} />;
      }
      if (licitacaoId?.startsWith('LD-')) {
        return <LicitarDigitalDetails details={portalData} />;
      }
      if (licitacaoId?.startsWith('TCE-PR-')) {
        return <PncpDetails details={portalData} licitacao={licitacao} />;
      }

      if (licitacaoId?.startsWith('DOU-')) {
        return <DouDetails details={portalData} licitacao={licitacao} />;
      }
      return null;
  }
};

export default PortalDetails;
