import LicitacaoCard from '@components/Licitacao/LicitacaoCard';
import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { useFollowingStore } from '@store/followingStore';
import React, { useCallback, useEffect, useState } from 'react';

const FollowingLicitacoes: React.FC = () => {
  const theme = useTheme();
  const { followingLicitacoes, fetchFollowingLicitacoes, isLoading, error } = useFollowingStore();
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    fetchFollowingLicitacoes();
  }, [fetchFollowingLicitacoes]);

  const handleRefresh = useCallback(async () => {
    setRefreshing(true);
    await fetchFollowingLicitacoes();
    setRefreshing(false);
  }, [fetchFollowingLicitacoes]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6" component="h2" color="primary">
          Licitações Acompanhadas
        </Typography>
        <IconButton
          onClick={handleRefresh}
          disabled={isLoading || refreshing}
          sx={{ color: theme.palette.primary.main }}
        >
          <RefreshIcon />
        </IconButton>
      </Box>
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      <Grid container spacing={2}>
        {followingLicitacoes.map((item, index) => (
          <LicitacaoCard
            key={item.id_licitacao}
            item={item}
            isFirst={index === 0}
            searchKeywords={[]}
          />
        ))}
      </Grid>
      {(isLoading || refreshing) && (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress color="primary" />
        </Box>
      )}
      {!isLoading && !error && followingLicitacoes.length === 0 && (
        <Paper
          elevation={3}
          sx={{
            p: 3,
            mt: 4,
            backgroundColor: theme.palette.background.paper,
            borderRadius: 2,
          }}
        >
          <Box display="flex" alignItems="center" mb={2}>
            <InfoIcon color="info" sx={{ mr: 2, fontSize: 40 }} />
            <Typography variant="h6" color="info.main">
              Você ainda não está acompanhando nenhuma licitação
            </Typography>
          </Box>
          <Typography color="text.secondary" paragraph>
            Ao navegar pelas licitações, você pode marcar as que deseja acompanhar de perto.
          </Typography>
          <Typography color="text.secondary">
            Isso facilitará o monitoramento das oportunidades mais relevantes para você. Explore as
            licitações disponíveis e comece a criar sua lista de acompanhamento!
          </Typography>
        </Paper>
      )}
    </Box>
  );
};

export default FollowingLicitacoes;
