import {
  AttachMoney,
  Block,
  Campaign,
  Computer,
  Description,
  Email,
  EmojiEvents,
  Forum,
  Gavel,
  Groups,
  HelpOutline,
  ListAlt,
  RequestQuote,
  Settings,
  ShoppingCart,
  SvgIconComponent,
} from '@mui/icons-material';
import { Esfera, KanbanColumn, LicitacoesSearchParams, ModalidadeInfo, Portal } from '@types';

export const TIME_ZONE = 'America/Sao_Paulo';

export const brazilianStates = [
  { label: 'Acre', value: 'AC' },
  { label: 'Alagoas', value: 'AL' },
  { label: 'Amapá', value: 'AP' },
  { label: 'Amazonas', value: 'AM' },
  { label: 'Bahia', value: 'BA' },
  { label: 'Ceará', value: 'CE' },
  { label: 'Distrito Federal', value: 'DF' },
  { label: 'Espírito Santo', value: 'ES' },
  { label: 'Goiás', value: 'GO' },
  { label: 'Maranhão', value: 'MA' },
  { label: 'Mato Grosso', value: 'MT' },
  { label: 'Mato Grosso do Sul', value: 'MS' },
  { label: 'Minas Gerais', value: 'MG' },
  { label: 'Pará', value: 'PA' },
  { label: 'Paraíba', value: 'PB' },
  { label: 'Paraná', value: 'PR' },
  { label: 'Pernambuco', value: 'PE' },
  { label: 'Piauí', value: 'PI' },
  { label: 'Rio de Janeiro', value: 'RJ' },
  { label: 'Rio Grande do Norte', value: 'RN' },
  { label: 'Rio Grande do Sul', value: 'RS' },
  { label: 'Rondônia', value: 'RO' },
  { label: 'Roraima', value: 'RR' },
  { label: 'Santa Catarina', value: 'SC' },
  { label: 'São Paulo', value: 'SP' },
  { label: 'Sergipe', value: 'SE' },
  { label: 'Tocantins', value: 'TO' },
];

export const tipoMapping = {
  0: 'Sem modalidade definida',
  1: 'Convite',
  2: 'Concorrência',
  3: 'Leilão',
  4: 'Tomada de preços',
  5: 'Pregão eletrônico',
  6: 'Dispensa',
  7: 'Inexigibilidade',
  8: 'Pregão presencial',
  9: 'Concurso',
  10: 'Registro de preço',
  11: 'Chamamento público',
  12: 'Compra eletrônica',
  13: 'Pregão',
  14: 'RDC - Regime diferenciado de contratação',
  15: 'Cotação Eletrônica',
  16: 'Diálogo competitivo',
  17: 'Concorrência eletrônica',
};

export const modalidadesMap: Record<
  number,
  { name: string; icon: SvgIconComponent; color: string; description: string }
> = {
  0: {
    name: 'Sem modalidade definida',
    icon: HelpOutline,
    color: '#999999',
    description: 'Modalidade de licitação não especificada ou não reconhecida.',
  },
  1: {
    name: 'Convite',
    icon: Email,
    color: '#FFA000',
    description:
      'Modalidade entre interessados do ramo, cadastrados ou não, escolhidos em número mínimo de três.',
  },
  2: {
    name: 'Concorrência',
    icon: EmojiEvents,
    color: '#1E88E5',
    description:
      'Modalidade entre quaisquer interessados que comprovem possuir os requisitos mínimos de qualificação.',
  },
  3: {
    name: 'Leilão',
    icon: Gavel,
    color: '#43A047',
    description:
      'Modalidade entre quaisquer interessados para a venda de bens móveis inservíveis ou de produtos legalmente apreendidos.',
  },
  4: {
    name: 'Tomada de preços',
    icon: AttachMoney,
    color: '#E53935',
    description:
      'Modalidade entre interessados devidamente cadastrados, observada a necessária qualificação.',
  },
  5: {
    name: 'Pregão eletrônico',
    icon: Computer,
    color: '#3949AB',
    description:
      'Modalidade realizada à distância em sessão pública, por meio de sistema que promova a comunicação pela internet.',
  },
  6: {
    name: 'Dispensa',
    icon: Description,
    color: '#8E24AA',
    description: 'Contratação direta, sem licitação, nos casos previstos em lei.',
  },
  7: {
    name: 'Inexigibilidade',
    icon: Block,
    color: '#D81B60',
    description: 'Contratação direta quando houver inviabilidade de competição.',
  },
  8: {
    name: 'Pregão presencial',
    icon: Groups,
    color: '#00897B',
    description: 'Modalidade presencial para aquisição de bens e serviços comuns.',
  },
  9: {
    name: 'Concurso',
    icon: EmojiEvents,
    color: '#7CB342',
    description:
      'Modalidade entre quaisquer interessados para escolha de trabalho técnico, científico ou artístico.',
  },
  10: {
    name: 'Registro de preço',
    icon: ListAlt,
    color: '#FB8C00',
    description:
      'Sistema de compras pelo qual os interessados concordam em manter os preços registrados por um período.',
  },
  11: {
    name: 'Chamamento público',
    icon: Campaign,
    color: '#5E35B1',
    description:
      'Procedimento para selecionar organização da sociedade civil para firmar parceria.',
  },
  12: {
    name: 'Compra eletrônica',
    icon: ShoppingCart,
    color: '#00ACC1',
    description:
      'Processo de aquisição de bens e serviços comuns, utilizando a tecnologia da informação.',
  },
  13: {
    name: 'Pregão',
    icon: Gavel,
    color: '#F4511E',
    description: 'Modalidade de licitação para aquisição de bens e serviços comuns.',
  },
  14: {
    name: 'RDC - Regime diferenciado de contratação',
    icon: Settings,
    color: '#6D4C41',
    description:
      'Modalidade que busca ampliar a eficiência nas contratações públicas e a competitividade entre os licitantes.',
  },
  15: {
    name: 'Cotação Eletrônica',
    icon: RequestQuote,
    color: '#546E7A',
    description: 'Procedimento para aquisição de bens de pequeno valor por dispensa de licitação.',
  },
  16: {
    name: 'Diálogo competitivo',
    icon: Forum,
    color: '#C0CA33',
    description:
      'Modalidade de licitação para contratação de obras, serviços e compras em que a administração pública realiza diálogos com licitantes previamente selecionados.',
  },
  17: {
    name: 'Concorrência eletrônica',
    icon: Computer,
    color: '#8D6E63',
    description:
      'Modalidade de licitação realizada de forma eletrônica, entre quaisquer interessados que comprovem possuir os requisitos mínimos de qualificação.',
  },
};

export const getModalidadeName = (id: number) => {
  return modalidadesMap[id as keyof typeof modalidadesMap]?.name || 'Desconhecido';
};

export const getModalidadeInfo = (id: number): ModalidadeInfo => {
  const defaultInfo: ModalidadeInfo = {
    name: 'Desconhecido',
    icon: modalidadesMap[0].icon,
    color: '#999999', // Default color for unknown modalidade
    description: 'Modalidade não identificada',
  };

  const modalidade = modalidadesMap[id as keyof typeof modalidadesMap];
  if (!modalidade) return defaultInfo;

  return {
    name: modalidade.name,
    icon: modalidade.icon,
    color: modalidade.color || '#1976d2', // Use a default color if not specified
    description: modalidade.description || 'Descrição não disponível',
  };
};

export const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text;
  return text.substr(0, maxLength) + '...';
};

export const LicitacoesOnboardingSteps = [
  {
    target: '.header-menu',
    content:
      'Aqui você pode acessar diferentes seções do aplicativo, como suas licitações recomendadas e favoritas.',
    disableBeacon: true,
  },
  {
    target: '.search-box',
    content:
      'Use esta caixa de pesquisa para encontrar licitações específicas por palavra-chave, localização, data ou outros critérios.',
  },
  {
    target: '.licitacao-card',
    content: 'Cada card representa uma licitação. Clique para ver mais detalhes.',
  },
  {
    target: '.favorite-button',
    content: 'Clique na estrela para adicionar ou remover uma licitação dos seus favoritos.',
  },
  {
    target: '[data-tour="config-button"]',
    content: 'Venha aqui para gerenciar seus pagamentos, notificações e muito mais.',
  },
];

export const ESFERAS: Record<string, string> = {
  [Esfera.Federal]: 'Federal',
  [Esfera.Estadual]: 'Estadual',
  [Esfera.MunicipalDistrital]: 'Municipal/Distrital',
  [Esfera.AdministracaoIndireta]: 'Administração Indireta (autarquias, fundações, consórcios)',
  [Esfera.NaoClassificado]: 'Não classificado',
};

export const PORTAL_NAMES: { [key in Portal]: string } = {
  [Portal.ComprasGovernamentais]: 'ComprasNET',
  [Portal.LicitacoesEBancoDoBrasil]: 'Licitações-E / BB',
  [Portal.BECSP]: 'BEC-SP',
  [Portal.PortalNacionalDeComprasPublicas]: 'PNCP',
  [Portal.PRODESP]: 'ProDESP',
  [Portal.BLLCompras]: 'BLL Compras',
  [Portal.BNCCompras]: 'BNC Compras',
  [Portal.BBMNET]: 'BBMNET',
  [Portal.PortalDeComprasPublicas]: 'Portal de Compras Públicas',
  [Portal.LicitaNET]: 'LicitaNET',
  [Portal.ComprasBR]: 'Compras BR',
  [Portal.LicitarDigital]: 'Licitar Digital',
  [Portal.Banrisul]: 'Banrisul',
  [Portal.DOU]: 'DOU',
  [Portal.DemaisPortais]: 'Demais portais',
};

// Add this type guard function

export function isKeyOfLicitacoesSearchParams(key: string): key is keyof LicitacoesSearchParams {
  return (
    key in
    {
      palavra_chave: true,
      uf: true,
      municipio_ibge: true,
      modalidade: true,
      data_abertura_inicio: true,
      data_abertura_fim: true,
      id_portal: true,
      valor_min: true,
      valor_max: true,
      cnae: true,
      item: true,
      codigo_uasg: true,
      match_exact: true,
      edital: true,
      esfera: true,
    }
  );
}

export const FIXED_KANBAN_COLUMNS: KanbanColumn[] = [
  {
    id: 'em_espera',
    title: 'Em espera',
    position: 0,
    licitacoesIds: [],
    licitacoes: [],
  },
  {
    id: 'nao_participando',
    title: 'Não participando',
    position: 1,
    licitacoesIds: [],
    licitacoes: [],
  },
  {
    id: 'proposta_enviada',
    title: 'Proposta enviada',
    position: 2,
    licitacoesIds: [],
    licitacoes: [],
  },
  {
    id: 'participando',
    title: 'Participando',
    position: 3,
    licitacoesIds: [],
    licitacoes: [],
  },
  {
    id: 'ganhas',
    title: 'Não participando',
    position: 4,
    licitacoesIds: [],
    licitacoes: [],
  },
  {
    id: 'envio_documentos',
    title: 'Envio de documentos',
    position: 5,
    licitacoesIds: [],
    licitacoes: [],
  },
  {
    id: 'contratos',
    title: 'Contratos',
    position: 6,
    licitacoesIds: [],
    licitacoes: [],
  },
  {
    id: 'nao_ganhas',
    title: 'Não ganhas',
    position: 7,
    licitacoesIds: [],
    licitacoes: [],
  },
];

export const DEFAULT_BOARD_ID = 'default';
export const DEFAULT_BOARD_NAME = 'Meu Quadro de Licitações';
