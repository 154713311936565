import FavoriteAnnotation from '@components/Favorite/FavoriteAnnotation';
import FavoriteButton from '@components/Favorite/FavoriteButton';
import FollowingAnnotation from '@components/Following/FollowingAnnotation';
import FollowingButton from '@components/Following/FollowingButton';
import { ESFERAS, getModalidadeInfo, PORTAL_NAMES } from '@constants';
import {
  AttachMoney,
  Business,
  Category,
  Description,
  Dns,
  Public as EsferaIcon,
  Event,
  EventAvailable,
  ExpandMore as ExpandMoreIcon,
  ListAlt,
  LocationOn,
  Public as PortalIcon,
  Today,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useFavoritesStore } from '@store/favoritesStore';
import { useFollowingStore } from '@store/followingStore';
import { Esfera, Licitacao, MyLicitacoes } from '@types';
import { formatIsoDate, getLicitacaoStatus, isNew, truncateText } from '@utils';
import React from 'react';
import { Link } from 'react-router-dom';

interface LicitacaoCardProps {
  item: Licitacao | MyLicitacoes;
  isFirst: boolean;
  searchKeywords: string[];
}

const colors = ['#FFD700', '#00CED1', '#32CD32', '#FF69B4', '#FFA500', '#9370DB'];

const highlightText = (text: string, keywords: string[]) => {
  if (keywords.length === 0) return text;

  const parts = text.split(new RegExp(`(${keywords.join('|')})`, 'gi'));
  return parts.map((part, index) => {
    const keywordIndex = keywords.findIndex(
      (keyword) => part.toLowerCase() === keyword.toLowerCase(),
    );
    if (keywordIndex !== -1) {
      const color = colors[keywordIndex % colors.length];
      return (
        <mark key={index} style={{ backgroundColor: color, padding: '0 2px' }}>
          {part}
        </mark>
      );
    }
    return part;
  });
};

const LicitacaoCard: React.FC<LicitacaoCardProps> = ({ item, isFirst, searchKeywords }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { isFavorite } = useFavoritesStore();
  const { isFollowing } = useFollowingStore();
  const modalidadeInfo = getModalidadeInfo(item.id_tipo);
  const isNewLicitacao = isNew(item.created_at);
  const currentStatus = getLicitacaoStatus(
    item.status,
    item.abertura_datetime,
    item.fechamento_datetime,
  );
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setExpanded(!expanded);
  };

  const InfoItem = ({
    icon: Icon,
    label,
    value,
  }: {
    icon: React.ElementType;
    label: string;
    value: string;
  }) => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 0.5 }}>
        <Tooltip title={label}>
          <Icon fontSize="small" sx={{ color: theme.palette.primary.main, opacity: 0.7 }} />
        </Tooltip>
        <Typography variant="caption" color="text.secondary" sx={{ fontSize: '0.7rem' }}>
          {label}
        </Typography>
      </Box>
      <Typography variant="body2" sx={{ fontSize: '0.75rem', fontWeight: 'medium' }}>
        {value}
      </Typography>
    </Box>
  );

  const matchingItem = item.items?.find((licitacaoItem) =>
    searchKeywords.some((keyword) =>
      licitacaoItem.descricao.toLowerCase().includes(keyword.toLowerCase()),
    ),
  );

  const renderMatchingItem = () =>
    matchingItem && (
      <Box mt={1}>
        <Typography variant="subtitle2" color="primary">
          Item correspondente:
        </Typography>
        <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
          {highlightText(matchingItem.descricao, searchKeywords)}
        </Typography>
      </Box>
    );

  const renderMobileContent = () => (
    <>
      <Box mb={1}>
        <Typography variant="subtitle2" color="primary">
          {truncateText(item.titulo, 50)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {item.orgao}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <InfoItem
          icon={EventAvailable}
          label="Abertura"
          value={item.abertura_datetime ? formatIsoDate(item.abertura_datetime) : '-'}
        />
        <InfoItem icon={Dns} label="Status" value={currentStatus} />
      </Box>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <InfoItem icon={Description} label="Modalidade" value={modalidadeInfo.name} />
        <InfoItem
          icon={AttachMoney}
          label="Valor"
          value={
            item.valor > 0
              ? new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(item.valor)
              : '-'
          }
        />
      </Box>
      <IconButton
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label="show more"
        sx={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)', transition: '0.3s' }}
      >
        <ExpandMoreIcon />
      </IconButton>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box mt={1}>
          <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
            {highlightText(truncateText(item.objeto, 300), searchKeywords)}
          </Typography>
          {renderMatchingItem()}
        </Box>
      </Collapse>
    </>
  );

  return (
    <Grid item xs={12} className={isFirst ? 'licitacao-card' : ''}>
      <Link to={`/licitacao/${item.id_licitacao}`} style={{ textDecoration: 'none' }}>
        <Card
          sx={{
            mb: 2,
            boxShadow: 3,
            '&:hover': { boxShadow: 6 },
            position: 'relative',
            bgcolor: theme.palette.background.paper, // Ensure card background adapts to theme
            color: theme.palette.text.primary, // Ensure text color adapts to theme
          }}
        >
          {isNewLicitacao && (
            <Chip
              label="Nova"
              color="primary"
              size="small"
              sx={{
                position: 'absolute',
                top: 8,
                left: 8,
                zIndex: 1,
              }}
            />
          )}
          <CardContent>
            {isMobile ? (
              renderMobileContent()
            ) : (
              <>
                <Box mb={1}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.5,
                      mb: 0.5,
                    }}
                  >
                    <modalidadeInfo.icon
                      fontSize="small"
                      style={{ color: modalidadeInfo.color, marginRight: 0.5 }}
                    />
                    <Typography variant="subtitle2" color="primary">
                      {item.titulo} - {item.orgao}
                    </Typography>
                    <Box sx={{ marginLeft: 'auto', display: 'flex', gap: 1 }}>
                      <FollowingButton
                        className="following-button"
                        licitacaoId={item.id_licitacao}
                      />
                      <FavoriteButton className="favorite-button" licitacaoId={item.id_licitacao} />
                    </Box>
                  </Box>
                </Box>
                <Grid container spacing={1}>
                  <Grid item xs={2}>
                    <InfoItem
                      icon={PortalIcon}
                      label="Portal"
                      value={PORTAL_NAMES[item.id_portal]}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <InfoItem
                      icon={LocationOn}
                      label="Local"
                      value={`${item.municipio} - ${item.uf}`}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <InfoItem
                      icon={EventAvailable}
                      label="Data de abertura"
                      value={item.abertura_datetime ? formatIsoDate(item.abertura_datetime) : '-'}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <InfoItem icon={Dns} label="Status" value={currentStatus} />
                  </Grid>
                  <Grid item xs={2}>
                    <InfoItem
                      icon={ListAlt}
                      label="Número do Edital"
                      value={item.numero_edital || '-'}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <InfoItem
                      icon={Today}
                      label="Data publicação"
                      value={formatIsoDate(item.created_at)}
                    />
                  </Grid>
                  {/* second row */}
                  <Grid item xs={2}>
                    <InfoItem icon={Description} label="Modalidade" value={modalidadeInfo.name} />
                  </Grid>
                  <Grid item xs={2}>
                    <InfoItem
                      icon={AttachMoney}
                      label="Valor"
                      value={
                        item.valor > 0
                          ? new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(item.valor)
                          : '-'
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <InfoItem
                      icon={Event}
                      label="Data encerramento"
                      value={
                        item.fechamento_datetime ? formatIsoDate(item.fechamento_datetime) : '-'
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <InfoItem icon={Category} label="Código CNAE" value={item.cnae || '-'} />
                  </Grid>
                  <Grid item xs={2}>
                    <InfoItem icon={Business} label="Código UASG" value={item.codigo_uasg || '-'} />
                  </Grid>
                  <Grid item xs={2}>
                    <InfoItem
                      icon={EsferaIcon}
                      label="Esfera"
                      value={ESFERAS[item.esfera as Esfera] || 'Não informada'}
                    />
                  </Grid>
                </Grid>
                <Box mt={1}>
                  <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
                    {highlightText(truncateText(item.objeto, 300), searchKeywords)}
                  </Typography>
                  {renderMatchingItem()}
                </Box>
              </>
            )}
            {(isFavorite(item.id_licitacao) || isFollowing(item.id_licitacao)) && (
              <>
                {isFavorite(item.id_licitacao) ? (
                  <FavoriteAnnotation licitacaoId={item.id_licitacao} />
                ) : (
                  <FollowingAnnotation licitacaoId={item.id_licitacao} />
                )}
              </>
            )}
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
};

export default LicitacaoCard;
