import OnboardingReminder from '@components/Onboarding/OnboardingReminder';
import SubscriptionReminder from '@components/Payment/SubscriptionReminder';
import { useAuth } from '@contexts/AuthContext';
import { useProfileStore } from '@store/profileStore';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { user } = useAuth();

  const { company, userPreferences, userData } = useProfileStore();
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (
    userData &&
    !userData.isActive &&
    userData.hasHadActivePlan !== undefined &&
    userData.role !== 'admin'
  ) {
    if (location.pathname === '/configuracoes/pagamento') {
      return <>{children}</>;
    }
    if (userData.hasHadActivePlan) {
      return <Navigate to="/configuracoes/pagamento" replace />;
    } else {
      return (window.location.href = 'https://licitou.com.br/pagar');
    }
  }

  const isCompanyInfoComplete = company && company.cnaePrincipal && company.cnaePrincipal.id;
  const isPreferencesComplete = userPreferences && userPreferences.companySummary;
  const isOnboardingComplete = isCompanyInfoComplete && isPreferencesComplete;

  return (
    <>
      {!isOnboardingComplete && <OnboardingReminder />}
      {userData && userData.subscriptionStatus === 'past_due' && <SubscriptionReminder />}
      {children}
    </>
  );
};

export default PrivateRoute;
