import Api from '@services/api';
import { Following, Licitacao } from '@types';
import { create } from 'zustand';

interface FollowingState {
  following: Following[];
  followingLicitacoes: Licitacao[];
  isLoading: boolean;
  error: string | null;
  fetchFollowing: () => Promise<void>;
  fetchFollowingLicitacoes: () => Promise<void>;
  addFollowing: (licitacaoId: string) => Promise<void>;
  removeFollowing: (licitacaoId: string) => Promise<void>;
  isFollowing: (licitacaoId: string) => boolean;
  updateFollowingAnnotation: (licitacaoId: string, annotation: string) => Promise<void>;
  getFollowingAnnotation: (licitacaoId: string) => string;
  resetStore: () => void;
}

export const useFollowingStore = create<FollowingState>((set, get) => ({
  following: [],
  followingLicitacoes: [],
  isLoading: false,
  error: null,

  fetchFollowing: async () => {
    set({ isLoading: true, error: null });
    try {
      const following = await Api.getFollowing();
      set({ following, isLoading: false });
    } catch (error) {
      console.error('Error fetching following:', error);
      set({ error: 'Erro ao buscar acompanhamentos', isLoading: false });
    }
  },

  fetchFollowingLicitacoes: async () => {
    set({ isLoading: true, error: null });
    try {
      const followingLicitacoes = await Api.getFollowingLicitacoes();
      set({ followingLicitacoes, isLoading: false });
    } catch (error) {
      console.error('Error fetching following licitacoes:', error);
      set({ error: 'Erro ao buscar acompanhamentos', isLoading: false });
    }
  },

  addFollowing: async (licitacaoId: string) => {
    try {
      const newFollowing = await Api.addFollowing(licitacaoId);
      set((state) => ({
        following: [...state.following, newFollowing],
      }));
      await get().fetchFollowingLicitacoes();
    } catch (error) {
      console.error('Error adding following:', error);
      set({ error: 'Erro ao adicionar acompanhamento' });
    }
  },

  removeFollowing: async (licitacaoId: string) => {
    try {
      await Api.removeFollowing(licitacaoId);
      set((state) => ({
        following: state.following.filter((fav) => fav.licitacaoId !== licitacaoId),
        followingLicitacoes: state.followingLicitacoes.filter(
          (l) => l.id_licitacao !== licitacaoId,
        ),
      }));
    } catch (error) {
      console.error('Error removing following:', error);
      set({ error: 'Erro ao remover acompanhamento' });
    }
  },

  isFollowing: (licitacaoId: string) => {
    return get().following.some((fav) => fav.licitacaoId === licitacaoId);
  },

  updateFollowingAnnotation: async (licitacaoId: string, annotation: string) => {
    try {
      await Api.updateFollowingAnnotation(licitacaoId, annotation);
      set((state) => ({
        following: state.following.map((fav) =>
          fav.licitacaoId === licitacaoId ? { ...fav, annotation } : fav,
        ),
        followingLicitacoes: state.followingLicitacoes.map((licitacao) =>
          licitacao.id_licitacao === licitacaoId ? { ...licitacao, annotation } : licitacao,
        ),
      }));
    } catch (error) {
      console.error('Error updating annotation for following:', error);
      set({ error: 'Erro ao atualizar anotação do acompanhamento' });
    }
  },

  getFollowingAnnotation: (licitacaoId: string) => {
    const following = get().following.find((fav) => fav.licitacaoId === licitacaoId);
    return following ? following.annotation || '' : '';
  },

  resetStore: () =>
    set({
      following: [],
      followingLicitacoes: [],
      isLoading: false,
      error: null,
    }),
}));
