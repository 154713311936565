import { AttachMoney, OpenInNew } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { isFuture, parseISO } from 'date-fns';
import React from 'react';
import { Licitacao, PncpLicitacao, Portal } from '../../types';
import { getComprasNetId } from '../../utils';
interface PncpDetailsProps {
  details: PncpLicitacao;
  licitacao: Licitacao;
}

const PncpDetails: React.FC<PncpDetailsProps> = ({ details, licitacao }) => {
  let portalLink = null;
  let portalLinkCadastroProposta = null;
  const isPastAbertura = React.useMemo(() => {
    if (!licitacao.abertura_datetime) return false;

    const aberturaDate = parseISO(licitacao.abertura_datetime);
    return isFuture(aberturaDate);
  }, [licitacao.abertura_datetime]);

  if (licitacao.id_portal.toString() === Portal.ComprasGovernamentais) {
    const comprasNetId = getComprasNetId(licitacao);
    if (comprasNetId) {
      portalLink = `https://cnetmobile.estaleiro.serpro.gov.br/comprasnet-web/public/landing?destino=acompanhamento-compra&compra=${comprasNetId}`;
      if (!isPastAbertura) {
        portalLinkCadastroProposta = `https://cnetmobile.estaleiro.serpro.gov.br/comprasnet-web/seguro/fornecedor/cadastro-propostas?compra=${comprasNetId}`;
      } else {
        // portalLinkCadastroProposta = `https://cnetmobile.estaleiro.serpro.gov.br/comprasnet-web/seguro/fornecedor/acompanhamento-compra?compra=${comprasNetId}`;
        portalLinkCadastroProposta = `https://cnetmobile.estaleiro.serpro.gov.br/comprasnet-web/seguro/fornecedor/compras`;
      }
    }
  } else {
    if (details.linkSistemaOrigem) {
      portalLink = details.linkSistemaOrigem;
    }
    if (details.linkProcessoEletronico) {
      portalLinkCadastroProposta = details.linkProcessoEletronico;
    }
  }

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" color="primary">
            Informações adicionais do portal
          </Typography>
          {portalLink && (
            <Button
              href={portalLink}
              target="_blank"
              rel="noopener noreferrer"
              variant="contained"
              color="secondary"
              startIcon={<OpenInNew />}
            >
              Acompanhar no portal
            </Button>
          )}
          {portalLinkCadastroProposta && (
            <Button
              href={portalLinkCadastroProposta}
              target="_blank"
              rel="noopener noreferrer"
              variant="contained"
              color="primary"
              startIcon={<AttachMoney />}
            >
              {isPastAbertura ? 'Cadastrar Proposta' : 'Acompanhar proposta'}
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default PncpDetails;
