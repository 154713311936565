import { ESFERAS, PORTAL_NAMES } from '@constants';
import {
  AttachMoney,
  Delete,
  EventAvailable,
  LocationOn,
  MoreVert,
  Public,
  Visibility,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { useKanbanStore } from '@store/kanbanStore';
import { KanbanLicitacao } from '@types';
import { formatIsoDate, truncateText } from '@utils';
import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import LicitacaoDetailsModal from './LicitacaoDetailsModal';

interface KanbanCardProps {
  licitacao: KanbanLicitacao;
  index: number;
  columnId: string;
}

const KanbanCard: React.FC<KanbanCardProps> = ({ licitacao, index }) => {
  const theme = useTheme();
  const draggableId = encodeURIComponent(licitacao.id_licitacao);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const { removeKanbanLicitacao } = useKanbanStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleView = () => {
    setIsModalOpen(true);
    handleMenuClose();
  };

  const handleRemoveClick = () => {
    setIsConfirmDialogOpen(true);
    handleMenuClose();
  };

  const handleRemoveConfirm = () => {
    removeKanbanLicitacao(licitacao.id_licitacao);
    setIsConfirmDialogOpen(false);
  };

  const handleRemoveCancel = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleCardClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const getImportanceColor = (importance: string) => {
    switch (importance) {
      case 'low':
        return theme.palette.success.main;
      case 'medium':
        return theme.palette.warning.main;
      case 'high':
        return theme.palette.error.main;
      default:
        return theme.palette.grey[500];
    }
  };

  const getImportanceLabel = (importance: string) => {
    switch (importance) {
      case 'low':
        return 'Baixa';
      case 'medium':
        return 'Média';
      case 'high':
        return 'Alta';
      default:
        return 'Não definida';
    }
  };

  return (
    <>
      <Draggable draggableId={draggableId} index={index}>
        {(provided) => (
          <Card
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={handleCardClick}
            sx={{
              mb: 1,
              boxShadow: 1,
              '&:hover': { boxShadow: 3 },
              bgcolor: theme.palette.background.paper,
              color: theme.palette.text.primary,
              position: 'relative',
            }}
          >
            <IconButton
              size="small"
              onClick={handleMenuOpen}
              sx={{
                position: 'absolute',
                top: 2,
                right: 2,
                opacity: 0.7,
                '&:hover': { opacity: 1 },
              }}
            >
              <MoreVert fontSize="small" />
            </IconButton>
            <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
              <Typography variant="subtitle2" noWrap sx={{ mb: 0.5 }}>
                {truncateText(licitacao.titulo, 50)}
              </Typography>
              <Typography variant="body2" color="text.secondary" noWrap sx={{ mb: 1 }}>
                {licitacao.orgao}
              </Typography>
              <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                <Box display="flex" alignItems="center">
                  <EventAvailable fontSize="small" color="action" sx={{ mr: 0.5 }} />
                  <Typography variant="caption">
                    {licitacao.abertura_datetime
                      ? formatIsoDate(licitacao.abertura_datetime)
                      : 'N/A'}
                  </Typography>
                </Box>
                <Chip
                  icon={<Public style={{ fontSize: '1rem' }} />}
                  label={PORTAL_NAMES[licitacao.id_portal]}
                  size="small"
                  color="primary"
                  variant="outlined"
                />
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                  <AttachMoney
                    fontSize="small"
                    color={licitacao.bidValue ? 'error' : 'action'}
                    sx={{ mr: 0.5 }}
                  />
                  <Typography
                    variant="caption"
                    color={licitacao.bidValue ? 'error' : 'textPrimary'}
                  >
                    {licitacao.bidValue
                      ? new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(licitacao.bidValue)
                      : licitacao.valor > 0
                        ? new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(licitacao.valor)
                        : 'Valor não informado'}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <LocationOn fontSize="small" color="action" sx={{ mr: 0.5 }} />
                  <Typography variant="caption">
                    {licitacao.municipio ? `${licitacao.municipio}/${licitacao.uf}` : 'N/A'}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                <Box display="flex" alignItems="center">
                  <Public fontSize="small" color="action" sx={{ mr: 0.5 }} />
                  <Typography variant="caption">
                    {ESFERAS[licitacao.esfera] || 'Não informada'}
                  </Typography>
                </Box>
                {licitacao.importance && (
                  <Chip
                    label={getImportanceLabel(licitacao.importance)}
                    size="small"
                    sx={(theme) => {
                      const bgColor = getImportanceColor(licitacao.importance || '');
                      return {
                        backgroundColor: bgColor,
                        color: theme.palette.getContrastText(bgColor),
                        fontWeight: 'bold',
                        '& .MuiChip-label': {
                          color: theme.palette.getContrastText(bgColor),
                        },
                      };
                    }}
                  />
                )}
              </Box>
            </CardContent>
          </Card>
        )}
      </Draggable>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={handleView}>
          <ListItemIcon>
            <Visibility fontSize="small" />
          </ListItemIcon>
          <ListItemText>Ver detalhes</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleRemoveClick}>
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <ListItemText>Remover do quadro</ListItemText>
        </MenuItem>
      </Menu>
      <LicitacaoDetailsModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        licitacao={licitacao}
      />
      <Dialog
        open={isConfirmDialogOpen}
        onClose={handleRemoveCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirmar remoção'}</DialogTitle>
        <DialogContent>
          <Typography>Tem certeza que deseja remover esta licitação do quadro Kanban?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveCancel} color="primary" variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleRemoveConfirm} color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(KanbanCard);
