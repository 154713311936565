import { isKeyOfLicitacoesSearchParams } from '@constants';
import { Esfera, Licitacao, LicitacoesSearchParams, Portal } from '@types';
import { format, isAfter, isToday, parseISO } from 'date-fns';

export const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

export const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text;

  const truncated = text.substr(0, maxLength);
  const lastSpaceIndex = truncated.lastIndexOf(' ');

  if (lastSpaceIndex === -1) return truncated + '...';

  return truncated.substr(0, lastSpaceIndex) + '...';
};

export const getLicitacaoStatus = (
  status: string | null,
  abertura_datetime: string | null,
  fechamento_datetime: string | null,
) => {
  const now = new Date();

  if (status === 'NOVO' && abertura_datetime && isAfter(now, new Date(abertura_datetime))) {
    return 'EM ANDAMENTO';
  }

  if (fechamento_datetime && isAfter(now, new Date(fechamento_datetime))) {
    return 'ENCERRADO';
  }

  return status || 'NOVO';
};

export const formatDateTime = (dateString: string | null | undefined) => {
  if (!dateString) return 'N/A';
  try {
    return format(new Date(dateString), 'dd/MM/yyyy HH:mm');
  } catch (error) {
    console.error('Error formatting date:', dateString, error);
    return 'Invalid Date';
  }
};

export const formatIsoDate = (
  dateString: string | null | undefined,
  outputFormat: string = 'dd/MM/yyyy HH:mm',
) => {
  if (!dateString) return 'N/A';
  try {
    // Extract date and time parts from the ISO string
    const [datePart, timePart] = dateString.split('T');
    const [year, month, day] = datePart.split('-');
    const [hour, minute] = timePart.split(':');

    // Construct a date string in a format that won't be adjusted
    const dateTimeString = `${year}-${month}-${day} ${hour}:${minute}`;

    // Parse the date without timezone conversion
    const date = new Date(dateTimeString);

    // Format the date
    return format(date, outputFormat);
  } catch (error) {
    console.error('Error formatting date:', dateString, error);
    return 'Invalid Date';
  }
};

export const isNew = (date: string) => {
  return isToday(parseISO(date));
};

const COMPRASNET_URL = 'https://cnetmobile.estaleiro.serpro.gov.br/';
export const getComprasNetId = (licitacao: Licitacao) => {
  let uasg = null;
  let numeroAviso = null;
  let modalidade = null;
  if (licitacao.id_licitacao.startsWith('CN-')) {
    const ids = licitacao.id_licitacao.split('-');
    if (ids.length < 4) {
      console.log(`Invalid ComprasNet CN- format: ${licitacao.id_licitacao}`);
      return null;
    }
    uasg = ids[1];
    numeroAviso = ids[3];
    modalidade = ids[2];
  } else if (licitacao.id_licitacao.startsWith('PNCP-')) {
    if (licitacao.linkExterno.startsWith(COMPRASNET_URL)) {
      return licitacao.linkExterno.split('compra=')[1];
    } else if (
      licitacao.portal_data.linkSistemaOrigem &&
      licitacao.portal_data.linkSistemaOrigem.startsWith(COMPRASNET_URL)
    ) {
      return licitacao.portal_data.linkSistemaOrigem.split('compra=')[1];
    }
    if (
      !licitacao.codigo_uasg ||
      !licitacao.portal_data ||
      !licitacao.portal_data.numeroCompra ||
      !licitacao.portal_data.anoCompra
    ) {
      console.log(`Invalid getComprasNetId PNCP data:`, licitacao);
      return null;
    }
    uasg = licitacao.codigo_uasg;
    numeroAviso = licitacao.portal_data.numeroCompra + licitacao.portal_data.anoCompra.toString();
    modalidade = licitacao.id_tipo.toString();
  } else {
    console.log(`Invalid getComprasNetId data:`, licitacao);
    return null;
  }

  const paddedUasg = String(uasg).padStart(6, '0');
  const paddedNumeroAviso = String(numeroAviso).padStart(8, '0');
  const paddedModalidade = String(modalidade).padStart(2, '0');

  return `${paddedUasg}${paddedModalidade}${paddedNumeroAviso}`;
};

export const parseSearchParams = (locationSearch: string) => {
  const searchParams = new URLSearchParams(locationSearch);
  const params: Partial<LicitacoesSearchParams> = {};

  searchParams.forEach((value, key) => {
    if (isKeyOfLicitacoesSearchParams(key)) {
      if (key === 'id_portal') {
        params[key] = params[key] || [];
        (params[key] as Portal[]).push(value as Portal);
      } else if (key === 'esfera') {
        params[key] = params[key] || [];
        (params[key] as Esfera[]).push(value as Esfera);
      } else if (key === 'data_abertura_inicio' || key === 'data_abertura_fim') {
        params[key] = value;
      } else if (key === 'valor_min' || key === 'valor_max') {
        params[key] = value;
      } else if (key === 'match_exact') {
        params[key] = value === 'true';
      } else if (key === 'cnae') {
        params[key] = params[key] || [];
        (params[key] as string[]).push(value);
      } else if (key === 'palavra_chave') {
        if (!params[key]) {
          params[key] = [];
        }
        (params[key] as string[]).push(decodeURIComponent(value));
      } else if (key === 'edital' || key === 'item') {
        params[key] = decodeURIComponent(value);
      } else if (Array.isArray(params[key])) {
        (params[key] as string[]).push(value);
      } else {
        params[key] = [value] as any;
      }
    }
  });

  return params;
};

// Function to determine if a color is light or dark
const isLightColor = (color: string) => {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 155;
};

// Function to get the text color based on the background color
export const getTextColor = (backgroundColor: string) => {
  return isLightColor(backgroundColor) ? '#000000' : '#FFFFFF';
};

// Function to get portal color and ensure readability
export const getPortalColorAndText = (portalId: string) => {
  const colors = ['#4caf50', '#2196f3', '#ff9800', '#e91e63', '#9c27b0'];
  const backgroundColor = colors[parseInt(portalId) % colors.length];
  const textColor = getTextColor(backgroundColor);
  return { backgroundColor, textColor };
};
