import { Alert, Box, Button, Typography } from '@mui/material';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useState } from 'react';

interface PaymentMethodFormProps {
  onSubmit: (paymentMethodId: string) => void;
}

const PaymentMethodForm: React.FC<PaymentMethodFormProps> = ({ onSubmit }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);
    setIsProcessing(true);

    if (!stripe || !elements) {
      setError('Stripe não está carregado. Por favor, tente novamente mais tarde.');
      setIsProcessing(false);
      return;
    }

    const cardNumber = elements.getElement(CardNumberElement);
    const cardExpiry = elements.getElement(CardExpiryElement);
    const cardCvc = elements.getElement(CardCvcElement);

    if (!cardNumber || !cardExpiry || !cardCvc) {
      setError(
        'Elementos do cartão não encontrados. Por favor, recarregue a página e tente novamente.',
      );
      setIsProcessing(false);
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber,
    });

    if (error) {
      setError(
        error.message || 'Ocorreu um erro ao processar o cartão. Por favor, tente novamente.',
      );
    } else if (paymentMethod) {
      try {
        await onSubmit(paymentMethod.id);
      } catch (submitError) {
        if (submitError instanceof Error) {
          setError(submitError.message);
        } else {
          setError('Falha ao salvar o método de pagamento. Por favor, tente novamente.');
        }
      }
    }

    setIsProcessing(false);
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
        iconColor: '#9e2146',
      },
    },
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          Número do Cartão
        </Typography>
        <CardNumberElement options={cardElementOptions} />
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          Data de Expiração
        </Typography>
        <CardExpiryElement options={cardElementOptions} />
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          CVC
        </Typography>
        <CardCvcElement options={cardElementOptions} />
      </Box>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={!stripe || isProcessing}
        sx={{ mt: 2 }}
      >
        {isProcessing ? 'Processando...' : 'Salvar Método de Pagamento'}
      </Button>
    </form>
  );
};

export default PaymentMethodForm;
