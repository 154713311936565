import Loading from '@components/Loading';
import PaymentMethodForm from '@components/Payment/PaymentMethodForm';
import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { formatStatus, usePaymentStore } from '@store/paymentStore';
import { useProfileStore } from '@store/profileStore';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import React, { useCallback, useEffect, useState } from 'react';

const Payments: React.FC = () => {
  const theme = useTheme();
  const {
    currentPlans,
    nextBillingDates,
    paymentHistory,
    paymentMethods,
    isLoading,
    error,
    fetchPaymentInfo,
    cancelSubscription,
    reactivateSubscription,
    updatePaymentMethod,
    addPaymentMethod,
  } = usePaymentStore();
  const { userData } = useProfileStore();

  const [openReactivateDialog, setOpenReactivateDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openPaymentMethodDialog, setOpenPaymentMethodDialog] = useState(false);
  const [actionPlanId, setActionPlanId] = useState<string | null>(null);
  const [paymentMethodError, setPaymentMethodError] = useState<string | null>(null);
  const [stripe, setStripe] = useState<Stripe | null>(null);

  const loadStripeInstance = useCallback(async () => {
    if (!stripe) {
      const stripeInstance = await loadStripe(process.env.REACT_APP_STRIPE_KEY!);
      setStripe(stripeInstance);
    }
  }, [stripe]);

  useEffect(() => {
    if (userData && userData.stripeCustomerId) {
      fetchPaymentInfo();
    }
  }, [fetchPaymentInfo, userData]);

  useEffect(() => {
    if (openPaymentMethodDialog && !stripe) {
      loadStripeInstance();
    }
  }, [openPaymentMethodDialog, stripe, loadStripeInstance]);

  if (isLoading) {
    return <Loading withHeader={false} />;
  }

  const handleReactivateClick = (planId: string) => {
    setActionPlanId(planId);
    setOpenReactivateDialog(true);
  };

  const handleCancelClick = (planId: string) => {
    setActionPlanId(planId);
    setOpenCancelDialog(true);
  };

  const handleReactivateConfirm = () => {
    if (actionPlanId) {
      reactivateSubscription(actionPlanId);
    }
    setOpenReactivateDialog(false);
  };

  const handleCancelConfirm = () => {
    if (actionPlanId) {
      cancelSubscription(actionPlanId);
    }
    setOpenCancelDialog(false);
  };

  const handleDialogClose = () => {
    setOpenReactivateDialog(false);
    setOpenCancelDialog(false);
    setOpenPaymentMethodDialog(false);
  };

  const handleAddPaymentMethod = () => {
    setOpenPaymentMethodDialog(true);
    loadStripeInstance();
  };

  const handlePaymentMethodSubmit = async (paymentMethodId: string) => {
    setPaymentMethodError(null);
    try {
      if (!paymentMethods || paymentMethods.length === 0) {
        await addPaymentMethod(paymentMethodId);
      } else {
        await updatePaymentMethod(paymentMethodId);
      }
      setOpenPaymentMethodDialog(false);
    } catch (paymentStoreError) {
      if (paymentStoreError instanceof Error) {
        setPaymentMethodError(paymentStoreError.message);
      } else {
        setPaymentMethodError(
          'Falha ao adicionar/atualizar o método de pagamento. Por favor, tente novamente.',
        );
      }
    }
  };

  return (
    <Box sx={{ my: 4 }}>
      {userData && !userData.isActive && (
        <Paper
          elevation={3}
          sx={{
            p: 3,
            mb: 4,
            backgroundColor: theme.palette.background.paper,
            borderRadius: 2,
          }}
        >
          <Box display="flex" alignItems="center" mb={2}>
            <InfoIcon color="warning" sx={{ mr: 2, fontSize: 40 }} />
            <Typography variant="h6" color="warning.main">
              Pagamento Pendente
            </Typography>
          </Box>
          <Typography color="text.secondary" paragraph>
            Notamos que seu pagamento está pendente. Para continuar usando todos os recursos do
            Licitou, por favor, atualize suas informações de pagamento.
          </Typography>
          <Typography color="text.secondary">
            Se você tiver alguma dúvida ou precisar de assistência, não hesite em entrar em contato
            com nosso suporte.
          </Typography>
        </Paper>
      )}
      <Typography variant="h4" component="h1" gutterBottom color="primary">
        Pagamentos
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {userData && !userData.stripeCustomerId && userData.isActive && (
        <Alert severity="info" sx={{ mb: 2 }}>
          Você já tem um plano ativo. Não precisa se preocupar com pagamentos.
        </Alert>
      )}

      <Card sx={{ backgroundColor: theme.palette.background.paper, mb: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Planos Atuais
          </Typography>
          {currentPlans && currentPlans.length > 0 ? (
            currentPlans.map((plan, index) => (
              <Box key={plan.id} sx={{ mb: 2 }}>
                <Typography>{plan.name || 'Plano sem nome'}</Typography>
                <Typography variant="body2" color="text.secondary">
                  Status: {formatStatus(plan.status, plan.cancelAtPeriodEnd)}
                </Typography>
                {nextBillingDates && nextBillingDates[index] && (
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    {plan.cancelAtPeriodEnd || plan.status === 'canceled'
                      ? 'Acesso até'
                      : 'Próxima cobrança'}
                    : {new Date(nextBillingDates[index]).toLocaleDateString()}
                  </Typography>
                )}
                {plan.status !== 'canceled' && !plan.cancelAtPeriodEnd && (
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1 }}
                    onClick={() => handleCancelClick(plan.id)}
                  >
                    Cancelar Assinatura
                  </Button>
                )}
                {(plan.status === 'canceled' || plan.cancelAtPeriodEnd) && (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 1 }}
                    onClick={() => handleReactivateClick(plan.id)}
                  >
                    Reativar Assinatura
                  </Button>
                )}
              </Box>
            ))
          ) : (
            <Typography>Nenhum plano ativo</Typography>
          )}
        </CardContent>
      </Card>

      <Card sx={{ backgroundColor: theme.palette.background.paper, mb: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Métodos de Pagamento
          </Typography>
          {paymentMethods && paymentMethods.length > 0 ? (
            paymentMethods.map((method) => (
              <Typography key={method.id}>
                {method.brand} terminando em {method.last4}
              </Typography>
            ))
          ) : (
            <Typography>Nenhum método de pagamento cadastrado</Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleAddPaymentMethod}
          >
            {paymentMethods && paymentMethods.length > 0
              ? 'Atualizar Método de Pagamento'
              : 'Adicionar Método de Pagamento'}
          </Button>
        </CardContent>
      </Card>
      <Dialog open={openReactivateDialog} onClose={handleDialogClose}>
        <DialogTitle>Confirmar Reativação</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ao reativar sua assinatura, ela continuará ativa até o final do período atual. Você não
            será cobrado imediatamente. A próxima cobrança ocorrerá na data regular do seu ciclo de
            faturamento.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancelar</Button>
          <Button onClick={handleReactivateConfirm} autoFocus>
            Confirmar Reativação
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openCancelDialog} onClose={handleDialogClose}>
        <DialogTitle>Confirmar Cancelamento</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja cancelar sua assinatura? Você continuará tendo acesso aos
            serviços até o final do período atual, mas sua assinatura não será renovada
            automaticamente.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Voltar</Button>
          <Button onClick={handleCancelConfirm} color="secondary" autoFocus>
            Confirmar Cancelamento
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openPaymentMethodDialog} onClose={handleDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>
          {paymentMethods && paymentMethods.length > 0
            ? 'Atualizar Método de Pagamento'
            : 'Adicionar Método de Pagamento'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Por favor, insira os detalhes do seu cartão de crédito abaixo.
          </DialogContentText>
          {paymentMethodError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {paymentMethodError}
            </Alert>
          )}
          {stripe ? (
            <Elements stripe={stripe}>
              <PaymentMethodForm onSubmit={handlePaymentMethodSubmit} />
            </Elements>
          ) : (
            <CircularProgress />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Card sx={{ backgroundColor: theme.palette.background.paper }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Histórico de Pagamentos
          </Typography>
          {paymentHistory.filter((payment) => payment.amount > 0).length > 0 ? (
            <Grid container spacing={2}>
              {paymentHistory
                .filter((payment) => payment.amount > 0)
                .map((payment, index) => (
                  <Grid item xs={12} key={index}>
                    <Typography>
                      {new Date(payment.date).toLocaleDateString()} - R$ {payment.amount.toFixed(2)}
                    </Typography>
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Typography>Nenhum pagamento registrado</Typography>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Payments;
