import { Search } from '@mui/icons-material';
import { Box, Link, SvgIconProps, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface InfoItemProps {
  icon: React.ComponentType<SvgIconProps>;
  label: string;
  value: string | number;
  link?: string;
  copyable?: boolean;
}

const InfoItem: React.FC<InfoItemProps> = ({ icon: Icon, label, value, link, copyable }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(value.toString());
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <Icon sx={{ mr: 1, color: 'primary.main' }} />
      <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
        {label}:
      </Typography>
      {link ? (
        <Tooltip title={`Buscar licitações do mesmo ${label.toLowerCase()}`}>
          <Link
            component={RouterLink}
            to={link}
            color="primary"
            sx={{
              display: 'flex',
              alignItems: 'center',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            <Typography variant="body2">{value}</Typography>
            <Search sx={{ ml: 0.5, fontSize: '0.875rem' }} />
          </Link>
        </Tooltip>
      ) : copyable ? (
        <Tooltip title="Clique para copiar">
          <Typography
            variant="body2"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={handleCopy}
          >
            {value}
          </Typography>
        </Tooltip>
      ) : (
        <Typography variant="body2">{value}</Typography>
      )}
    </Box>
  );
};

export default InfoItem;
