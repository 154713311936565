import logo from '@assets/logo.png';
import UserMenu from '@components/Layout/UserMenu';
import ProfileSelect from '@components/ProfileSelect';
import FeedIcon from '@mui/icons-material/Feed';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import GavelIcon from '@mui/icons-material/Gavel';
import RecommendIcon from '@mui/icons-material/Recommend';
import SettingsIcon from '@mui/icons-material/Settings';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Header: React.FC<{ className?: string; dataTourValue?: string }> = ({
  className,
  dataTourValue,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const isActive = (path: string) => location.pathname.startsWith(path);

  const menuItems = [
    {
      name: 'Licitações',
      path: '/licitacoes',
      icon: <GavelIcon />,
      color: theme.palette.primary.main,
    },
    {
      name: 'Recomendadas',
      path: '/recomendadas',
      icon: <RecommendIcon />,
      color: theme.palette.secondary.main,
    },
    {
      name: 'Minhas Licitações',
      path: '/minhas-licitacoes',
      icon: <FolderSpecialIcon />,
      color: theme.palette.info.main,
    },
    {
      name: 'Boletins',
      path: '/boletins',
      icon: <FeedIcon />,
      color: theme.palette.warning.main,
    },
    {
      name: 'Kanban',
      path: '/kanban',
      icon: <ViewKanbanIcon />,
      color: theme.palette.success.main,
    },
  ];

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: 'transparent',
        backgroundImage: 'none',
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Toolbar>
        <Link
          to="/licitacoes"
          style={{
            textDecoration: 'none',
            color: theme.palette.text.primary,
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img src={logo} alt="Licitou Logo" style={{ height: 40, marginRight: 8 }} />
          <Typography variant="h6">Licitou</Typography>
        </Link>
        <ProfileSelect />
        <Box sx={{ display: 'flex', alignItems: 'center' }} className={className}>
          {!isMobile ? (
            <>
              {menuItems.map((item) => (
                <Button
                  key={item.name}
                  color="inherit"
                  onClick={() => navigate(item.path)}
                  startIcon={React.cloneElement(item.icon, { style: { color: item.color } })}
                  sx={{
                    color: isActive(item.path) ? item.color : theme.palette.text.primary,
                    backgroundColor: isActive(item.path)
                      ? theme.palette.action.selected
                      : 'transparent',
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                >
                  {item.name}
                </Button>
              ))}
              <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
              <IconButton
                color="inherit"
                onClick={() => navigate('/configuracoes')}
                data-tour={dataTourValue || 'config-button'}
                sx={{
                  ml: 1,
                  mr: 2,
                  color: isActive('/configuracoes')
                    ? theme.palette.info.main
                    : theme.palette.text.primary,
                }}
              >
                <SettingsIcon />
              </IconButton>
            </>
          ) : (
            <>
              {menuItems.map((item, index) => (
                <IconButton
                  key={item.name}
                  color="inherit"
                  onClick={() => navigate(item.path)}
                  sx={{
                    color: isActive(item.path) ? item.color : theme.palette.text.primary,
                    mr: index < menuItems.length - 1 ? 1 : 0,
                  }}
                >
                  {React.cloneElement(item.icon, {
                    style: { color: isActive(item.path) ? item.color : theme.palette.text.primary },
                  })}
                </IconButton>
              ))}
              <IconButton
                color="inherit"
                onClick={() => navigate('/configuracoes')}
                data-tour={dataTourValue || 'config-button'}
                sx={{ ml: 1, mr: 2, color: theme.palette.info.main }}
              >
                <SettingsIcon />
              </IconButton>
            </>
          )}
          <UserMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
