import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Snackbar,
  TextField,
  useTheme,
} from '@mui/material';
import { useProfileStore } from '@store/profileStore';
import { FirestoreUser } from '@types';
import React, { useEffect, useState } from 'react';

const UserProfile: React.FC = () => {
  const theme = useTheme();
  const { userData: user, saveUser } = useProfileStore();
  const [localUser, setLocalUser] = useState<FirestoreUser | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    if (user) {
      setLocalUser(user);
    }
  }, [user]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLocalUser((prev) => (prev ? { ...prev, [name]: value } : null));
  };

  const handleSaveChanges = async () => {
    if (localUser) {
      setIsLoading(true);
      setError(null);
      try {
        await saveUser(localUser);
        setSuccessMessage('Perfil atualizado com sucesso!');
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError('Erro ao atualizar o perfil. Por favor, tente novamente.');
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Box sx={{ my: 4 }}>
      <Card sx={{ backgroundColor: theme.palette.background.paper }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Nome"
                name="displayName"
                value={localUser?.displayName || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="E-mail"
                name="email"
                value={localUser?.email || ''}
                onChange={handleInputChange}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Telefone"
                name="phoneNumber"
                value={localUser?.phoneNumber || ''}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              onClick={handleSaveChanges}
              disabled={isLoading}
              startIcon={isLoading ? <CircularProgress size={20} /> : null}
            >
              {isLoading ? 'Salvando...' : 'Salvar Alterações'}
            </Button>
          </Box>
        </CardContent>
      </Card>

      <Snackbar
        open={!!error || !!successMessage}
        autoHideDuration={6000}
        onClose={() => {
          setError(null);
          setSuccessMessage(null);
        }}
      >
        <Alert
          onClose={() => {
            setError(null);
            setSuccessMessage(null);
          }}
          severity={error ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {error || successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserProfile;
