import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { cnaes, formatCNAE, useProfileStore } from '@store/profileStore';
import { CNAE, Company, CompanyCNAE } from '@types';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CompanyInfo: React.FC = () => {
  const theme = useTheme();
  const {
    company: storedCompany,
    fetchCompany,
    setCompany,
    setOnboardingPreferences,
  } = useProfileStore();
  const navigate = useNavigate();

  const [cnpj, setCnpj] = useState(storedCompany?.cnpj || '');
  const [selectedCNAEs, setSelectedCNAEs] = useState<CompanyCNAE[]>(
    storedCompany?.cnaePrincipal ? [storedCompany.cnaePrincipal] : [],
  );
  const [cnpjError, setCnpjError] = useState('');
  const [localCompanyInfo, setLocalCompanyInfo] = useState<Company | null>(storedCompany);
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const isOnboarding = !storedCompany;

  const steps = ['Informações da Empresa', 'Confirmação'];

  const handleSkip = () => {
    navigate('/configuracoes/preferencias');
  };

  useEffect(() => {
    if (storedCompany) {
      setLocalCompanyInfo(storedCompany);
      setCnpj(maskCNPJ(storedCompany.cnpj));
      setSelectedCNAEs(
        storedCompany.cnaePrincipal
          ? [storedCompany.cnaePrincipal, ...(storedCompany.cnaesSecundarias || [])]
          : [],
      );
    }
  }, [storedCompany]);

  const validateCNPJ = (cnpjToValidate: string) => {
    const cnpjRegex = /^\d{14}$/;
    return cnpjRegex.test(cnpjToValidate);
  };

  const handleFetchCompany = async (cnpjToFetch: string) => {
    if (!validateCNPJ(cnpjToFetch)) {
      setCnpjError('CNPJ inválido. Deve conter 14 dígitos numéricos.');
      return;
    }
    setCnpjError('');
    setIsLoading(true);

    try {
      const result = await fetchCompany(cnpjToFetch);
      if ('error' in result) {
        setCnpjError(result.error || 'Empresa não encontrada. Verifique o CNPJ e tente novamente.');
        setLocalCompanyInfo(null);
      } else {
        setLocalCompanyInfo(result);
        setSelectedCNAEs(
          result.cnaePrincipal ? [result.cnaePrincipal, ...(result.cnaesSecundarias || [])] : [],
        );
      }
    } catch (error) {
      console.error('Error fetching company:', error);
      setCnpjError('Ocorreu um erro ao buscar a empresa. Tente novamente mais tarde.');
      setLocalCompanyInfo(null);
    } finally {
      setIsLoading(false);
    }
  };

  const maskCNPJ = (value: string | undefined | null): string => {
    if (!value) return '';
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  };

  const handleCnpjChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maskedValue = maskCNPJ(e.target.value);
    setCnpj(maskedValue);
    setCnpjError('');
    const unmaskedValue = maskedValue.replace(/\D/g, '');
    if (unmaskedValue.length === 14) {
      handleFetchCompany(unmaskedValue);
    }
  };

  const handleCnaeSelect = (event: React.SyntheticEvent, value: CNAE | null) => {
    if (value && !selectedCNAEs.some((cnae) => cnae.id === value.cod)) {
      const newCNAE: CompanyCNAE = {
        id: value.cod,
        subclasse: formatCNAE(value.cod),
        descricao: value.desc,
      };
      setSelectedCNAEs((prev) => [...prev, newCNAE]);

      const updatedCompany = {
        ...localCompanyInfo,
        cnaePrincipal: selectedCNAEs.length === 0 ? newCNAE : localCompanyInfo?.cnaePrincipal,
        cnaesSecundarias: selectedCNAEs.length > 0 ? [...selectedCNAEs.slice(1), newCNAE] : [],
      } as Company;
      setLocalCompanyInfo(updatedCompany);
    }
  };

  const handleRemoveCNAE = (cnaeToRemove: CompanyCNAE) => {
    setSelectedCNAEs((prev) => prev.filter((cnae) => cnae.id !== cnaeToRemove.id));

    const updatedCompany = { ...localCompanyInfo } as Company;
    if (updatedCompany.cnaePrincipal?.id === cnaeToRemove.id) {
      updatedCompany.cnaePrincipal = selectedCNAEs[1] || null;
      updatedCompany.cnaesSecundarias = selectedCNAEs.slice(2);
    } else {
      updatedCompany.cnaesSecundarias = updatedCompany.cnaesSecundarias?.filter(
        (cnae) => cnae.id !== cnaeToRemove.id,
      );
    }
    setLocalCompanyInfo(updatedCompany);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleConfirmCompany = async () => {
    if (localCompanyInfo) {
      await setCompany(localCompanyInfo);
      if (isOnboarding) {
        const presetPreferences = {
          states: localCompanyInfo.endereco ? [localCompanyInfo.endereco.uf] : [],
          cities: localCompanyInfo.endereco
            ? [localCompanyInfo.endereco.municipio_IBGE.toString()]
            : [],
          companySummary: `${localCompanyInfo.razaoSocial} é uma empresa ${localCompanyInfo.porte?.descricao || ''} que atua principalmente no setor de ${localCompanyInfo.cnaePrincipal?.descricao || 'diversos setores'}.`,
        };

        setOnboardingPreferences(presetPreferences);
      }
      navigate('/configuracoes/preferencias');
    }
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <>
            <Card sx={{ mb: 2, backgroundColor: theme.palette.background.paper }}>
              <CardContent>
                <TextField
                  fullWidth
                  label="CNPJ"
                  value={cnpj}
                  onChange={handleCnpjChange}
                  error={!!cnpjError}
                  disabled={isLoading}
                  slotProps={{
                    input: {
                      endAdornment: isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : null,
                    },
                  }}
                  sx={{ mb: 2 }}
                />
              </CardContent>
              {cnpjError && (
                <Alert severity="info" sx={{ mb: 2 }}>
                  <AlertTitle>{cnpjError}</AlertTitle>
                  <Typography>
                    Se você ainda não tem o CNPJ da sua empresa, você pode selecionar apenas o CNAE
                    de sua empresa.
                  </Typography>
                </Alert>
              )}
            </Card>

            <Typography variant="h6" align="center" sx={{ my: 2 }}>
              OU escolha os CNAEs da sua empresa ou que tem maior relevância para você
            </Typography>

            <Card sx={{ mb: 2, backgroundColor: theme.palette.background.paper }}>
              <CardContent>
                <Autocomplete
                  options={cnaes}
                  getOptionLabel={(option) => `${option.cod} - ${option.desc}`}
                  onChange={handleCnaeSelect}
                  renderInput={(params) => <TextField {...params} label="Selecione os CNAEs" />}
                  fullWidth
                />
                <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {selectedCNAEs.map((cnae, index) => (
                    <Chip
                      key={cnae.id}
                      label={`${cnae.subclasse} - ${cnae.descricao}`}
                      onDelete={() => handleRemoveCNAE(cnae)}
                      color={index === 0 ? 'primary' : 'default'}
                    />
                  ))}
                </Box>
              </CardContent>
            </Card>
          </>
        );
      case 1:
        return (
          <Card sx={{ backgroundColor: theme.palette.background.paper }}>
            <CardContent>
              <Typography variant="body1" sx={{ mb: 1 }}>
                Razão Social: {localCompanyInfo?.razaoSocial}
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                Nome Fantasia: {localCompanyInfo?.nomeFantasia}
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                CNAE Principal: {localCompanyInfo?.cnaePrincipal?.descricao}
              </Typography>
              {localCompanyInfo?.cnaesSecundarias &&
                localCompanyInfo.cnaesSecundarias.length > 0 && (
                  <>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      CNAEs Secundários:
                    </Typography>
                    <ul>
                      {localCompanyInfo.cnaesSecundarias.map((cnae) => (
                        <li key={cnae.id}>
                          {cnae.subclasse} - {cnae.descricao}
                        </li>
                      ))}
                    </ul>
                  </>
                )}
            </CardContent>
          </Card>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ my: 4 }}>
      {isOnboarding && (
        <Paper
          elevation={3}
          sx={{
            p: 3,
            mb: 4,
            backgroundColor: theme.palette.background.paper,
            borderRadius: 2,
          }}
        >
          <Box display="flex" alignItems="center" mb={2}>
            <InfoIcon color="info" sx={{ mr: 2, fontSize: 40 }} />
            <Typography variant="h6" color="info.main">
              Bem-vindo ao Liciti
            </Typography>
          </Box>
          <Typography color="text.secondary" paragraph>
            Para começar a usar nosso sistema, precisamos de algumas informações sobre sua empresa.
          </Typography>
          <Typography color="text.secondary">
            Por favor, preencha o CNPJ ou selecione o CNAE principal da sua empresa. Estas
            informações nos ajudarão a fornecer recomendações mais precisas.
          </Typography>
        </Paper>
      )}

      <Typography variant="h4" component="h1" gutterBottom color="primary">
        {isOnboarding ? 'Configuração Inicial' : 'Dados da Empresa'}
      </Typography>

      {isOnboarding && (
        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}

      {isOnboarding ? (
        renderStepContent(activeStep)
      ) : (
        <>
          {renderStepContent(0)}
          {renderStepContent(1)}
        </>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        {isOnboarding && (
          <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
            Voltar
          </Button>
        )}
        <Box sx={{ flex: '1 1 auto' }} />
        {isOnboarding ? (
          <Button onClick={activeStep === steps.length - 1 ? handleConfirmCompany : handleNext}>
            {activeStep === steps.length - 1 ? 'Confirmar' : 'Próximo'}
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleConfirmCompany}
            disabled={!localCompanyInfo}
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            Salvar
          </Button>
        )}
        {isOnboarding && (
          <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
            Pular
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CompanyInfo;
