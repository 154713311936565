import {
  AccountBalance,
  AttachMoney,
  Business,
  Category,
  Description,
  Dns,
  Event,
  EventAvailable,
  ListAlt,
  LocationOn,
  OpenInNew,
  PictureAsPdf,
  Public as PublicIcon,
  Search,
  Today,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Link,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';

import FavoriteAnnotation from '@components/Favorite/FavoriteAnnotation';
import FavoriteButton from '@components/Favorite/FavoriteButton';
import Header from '@components/Layout/Header';
import AddToCalendarButton from '@components/Licitacao/AddToCalendarButton';
import InfoItem from '@components/Licitacao/InfoItem';
import WhatsAppButton from '@components/Licitacao/WhatsAppButton';
import Loading from '@components/Loading';
import PortalDetails from '@components/PortalDetails';
import { ESFERAS, PORTAL_NAMES } from '@constants';
import { useLicitacoesStore } from '@store/licitacoesStore';
import { Licitacao, MyLicitacoes } from '@types';
import { formatCurrency, formatIsoDate, getLicitacaoStatus } from '@utils';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

type CombinedLicitacao = Licitacao & MyLicitacoes;

const LicitacaoDetails: React.FC = () => {
  const theme = useTheme();
  const { id_licitacao } = useParams<{ id_licitacao: string }>();
  const {
    getLicitacaoById,
    fetchLicitacaoDetails,
    isLoadingDetails,
    getModalidadeInfo,
    isFavorite,
    lastLoadedLicitacaoId,
    lastLoadedLicitacao,
  } = useLicitacoesStore();
  const [licitacao, setLicitacao] = useState<CombinedLicitacao | null>(null);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (!id_licitacao) return;

    const fetchData = async () => {
      if (lastLoadedLicitacaoId === id_licitacao && lastLoadedLicitacao) {
        setLicitacao(lastLoadedLicitacao as CombinedLicitacao);
        return;
      }

      const currentLicitacao = getLicitacaoById(id_licitacao) as CombinedLicitacao | undefined;

      if (currentLicitacao) {
        setLicitacao(currentLicitacao);
      }

      const updatedLicitacao = await fetchLicitacaoDetails(id_licitacao);
      if (updatedLicitacao) {
        setLicitacao(updatedLicitacao as CombinedLicitacao);
      }
    };

    fetchData();
  }, [
    id_licitacao,
    getLicitacaoById,
    fetchLicitacaoDetails,
    lastLoadedLicitacaoId,
    lastLoadedLicitacao,
  ]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  if (!licitacao) {
    if (isLoadingDetails) {
      return <Loading />;
    }
    return (
      <Box sx={{ backgroundColor: theme.palette.background.default, minHeight: '100vh', py: 4 }}>
        <Container maxWidth="lg">
          <Header />
          <Box my={4}>
            <Typography variant="h4" component="h1" gutterBottom color="primary">
              Licitação não encontrada
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Search />}
              component={RouterLink}
              to={`/licitacoes`}
            >
              Voltar para a lista de licitações
            </Button>
          </Box>
        </Container>
      </Box>
    );
  }

  const modalidadeInfo = getModalidadeInfo(licitacao.id_tipo);

  const currentStatus = getLicitacaoStatus(
    licitacao.status,
    licitacao.abertura_datetime,
    licitacao.fechamento_datetime,
  );

  return (
    <Box sx={{ backgroundColor: theme.palette.background.default, minHeight: '100vh', py: 4 }}>
      <Container maxWidth="lg">
        <Header />
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom color="primary">
            {licitacao.titulo}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ backgroundColor: theme.palette.background.paper, position: 'relative' }}>
                <Box sx={{ position: 'absolute', top: 10, right: 10, display: 'flex', gap: 1 }}>
                  {licitacao.linkExterno && (
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      startIcon={<OpenInNew />}
                      href={licitacao.linkExterno}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Abrir no portal
                    </Button>
                  )}
                  <FavoriteButton licitacaoId={licitacao.id_licitacao} />
                </Box>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <InfoItem icon={AccountBalance} label="Órgão" value={licitacao.orgao} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InfoItem
                        icon={LocationOn}
                        label="Local"
                        value={`${licitacao.municipio} - ${licitacao.uf}`}
                        link={`/licitacoes?municipio_ibge=${licitacao.municipio_IBGE}`}
                      />
                      <InfoItem
                        icon={Business}
                        label="Portal"
                        value={
                          licitacao.portal ||
                          (licitacao.id_portal && PORTAL_NAMES[licitacao.id_portal]) ||
                          'N/A'
                        }
                        link={
                          licitacao.id_portal
                            ? `/licitacoes?id_portal=${licitacao.id_portal}`
                            : undefined
                        }
                      />
                      <InfoItem
                        icon={Category}
                        label="Código CNAE"
                        value={licitacao.cnae || 'N/A'}
                        link={licitacao.cnae ? `/licitacoes?cnae=${licitacao.cnae}` : undefined}
                      />
                      <InfoItem
                        icon={Business}
                        label="Código UASG"
                        value={licitacao.codigo_uasg || 'N/A'}
                        link={
                          licitacao.codigo_uasg
                            ? `/licitacoes?codigo_uasg=${licitacao.codigo_uasg}`
                            : undefined
                        }
                      />
                      <InfoItem
                        icon={modalidadeInfo.icon}
                        label="Modalidade"
                        value={modalidadeInfo.name}
                        link={`/licitacoes?modalidade=${licitacao.id_tipo}`}
                      />
                      <InfoItem
                        icon={ListAlt}
                        label="Número do edital"
                        value={licitacao.numero_edital || 'N/A'}
                        link={
                          licitacao.numero_edital
                            ? `/licitacoes?edital=${licitacao.numero_edital}`
                            : undefined
                        }
                      />
                      <InfoItem
                        icon={PublicIcon}
                        label="Esfera"
                        value={ESFERAS[licitacao.esfera] || 'Não informada'}
                        link={`/licitacoes?esfera=${licitacao.esfera}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InfoItem
                        icon={AttachMoney}
                        label="Valor estimado"
                        value={licitacao.valor > 0 ? formatCurrency(licitacao.valor) : 'N/A'}
                      />
                      <InfoItem
                        icon={Today}
                        label="Data publicação"
                        value={formatIsoDate(licitacao.created_at)}
                      />
                      <InfoItem
                        icon={EventAvailable}
                        label="Data de abertura"
                        value={formatIsoDate(licitacao.abertura_datetime)}
                      />
                      <InfoItem
                        icon={Event}
                        label="Data de fechamento"
                        value={formatIsoDate(licitacao.fechamento_datetime)}
                      />
                      <InfoItem icon={Dns} label="Status" value={currentStatus} />
                      <InfoItem
                        icon={Description}
                        label="ID Licitou"
                        value={licitacao.id_licitacao}
                        copyable
                      />
                    </Grid>
                  </Grid>

                  <Box mt={4}>
                    <Typography variant="h6" color="primary" gutterBottom>
                      Descrição
                    </Typography>
                    <Typography variant="body1" color="text.primary">
                      {licitacao.objeto}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <WhatsAppButton licitacao={licitacao} />
                    <AddToCalendarButton licitacao={licitacao} />
                    {licitacao.documents && licitacao.documents.length > 0 && (
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<PictureAsPdf />}
                        href={licitacao.documents[0].downloadUrl as string}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Baixar Edital
                      </Button>
                    )}
                  </Box>
                  {licitacao && isFavorite(licitacao.id_licitacao) && (
                    <Box mt={4}>
                      <Typography variant="h6" gutterBottom color="primary">
                        Anotações
                      </Typography>
                      <FavoriteAnnotation licitacaoId={licitacao.id_licitacao} />
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ backgroundColor: theme.palette.background.paper }}>
                <CardContent>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    centered
                    variant="fullWidth"
                    sx={{
                      borderRadius: '8px 8px 0 0',
                      '& .MuiTab-root': {
                        color: theme.palette.text.primary,
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        textTransform: 'none',
                        minHeight: 56,
                        '&.Mui-selected': {
                          color: theme.palette.secondary.main,
                        },
                      },
                      '& .MuiTabs-indicator': {
                        backgroundColor: theme.palette.secondary.main,
                        height: 3,
                      },
                    }}
                  >
                    <Tab label="Itens" />
                    <Tab label="Documentos" />
                  </Tabs>
                  <Box sx={{ mt: 2 }}>
                    {tabValue === 0 && (
                      <TableContainer component={Paper} sx={{ maxHeight: 440, overflow: 'auto' }}>
                        <Table size="small" stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '0.875rem',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                Item
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '0.875rem',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                Quantidade
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '0.875rem',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                Valor Unitário
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '0.875rem',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                Valor Total
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {licitacao.items &&
                              licitacao.items.map((item, index) => (
                                <TableRow key={index} hover>
                                  <TableCell sx={{ fontSize: '0.8125rem' }}>
                                    <Typography
                                      variant="body2"
                                      component="span"
                                      sx={{ fontWeight: 'bold' }}
                                    >
                                      {item.numeroItem}:
                                    </Typography>{' '}
                                    {item.descricao}
                                  </TableCell>
                                  <TableCell
                                    sx={{ fontSize: '0.8125rem', whiteSpace: 'nowrap' }}
                                  >{`${item.quantidade} ${item.unidadeMedida}`}</TableCell>
                                  <TableCell sx={{ fontSize: '0.8125rem', whiteSpace: 'nowrap' }}>
                                    {formatCurrency(item.valorUnitarioEstimado)}
                                  </TableCell>
                                  <TableCell sx={{ fontSize: '0.8125rem', whiteSpace: 'nowrap' }}>
                                    {formatCurrency(item.valorTotal)}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                    {tabValue === 1 && (
                      <TableContainer component={Paper} sx={{ maxHeight: 440, overflow: 'auto' }}>
                        <Table size="small" stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '0.875rem',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                Nome
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '0.875rem',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                Tipo
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '0.875rem',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                Data
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '0.875rem',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                Download
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {licitacao.documents &&
                              licitacao.documents.map((doc, index) => (
                                <TableRow key={index} hover>
                                  <TableCell sx={{ fontSize: '0.8125rem' }}>{doc.nome}</TableCell>
                                  <TableCell sx={{ fontSize: '0.8125rem' }}>{doc.tipo}</TableCell>
                                  <TableCell sx={{ fontSize: '0.8125rem', whiteSpace: 'nowrap' }}>
                                    {formatIsoDate(doc.dataHora)}
                                  </TableCell>
                                  <TableCell sx={{ fontSize: '0.8125rem' }}>
                                    {doc.downloadUrl && (
                                      <Link
                                        href={doc.downloadUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <PictureAsPdf color="primary" />
                                      </Link>
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            {licitacao.id_portal &&
              licitacao.portal_data &&
              Object.keys(licitacao.portal_data).length > 0 && (
                <Grid item xs={12}>
                  <PortalDetails portalData={licitacao.portal_data} licitacao={licitacao} />
                </Grid>
              )}
            {isLoadingDetails && (
              <Grid item xs={12}>
                <Card sx={{ backgroundColor: theme.palette.background.paper }}>
                  <CardContent>
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <CircularProgress color="primary" />
                      <Typography
                        variant="body1"
                        sx={{
                          marginLeft: '1rem',
                          color: theme.palette.text.secondary,
                        }}
                      >
                        Carregando informações adicionais...
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default LicitacaoDetails;
