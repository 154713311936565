import ChipInput from '@components/ChipInput';
import CurrencyInput from '@components/CurrencyInput';
import MultiSelect from '@components/MultiSelect';
import { brazilianStates, ESFERAS, modalidadesMap, PORTAL_NAMES } from '@constants';
import CNAES from '@data/CNAE.json';
import { Clear, ExpandLess, ExpandMore, Search } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  IconButton,
  Paper,
  TextField,
  useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Api from '@services/api';
import { Esfera, LicitacoesSearchParams, Portal, UASG } from '@types';
import debounce from 'lodash/debounce';
import React, { KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react';

const cnaesList = CNAES.map((cnae) => ({
  label: cnae.cod + ' - ' + cnae.desc,
  value: cnae.cod,
}));

const esferaOptions = Object.entries(ESFERAS).map(([key, value]) => ({
  value: key,
  label: value,
}));

const modalidadesOptions = Object.entries(modalidadesMap).map(([value, { name }]) => ({
  value,
  label: name,
}));

const portalOptions = Object.entries(PORTAL_NAMES).map(([key, value]) => ({
  value: Number(key),
  label: value,
}));

const stateOptions = brazilianStates.map((state) => ({
  value: state.value,
  label: state.label,
}));

interface LicitacoesSearchBoxProps {
  handleSearch: (params: LicitacoesSearchParams) => void;
  clearAllFilters: () => void;
  isLoading: boolean;
  className?: string;
  initialValues?: Partial<LicitacoesSearchParams>;
}

const LicitacoesSearchBox: React.FC<LicitacoesSearchBoxProps> = ({
  handleSearch,
  clearAllFilters,
  isLoading,
  initialValues,
  className,
}) => {
  const theme = useTheme();

  const [isExpanded, setIsExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string[]>([]);
  const [uf, setUf] = useState<string[]>([]);
  const [selectedCity, setSelectedCity] = useState<string[]>([]);
  const [modalidade, setModalidade] = useState<string[]>([]);
  const [selectedPortal, setSelectedPortal] = useState<string[]>([]);
  const [selectedEsfera, setSelectedEsfera] = useState<string[]>([]);
  const [cities, setCities] = useState<{ label: string; value: string }[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [minValue, setMinValue] = useState('');
  const [maxValue, setMaxValue] = useState('');
  const [selectedCnaes, setSelectedCnaes] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [codigoUasg, setCodigoUasg] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [item, setItem] = useState('');
  const [matchExact, setMatchExact] = useState(false);
  const [edital, setEdital] = useState('');
  const [currentInputValue, setCurrentInputValue] = useState('');

  const [uasgOptions, setUasgOptions] = useState<{ label: string; value: string }[]>([]);

  useEffect(() => {
    if (initialValues) {
      setSearchQuery(
        Array.isArray(initialValues.palavra_chave)
          ? initialValues.palavra_chave
          : initialValues.palavra_chave
            ? [initialValues.palavra_chave]
            : [],
      );
      setUf(initialValues.uf || []);
      setSelectedCity(initialValues.municipio_ibge || []);
      setModalidade(initialValues.modalidade || []);
      setStartDate(
        initialValues.data_abertura_inicio ? new Date(initialValues.data_abertura_inicio) : null,
      );
      setEndDate(
        initialValues.data_abertura_fim ? new Date(initialValues.data_abertura_fim) : null,
      );
      setSelectedPortal(initialValues.id_portal?.map((id) => id.toString()) || []);
      setSelectedEsfera(initialValues.esfera?.map(String) || []);
      setMinValue(initialValues.valor_min || '');
      setMaxValue(initialValues.valor_max || '');
      setSelectedCnaes(
        initialValues.cnae && initialValues.cnae.length > 0
          ? initialValues.cnae.map((cnaeCode) => {
              const cnae = cnaesList.find((c) => c.value === cnaeCode);
              return cnae ? cnae : { label: cnaeCode, value: cnaeCode };
            })
          : [],
      );
      setCodigoUasg(
        initialValues.codigo_uasg && initialValues.codigo_uasg.length > 0
          ? { label: '', value: initialValues.codigo_uasg[0] }
          : null,
      );
      setItem(initialValues.item || '');
      setMatchExact(initialValues.match_exact === true);
      setEdital(initialValues.edital || '');

      // Fetch cities if a state is selected

      if (initialValues.uf && initialValues.uf.length > 0) {
        try {
          const citiesData = Api.getCitiesByStateUF(initialValues.uf[0]);
          setCities(
            citiesData.map((city) => ({
              label: city.nome_municipio,
              value: city.codigo_ibge.toString(),
            })),
          );
        } catch (citiesError) {
          console.error('Error fetching cities:', citiesError);
          setCities([]);
        }
      }
    }
  }, [initialValues]);

  const params = useMemo(
    () => ({
      palavra_chave: searchQuery,
      uf: uf,
      municipio_ibge: selectedCity,
      modalidade: modalidade,
      data_abertura_inicio: startDate?.toISOString().split('T')[0],
      data_abertura_fim: endDate?.toISOString().split('T')[0],
      id_portal: selectedPortal.map((id) => id as Portal),
      esfera: selectedEsfera.map((id) => id as Esfera),
      valor_min: minValue,
      valor_max: maxValue,
      cnae: selectedCnaes.map((cnae) => cnae.value),
      codigo_uasg: codigoUasg ? [codigoUasg.value] : [],
      item: item,
      match_exact: matchExact,
      edital: edital,
    }),
    [
      searchQuery,
      uf,
      selectedCity,
      modalidade,
      startDate,
      endDate,
      selectedPortal,
      selectedEsfera,
      minValue,
      maxValue,
      selectedCnaes,
      codigoUasg,
      item,
      matchExact,
      edital,
    ],
  );

  const handleStateChange = useCallback((value: string[]) => {
    const selectedStates = value as string[];
    setUf(selectedStates);
    setSelectedCity([]);
    if (selectedStates.length > 0) {
      try {
        const citiesData = selectedStates.flatMap((state) => Api.getCitiesByStateUF(state));
        setCities(
          citiesData.map((city) => ({
            label: city.nome_municipio,
            value: city.codigo_ibge.toString(),
          })),
        );
      } catch (citiesError) {
        console.error('Error fetching cities:', citiesError);
        setCities([]);
      }
    } else {
      setCities([]);
    }
  }, []);

  const handleClearAllFilters = () => {
    setSearchQuery([]);
    setUf([]);
    setSelectedCity([]);
    setModalidade([]);
    setStartDate(null);
    setEndDate(null);
    setCities([]);
    setSelectedPortal([]);
    setMinValue('');
    setMaxValue('');
    setSelectedCnaes([]);
    setCodigoUasg(null);
    setItem('');
    setMatchExact(false);
    setEdital('');
    clearAllFilters();
  };

  const handleSearchPress = () => {
    const updatedSearchQuery = [...searchQuery];
    if (currentInputValue.trim() && !updatedSearchQuery.includes(currentInputValue.trim())) {
      updatedSearchQuery.push(currentInputValue.trim());
    }
    setSearchQuery(updatedSearchQuery);
    handleSearch({
      ...params,
      palavra_chave: updatedSearchQuery,
    });
    setIsExpanded(false);
  };
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSearchPress();
    }
  };
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const activeFilters = useMemo(() => {
    const filters = [];
    if (searchQuery.length > 0)
      filters.push({
        key: 'palavra_chave',
        value: searchQuery,
        label: `Busca: ${searchQuery.join(', ')}`,
      });
    if (uf.length > 0)
      filters.push({
        key: 'uf',
        value: uf,
        label: `Estado: ${uf.map((u) => brazilianStates.find((state) => state.value === u)?.label).join(', ')}`,
      });
    if (selectedCity.length > 0)
      filters.push({
        key: 'municipio_ibge',
        value: selectedCity,
        label: `Cidade: ${selectedCity.map((c) => Api.getCitiesByMunicipioIBGE(parseInt(c))?.nome_municipio).join(', ')}`,
      });
    if (modalidade.length > 0)
      filters.push({
        key: 'modalidade',
        value: modalidade,
        label: `Modalidade: ${modalidade.map((m) => modalidadesMap[parseInt(m)]?.name).join(', ')}`,
      });
    if (startDate)
      filters.push({
        key: 'data_abertura_inicio',
        value: startDate.toISOString(),
        label: `Data Início: ${startDate.toLocaleDateString()}`,
      });
    if (endDate)
      filters.push({
        key: 'data_abertura_fim',
        value: endDate.toISOString(),
        label: `Data Fim: ${endDate.toLocaleDateString()}`,
      });
    if (selectedPortal.length > 0)
      filters.push({
        key: 'id_portal',
        value: selectedPortal,
        label: `Portal: ${selectedPortal.map((p) => PORTAL_NAMES[p as Portal]).join(', ')}`,
      });
    if (selectedEsfera.length > 0)
      filters.push({
        key: 'esfera',
        value: selectedEsfera,
        label: `Esfera: ${selectedEsfera.map((e) => ESFERAS[e as Esfera]).join(', ')}`,
      });
    if (minValue)
      filters.push({
        key: 'valor_min',
        value: minValue,
        label: `Valor Mín: ${minValue}`,
      });
    if (maxValue)
      filters.push({
        key: 'valor_max',
        value: maxValue,
        label: `Valor Máx: ${maxValue}`,
      });
    if (selectedCnaes.length > 0)
      filters.push({
        key: 'cnae',
        value: selectedCnaes.map((cnae) => cnae.value),
        label: `CNAE: ${selectedCnaes.map((cnae) => cnae.label).join(', ')}`,
      });
    if (codigoUasg)
      filters.push({
        key: 'codigo_uasg',
        value: codigoUasg?.value || '',
        label: `UASG: ${codigoUasg?.label}`,
      });
    if (item) filters.push({ key: 'item', value: item, label: `Item: ${item}` });
    if (matchExact)
      filters.push({
        key: 'match_exact',
        value: matchExact,
        label: 'Busca exata',
      });
    if (edital) filters.push({ key: 'edital', value: edital, label: `Edital: ${edital}` });
    return filters;
  }, [
    searchQuery,
    uf,
    selectedCity,
    modalidade,
    startDate,
    endDate,
    selectedPortal,
    minValue,
    maxValue,
    selectedCnaes,
    codigoUasg,
    item,
    matchExact,
    edital,
  ]);

  const handleRemoveFilter = (key: string) => {
    const updatedParams = { ...params };
    switch (key) {
      case 'palavra_chave':
        setSearchQuery([]);
        updatedParams.palavra_chave = [];
        break;
      case 'uf':
        setUf([]);
        updatedParams.uf = [];
        break;
      case 'municipio_ibge':
        setSelectedCity([]);
        updatedParams.municipio_ibge = [];
        break;
      case 'modalidade':
        setModalidade([]);
        updatedParams.modalidade = [];
        break;
      case 'data_abertura_inicio':
        setStartDate(null);
        updatedParams.data_abertura_inicio = undefined;
        break;
      case 'data_abertura_fim':
        setEndDate(null);
        updatedParams.data_abertura_fim = undefined;
        break;
      case 'id_portal':
        setSelectedPortal([]);
        updatedParams.id_portal = [];
        break;
      case 'esfera':
        setSelectedEsfera([]);
        updatedParams.esfera = [];
        break;
      case 'valor_min':
        setMinValue('');
        updatedParams.valor_min = '';
        break;
      case 'valor_max':
        setMaxValue('');
        updatedParams.valor_max = '';
        break;
      case 'cnae':
        setSelectedCnaes([]);
        updatedParams.cnae = [];
        break;
      case 'codigo_uasg':
        setCodigoUasg(null);
        updatedParams.codigo_uasg = [];
        break;
      case 'item':
        setItem('');
        updatedParams.item = '';
        break;
      case 'match_exact':
        setMatchExact(false);
        updatedParams.match_exact = false;
        break;
      case 'edital':
        setEdital('');
        updatedParams.edital = '';
        break;
    }
    handleSearch(updatedParams);
  };

  const searchUASG = useCallback(async (query: string) => {
    if (query.length >= 3) {
      const options: UASG[] = await Api.searchUASG(query);
      const uasgOptions = options.map((option) => ({
        label: `${option.id} - ${option.nome}`,
        value: option.id.toString(),
      }));
      setUasgOptions(uasgOptions);
    } else {
      setUasgOptions([]);
    }
  }, []);

  const debouncedSearchUASG = useMemo(() => debounce(searchUASG, 300), [searchUASG]);

  useEffect(() => {
    return () => {
      debouncedSearchUASG.cancel();
    };
  }, [debouncedSearchUASG]);

  const handleChipRemove = (removedChip: string) => {
    const updatedParams = { ...params };
    updatedParams.palavra_chave = searchQuery.filter((chip) => chip !== removedChip);
    handleSearch(updatedParams);
  };

  const handleMinValueChange = (value: string) => {
    setMinValue(value);
  };

  const handleMaxValueChange = (value: string) => {
    setMaxValue(value);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        mb: 2,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 1,
        boxShadow: theme.shadows[3],
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <ChipInput
            value={searchQuery}
            onChangeValue={setSearchQuery}
            onInputChange={setCurrentInputValue}
            onChipRemove={handleChipRemove}
            onEmptyEnter={() => handleSearchPress()}
            placeholder={
              isExpanded
                ? 'Digite aqui palavra(s) chave e pressione ENTER para adicionar'
                : 'Buscar licitações'
            }
            onFocus={() => setIsExpanded(true)}
            className={className}
            sx={{
              mr: 1,
              width: '100%',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: theme.palette.primary.main,
                },
                '&:hover fieldset': {
                  borderColor: theme.palette.primary.light,
                },
                '&.Mui-focused fieldset': {
                  borderColor: theme.palette.primary.dark,
                },
              },
            }}
          />
          {searchQuery.length > 0 && (
            <IconButton onClick={() => setSearchQuery([])} size="small">
              <Clear />
            </IconButton>
          )}
          <Button
            variant="contained"
            onClick={handleSearchPress}
            disabled={isLoading}
            sx={{
              ml: 1,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            {isLoading ? <CircularProgress size={24} /> : <Search />}
          </Button>
          <IconButton onClick={toggleExpand} size="small" sx={{ ml: 1 }}>
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
        {activeFilters.length > 0 && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1, width: '100%' }}>
            {activeFilters.map((filter) => (
              <Chip
                key={filter.key}
                label={filter.label}
                onDelete={() => handleRemoveFilter(filter.key)}
                sx={{ m: 0.5 }}
              />
            ))}
          </Box>
        )}
      </Box>
      <Collapse in={isExpanded} sx={{ width: '100%' }}>
        <Box sx={{ mt: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={matchExact}
                onChange={(e) => setMatchExact(e.target.checked)}
                color="primary"
              />
            }
            label="Busca exata"
          />
          <Box sx={{ display: 'flex', mb: 2 }}>
            <MultiSelect<string>
              label="Estado"
              options={stateOptions}
              value={uf}
              onChange={(value) => {
                setUf(value);
                handleStateChange(value);
              }}
              sx={{ mr: 1, flex: 1 }}
            />
            <MultiSelect<string>
              label="Cidade"
              options={cities}
              value={selectedCity}
              onChange={(value) => setSelectedCity(value)}
              disabled={uf.length === 0}
              sx={{ flex: 1 }}
            />
          </Box>

          <Box sx={{ display: 'flex', mb: 2 }}>
            <MultiSelect<string>
              label="Modalidades"
              options={modalidadesOptions}
              value={modalidade}
              onChange={(value) => setModalidade(value)}
              sx={{ mr: 1, flex: 1 }}
            />
            <MultiSelect<number>
              label="Portais"
              options={portalOptions}
              value={selectedPortal.map((v) => parseInt(v))}
              onChange={(value) => setSelectedPortal(value.map((v) => v.toString()))}
              sx={{ flex: 1 }}
            />
          </Box>

          <Box sx={{ display: 'flex', mb: 2 }}>
            <FormControl fullWidth sx={{ mr: 1 }}>
              <Autocomplete
                multiple
                options={cnaesList}
                value={selectedCnaes}
                onChange={(event, newValue) => setSelectedCnaes(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="CNAE"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: theme.palette.primary.main,
                        },
                        '&:hover fieldset': {
                          borderColor: theme.palette.primary.light,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: theme.palette.primary.dark,
                        },
                      },
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) => option.value === value.value}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mr: 1 }}>
              <Autocomplete
                options={uasgOptions}
                value={codigoUasg}
                onChange={(event, newValue) =>
                  setCodigoUasg(newValue as { label: string; value: string })
                }
                onInputChange={(event, newInputValue) => {
                  debouncedSearchUASG(newInputValue);
                }}
                noOptionsText="Digite o código ou nome da UASG"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Código UASG"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: theme.palette.primary.main,
                        },
                        '&:hover fieldset': {
                          borderColor: theme.palette.primary.light,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: theme.palette.primary.dark,
                        },
                      },
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) => option.value === value.value}
              />
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', mb: 2 }}>
            <DatePicker
              label="Data Inicial"
              value={startDate}
              onChange={(date: Date | null) => setStartDate(date)}
              slotProps={{
                textField: {
                  fullWidth: true,
                  sx: {
                    mr: 1,
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: theme.palette.primary.main,
                      },
                      '&:hover fieldset': {
                        borderColor: theme.palette.primary.light,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: theme.palette.primary.dark,
                      },
                    },
                  },
                },
              }}
            />
            <DatePicker
              label="Data Final"
              value={endDate}
              onChange={(date: Date | null) => setEndDate(date)}
              slotProps={{
                textField: {
                  fullWidth: true,
                  sx: {
                    ml: 1,
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: theme.palette.primary.main,
                      },
                      '&:hover fieldset': {
                        borderColor: theme.palette.primary.light,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: theme.palette.primary.dark,
                      },
                    },
                  },
                },
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', mb: 2 }}>
            <CurrencyInput
              label="Valor Mínimo"
              type="number"
              value={minValue}
              onChange={handleMinValueChange}
              onKeyDown={handleKeyDown}
              fullWidth
              sx={{
                mr: 1,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.primary.light,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: theme.palette.primary.dark,
                  },
                },
              }}
            />
            <CurrencyInput
              label="Valor Máximo"
              type="number"
              value={maxValue}
              onChange={handleMaxValueChange}
              onKeyDown={handleKeyDown}
              fullWidth
              sx={{
                ml: 1,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.primary.light,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: theme.palette.primary.dark,
                  },
                },
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', mb: 2 }}>
            <TextField
              label="Item dentro dos lotes das licitações"
              value={item}
              onChange={(e) => setItem(e.target.value)}
              onKeyDown={handleKeyDown}
              fullWidth
              sx={{
                mr: 1,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.primary.light,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: theme.palette.primary.dark,
                  },
                },
              }}
            />
            <TextField
              label="Número do Edital"
              value={edital}
              onChange={(e) => setEdital(e.target.value)}
              onKeyDown={handleKeyDown}
              fullWidth
              sx={{
                ml: 1,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.primary.light,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: theme.palette.primary.dark,
                  },
                },
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', mb: 2 }}>
            <MultiSelect<string>
              label="Esfera"
              options={esferaOptions}
              value={selectedEsfera}
              onChange={(value) => setSelectedEsfera(value)}
              sx={{ flex: 1 }}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="outlined"
              onClick={handleClearAllFilters}
              sx={{
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                  borderColor: theme.palette.primary.main,
                },
              }}
            >
              Limpar Filtros
            </Button>
            <Button
              variant="contained"
              onClick={handleSearchPress}
              disabled={isLoading}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
                '&:disabled': {
                  backgroundColor: theme.palette.action.disabledBackground,
                  color: theme.palette.action.disabled,
                },
              }}
            >
              Aplicar Filtros
            </Button>
          </Box>
        </Box>
      </Collapse>
    </Paper>
  );
};

export default LicitacoesSearchBox;
