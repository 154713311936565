import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, SxProps, Tooltip, useTheme } from '@mui/material';
import { useFollowingStore } from '@store/followingStore';
import React from 'react';

interface FollowingButtonProps {
  licitacaoId: string;
  isAlreadyFollowing?: boolean;
  className?: string;
  sx?: SxProps;
}

const FollowingButton: React.FC<FollowingButtonProps> = ({
  licitacaoId,
  isAlreadyFollowing,
  className,
  sx,
}) => {
  const theme = useTheme();
  const { isFollowing, addFollowing, removeFollowing } = useFollowingStore();
  const isFav = !isAlreadyFollowing ? isFollowing(licitacaoId) : true;

  const handleToggleFollowing = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (isFav) {
      removeFollowing(licitacaoId);
    } else {
      addFollowing(licitacaoId);
    }
  };

  return (
    <Tooltip
      title={isFav ? 'Remover dos acompanhamentos' : 'Adicionar aos acompanhamentos'}
      sx={sx || {}}
    >
      <IconButton onClick={handleToggleFollowing} size="small" className={className}>
        {isFav ? (
          <VisibilityIcon sx={{ color: theme.palette.success.main }} />
        ) : (
          <VisibilityOffIcon sx={{ color: theme.palette.text.secondary }} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default FollowingButton;
