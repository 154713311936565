import BulletinSearchParams from '@components/Bulletin/BulletinSearchParams';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useBulletinStore } from '@store/bulletinStore';
import { LicitacoesSearchParams } from '@types';
import React, { useEffect, useState } from 'react';

interface NewBulletinProps {
  open: boolean;
  onClose: () => void;
  onCreateBulletin: (name: string, searchParams: LicitacoesSearchParams) => Promise<void>;
  initialSearchParams?: LicitacoesSearchParams | null;
}

const NewBulletin: React.FC<NewBulletinProps> = ({
  open,
  onClose,
  onCreateBulletin,
  initialSearchParams,
}) => {
  const [name, setName] = useState('');
  const [searchParams, setSearchParams] = useState<LicitacoesSearchParams>({});
  const { isLoading } = useBulletinStore();

  useEffect(() => {
    if (initialSearchParams) {
      setSearchParams(initialSearchParams);
    }
  }, [initialSearchParams]);

  const handleCreate = async () => {
    await onCreateBulletin(name, searchParams);
    onClose();
    setName('');
    setSearchParams({});
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <DialogTitle>Criar Novo Boletim</DialogTitle>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <TextField
            autoFocus
            margin="dense"
            label="Nome do Boletim"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">Parâmetros de busca</Typography>
        </Box>
        <BulletinSearchParams onParamsChange={setSearchParams} initialValues={searchParams} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleCreate} variant="contained" color="primary" disabled={isLoading}>
          {isLoading ? 'Criando...' : 'Criar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewBulletin;
