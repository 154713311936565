import Header from '@components/Layout/Header';
import { Box, Container, Tab, Tabs, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const Settings: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    navigate(`/configuracoes/${newValue}`);
  };

  const value = location.pathname.split('/').pop() || 'preferencias';

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Container maxWidth="lg">
        <Header />
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom color="primary">
            Configurações
          </Typography>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="settings tabs">
              <Tab label="Dados da Empresa" value="empresa" />
              <Tab label="Preferências" value="preferencias" />
              <Tab label="Pagamento" value="pagamento" />
              <Tab label="Perfil do Usuário" value="perfil" />
            </Tabs>
          </Box>
          <Box sx={{ p: 3 }}>
            <Outlet />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Settings;
