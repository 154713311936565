import { Alert, Button, Snackbar } from '@mui/material';
import { useProfileStore } from '@store/profileStore';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const SubscriptionReminder: React.FC = () => {
  const { userData } = useProfileStore();
  const [open, setOpen] = React.useState(true);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  if (!userData || userData.subscriptionStatus !== 'past_due') {
    return null;
  }

  const message =
    'Seu pagamento está atrasado. Por favor, atualize seu método de pagamento para evitar a interrupção do serviço.';
  const link = '/configuracoes/pagamento';

  return (
    <Snackbar open={open} autoHideDuration={null} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity="warning"
        sx={{ width: '100%' }}
        action={
          <Button color="inherit" size="small" component={RouterLink} to={link}>
            Atualizar Pagamento
          </Button>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SubscriptionReminder;
