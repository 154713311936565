import { Global, css } from '@emotion/react';
import { Box, CssBaseline, PaletteMode } from '@mui/material';
import { ThemeOptions, ThemeProvider, createTheme, useColorScheme } from '@mui/material/styles';
import * as React from 'react';
import { dataDisplayCustomizations } from './customizations/dataDisplay';
import { feedbackCustomizations } from './customizations/feedback';
import { inputsCustomizations } from './customizations/inputs';
import { navigationCustomizations } from './customizations/navigation';
import { surfacesCustomizations } from './customizations/surfaces';
import { getGlobalStyles } from './globalStyles';
import { colorSchemes, shadows, shape, typography } from './themePrimitives';

interface AppThemeProps {
  children: React.ReactNode;
  disableCustomTheme?: boolean;
  themeComponents?: ThemeOptions['components'];
  mode?: PaletteMode | 'system' | undefined;
}

const getBackgroundStyles = (mode: 'light' | 'dark') => css`
  html,
  body,
  #root {
    min-height: 100vh;
    background: ${mode === 'dark'
      ? 'linear-gradient(135deg, #001E3C 0%, #0A1929 100%)'
      : '#FFFFFF'};
  }

  #root::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: ${mode === 'dark'
      ? 'radial-gradient(circle at 50% 50%, rgba(255,255,255,0.05) 0%, transparent 70%)'
      : 'none'};
    opacity: 0.6;
    pointer-events: none;
    z-index: -1;
  }
`;

function AppTheme({
  children,
  disableCustomTheme,
  themeComponents,
  mode = 'system',
}: AppThemeProps) {
  const { mode: systemMode } = useColorScheme();
  const effectiveMode = !mode || mode === 'system' ? systemMode : mode;

  const theme = React.useMemo(() => {
    if (disableCustomTheme) {
      return createTheme();
    }

    return createTheme({
      cssVariables: {
        colorSchemeSelector: 'data-mui-color-scheme',
        cssVarPrefix: 'template',
      },
      colorSchemes,
      palette: {
        mode: effectiveMode as PaletteMode,
        primary: {
          main: '#6C63FF', // Purple color from the website
          light: '#8B85FF',
          dark: '#5046E5',
          contrastText: '#FFFFFF',
        },
        background: {
          default: effectiveMode === 'dark' ? '#0A1929' : '#FFFFFF',
          paper: effectiveMode === 'dark' ? '#001E3C' : '#F5F8FA',
        },
        text: {
          primary: effectiveMode === 'dark' ? '#FFFFFF' : '#1A2027',
          secondary: effectiveMode === 'dark' ? '#B0BEC5' : '#637381',
        },
      },
      typography,
      shadows,
      shape,
      components: {
        ...inputsCustomizations,
        ...dataDisplayCustomizations,
        ...feedbackCustomizations,
        ...navigationCustomizations,
        ...surfacesCustomizations,
        ...themeComponents,
        MuiCssBaseline: {
          styleOverrides: (theme) => getGlobalStyles(theme),
        },
      },
    });
  }, [disableCustomTheme, themeComponents, effectiveMode]);

  const backgroundStyles = getBackgroundStyles(effectiveMode as 'light' | 'dark');

  if (disableCustomTheme) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <ThemeProvider theme={theme} disableTransitionOnChange>
      <CssBaseline />
      <Global styles={backgroundStyles} />
      <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>{children}</Box>
    </ThemeProvider>
  );
}

export default AppTheme;
