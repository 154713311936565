import { getModalidadeName } from '@constants';
import { WhatsApp } from '@mui/icons-material';
import { Box, useTheme } from '@mui/material';
import { Licitacao, MyLicitacoes } from '@types';
import React from 'react';

type CombinedLicitacao = Licitacao & MyLicitacoes;

interface WhatsAppButtonProps {
  licitacao: CombinedLicitacao;
}

const WhatsAppButton: React.FC<WhatsAppButtonProps> = ({ licitacao }) => {
  const theme = useTheme();

  const getWhatsAppShareLink = () => {
    const abertura = new Date(licitacao.abertura_datetime || new Date());
    const text = encodeURIComponent(
      `Confira esta licitação:\n\nTítulo: ${licitacao.titulo}\nÓrgão: ${licitacao.orgao}\nModalidade: ${licitacao.tipo || getModalidadeName(licitacao.id_tipo)}\nData de Abertura: ${abertura.toLocaleString()}\n\nMais detalhes: ${window.location.href}`,
    );
    return `https://api.whatsapp.com/send?text=${text}`;
  };

  return (
    <Box
      component="a"
      href={getWhatsAppShareLink()}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        cursor: 'pointer',
        userSelect: 'none',
        verticalAlign: 'middle',
        appearance: 'none',
        textDecoration: 'none',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: 1.75,
        minWidth: 64,
        padding: '6px 16px',
        borderRadius: '8px',
        transition: theme.transitions.create(
          ['background-color', 'box-shadow', 'border-color', 'color'],
          { duration: theme.transitions.duration.short },
        ),
        height: '2.5rem',
        color: '#FFFFFF',
        backgroundColor: '#25D366',
        backgroundImage: 'linear-gradient(rgba(37, 211, 102, 0.8), #128C7E)',
        boxShadow: 'inset 0 2px 0 rgba(255, 255, 255, 0.2), inset 0 -2px 0 rgba(0, 0, 0, 0.2)',
        border: '1px solid #128C7E',
        '&:hover': {
          backgroundColor: '#128C7E',
          boxShadow: theme.shadows[4],
        },
        '&:active': {
          boxShadow: 'inset 0 3px 5px rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      <WhatsApp sx={{ marginRight: 1 }} />
      Compartilhar via WhatsApp
    </Box>
  );
};

export default WhatsAppButton;
