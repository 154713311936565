import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Paper, Typography, useTheme } from '@mui/material';
import { KanbanColumn, KanbanLicitacao } from '@types';
import React, { useState } from 'react';
import AddLicitacaoModal from './AddLicitacaoModal';
import KanbanCard from './Card';
import { StrictModeDroppable } from './StrictModeDroppable';

interface ColumnProps {
  column: KanbanColumn;
}

const Column: React.FC<ColumnProps> = ({ column }) => {
  const theme = useTheme();
  const licitacoes: KanbanLicitacao[] = column.licitacoes || [];
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  return (
    <Box sx={{ minWidth: 320, width: 320, mx: 0.5 }}>
      <Paper
        elevation={3}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.background.default,
          borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 4px 4px`,
          overflow: 'hidden',
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            py: 1.5,
            px: 2,
            fontWeight: 'bold',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          {column.title}
        </Typography>
        <StrictModeDroppable droppableId={column.id}>
          {(provided) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{
                flexGrow: 1,
                minHeight: 100,
                p: 1,
                overflowY: 'auto',
                backgroundColor: theme.palette.background.default,
              }}
            >
              {licitacoes.map((licitacao, index) => (
                <KanbanCard
                  key={licitacao.id_licitacao}
                  licitacao={licitacao}
                  index={index}
                  columnId={column.id}
                />
              ))}
              {provided.placeholder}
            </Box>
          )}
        </StrictModeDroppable>
        <Button
          startIcon={<AddIcon />}
          onClick={() => setIsAddModalOpen(true)}
          sx={{
            m: 1,
            textTransform: 'none',
            color: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          }}
        >
          Adicionar Licitação
        </Button>
      </Paper>
      <AddLicitacaoModal
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        columnId={column.id}
      />
    </Box>
  );
};

export default React.memo(Column);
