import { getModalidadeInfo, getModalidadeName } from '@constants';
import Api from '@services/api';
import {
  Licitacao,
  LicitacoesAbertasResponse,
  LicitacoesSearchParams,
  ModalidadeInfo,
  MyLicitacoes,
  MyLicitacoesResponse,
} from '@types';
import debounce from 'lodash/debounce';
import { create } from 'zustand';
import { useFavoritesStore } from './favoritesStore';

interface LicitacoesState {
  licitacoes: Licitacao[];
  myLicitacoes: MyLicitacoes[];
  isLoading: boolean;
  error: string | null;
  hasMore: boolean;
  totalLicitacoes: number;
  fetchLicitacoes: (
    params: LicitacoesSearchParams,
    refresh?: boolean,
    licitacoesPorPagina?: number,
  ) => Promise<LicitacoesAbertasResponse | null>;
  fetchMyLicitacoes: () => Promise<MyLicitacoesResponse | null>;
  getModalidadeName: (id: number) => string;
  getModalidadeInfo: (id: number) => ModalidadeInfo;
  getLicitacaoById: (id: string) => Licitacao | MyLicitacoes | undefined;
  isFetchingMyLicitacoes: boolean;
  resetMyLicitacoesError: () => void;
  refresh: () => Promise<void>;
  currentParams: LicitacoesSearchParams | null;
  cities: { [key: string]: { label: string; value: string }[] };
  fetchCitiesByState: (state: string) => Promise<void>;
  fetchLicitacaoDetails: (id: string) => Promise<Licitacao | null>;
  isLoadingDetails: boolean;
  lastLoadedLicitacaoId: string | null;
  lastLoadedLicitacao: Licitacao | null;
  clearLastLoadedLicitacao: () => void;
  resetStore: () => void;
  lastSearchParams: LicitacoesSearchParams | null;
  debouncedFetchLicitacoes: (params: LicitacoesSearchParams, shouldRefresh?: boolean) => void;
  isFavorite: (licitacaoId: string) => boolean;
  toggleFavorite: (licitacaoId: string) => Promise<void>;
  getFavoriteAnnotation: (licitacaoId: string) => string;
  updateFavoriteAnnotation: (licitacaoId: string, annotation: string) => Promise<void>;
}

export const useLicitacoesStore = create<LicitacoesState>((set, get) => {
  let lastSearchRef = '';
  let lastSearchPages: { [key: string]: number } = {};

  const debouncedFetchLicitacoes = debounce(
    (params: LicitacoesSearchParams, shouldRefresh = false) => {
      const { pagina, licitacoesPorPagina, ...restParams } = params;
      const searchKeyWithoutPageAndCount = JSON.stringify(restParams);
      const currentPage = pagina || 1;

      const lastPageForThisSearch = lastSearchPages[searchKeyWithoutPageAndCount] || 0;

      if (
        searchKeyWithoutPageAndCount !== lastSearchRef ||
        currentPage > lastPageForThisSearch ||
        shouldRefresh
      ) {
        lastSearchRef = searchKeyWithoutPageAndCount;
        lastSearchPages[searchKeyWithoutPageAndCount] = currentPage;
        get().fetchLicitacoes(params, shouldRefresh, licitacoesPorPagina);
      } else {
        set({ isLoading: false });
      }
    },
    1000,
  );

  return {
    licitacoes: [],
    myLicitacoes: [],
    isLoading: false,
    error: null,
    hasMore: true,
    lastLoadedLicitacaoId: null,
    lastLoadedLicitacao: null,
    totalLicitacoes: 0,
    lastSearchParams: null,
    fetchLicitacoes: async (params, refresh = false, licitacoesPorPagina = 20) => {
      set({ isLoading: true, error: null });
      try {
        const fetchParams = { ...params, licitacoesPorPagina };
        const data = await Api.getLicitacoes(fetchParams);
        set((state) => ({
          licitacoes:
            fetchParams.pagina === 1 || refresh
              ? data.licitacoes
              : [...state.licitacoes, ...data.licitacoes],
          isLoading: false,
          hasMore: data.paginas > (fetchParams.pagina || 1),
          currentParams: fetchParams,
          totalLicitacoes: data.totalLicitacoes,
          lastSearchParams: fetchParams,
        }));
        return data;
      } catch (error) {
        console.error('Error fetching licitacoes:', error);
        set({
          isLoading: false,
          error: 'Erro ao carregar licitações',
          hasMore: false,
        });
        return null;
      }
    },
    debouncedFetchLicitacoes,
    fetchMyLicitacoes: async () => {
      const state = get();
      if (state.isFetchingMyLicitacoes) {
        return null;
      }

      set({ isLoading: true, error: null, isFetchingMyLicitacoes: true });
      try {
        const data = await Api.getMyLicitacoes();
        set({
          myLicitacoes: data.filteredLicitacoes,
          isLoading: false,
          isFetchingMyLicitacoes: false,
          error: null,
        });
        return data;
      } catch (error) {
        console.error('Error fetching my licitacoes:', error);
        set({
          isLoading: false,
          error: 'Erro ao carregar suas licitações. Por favor, tente novamente mais tarde.',
          myLicitacoes: [],
          isFetchingMyLicitacoes: false,
        });
        return null;
      }
    },
    getModalidadeName: (id: number) => {
      return getModalidadeName(id);
    },
    getModalidadeInfo: (id: number): ModalidadeInfo => {
      return getModalidadeInfo(id);
    },
    getLicitacaoById: (id: string) => {
      const { licitacoes, myLicitacoes } = get();

      const generalLicitacao = licitacoes.find((l) => l.id_licitacao === id);
      if (generalLicitacao) return generalLicitacao;

      const recommendedLicitacao = myLicitacoes.find(
        (l) => l.licitacaoId === id || l.id_licitacao === id,
      );
      if (recommendedLicitacao) return recommendedLicitacao;

      return undefined;
    },
    isFetchingMyLicitacoes: false,
    resetMyLicitacoesError: () => set({ error: null }),
    refresh: async () => {
      set({ isLoading: true, error: null });
      try {
        const currentParams = get().currentParams || {
          pagina: 1,
          licitacoesPorPagina: 20,
        };
        await get().fetchLicitacoes({ ...currentParams, pagina: 1 }, true);
      } catch (error) {
        console.error('Error refreshing licitacoes:', error);
        set({
          isLoading: false,
          error: 'Erro ao atualizar licitações',
          hasMore: false,
        });
      }
    },
    currentParams: null,
    cities: {},
    fetchCitiesByState: async (state: string) => {
      try {
        const citiesData = Api.getCitiesByStateUF(state);
        const formattedCities = citiesData.map((city) => ({
          label: city.nome_municipio,
          value: city.codigo_ibge,
        })) as any;
        set((prevState) => ({
          cities: {
            ...prevState.cities,
            [state]: formattedCities,
          },
        }));
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    },
    fetchLicitacaoDetails: async (id: string) => {
      const state = get();
      if (state.lastLoadedLicitacaoId === id && state.lastLoadedLicitacao) {
        return state.lastLoadedLicitacao;
      }

      set({ isLoadingDetails: true });
      try {
        const data = await Api.getLicitacaoDetails(id);
        set((state) => {
          const updatedLicitacoes = state.licitacoes.map((l) =>
            l.id_licitacao === id ? { ...l, ...data } : l,
          );
          const updatedMyLicitacoes = state.myLicitacoes.map((l) =>
            l.id_licitacao === id ? { ...l, ...data } : l,
          );
          return {
            licitacoes: updatedLicitacoes,
            myLicitacoes: updatedMyLicitacoes,
            isLoadingDetails: false,
            lastLoadedLicitacaoId: id,
            lastLoadedLicitacao: data,
          };
        });
        return data;
      } catch (error) {
        console.error('Error fetching licitacao details:', error);
        set({ isLoadingDetails: false });
        return null;
      }
    },
    isLoadingDetails: false,
    clearLastLoadedLicitacao: () => set({ lastLoadedLicitacaoId: null, lastLoadedLicitacao: null }),
    resetStore: () => {
      lastSearchRef = '';
      lastSearchPages = {};
      set({
        licitacoes: [],
        myLicitacoes: [],
        isLoading: false,
        error: null,
        hasMore: true,
        totalLicitacoes: 0,
        currentParams: null,
        cities: {},
        lastLoadedLicitacaoId: null,
        lastLoadedLicitacao: null,
      });
    },
    isFavorite: (licitacaoId: string) => {
      return useFavoritesStore.getState().isFavorite(licitacaoId);
    },

    toggleFavorite: async (licitacaoId: string) => {
      const favoritesStore = useFavoritesStore.getState();
      if (favoritesStore.isFavorite(licitacaoId)) {
        await favoritesStore.removeFavorite(licitacaoId);
      } else {
        await favoritesStore.addFavorite(licitacaoId);
      }
    },

    getFavoriteAnnotation: (licitacaoId: string) => {
      return useFavoritesStore.getState().getFavoriteAnnotation(licitacaoId);
    },

    updateFavoriteAnnotation: async (licitacaoId: string, annotation: string) => {
      await useFavoritesStore.getState().updateFavoriteAnnotation(licitacaoId, annotation);
    },
  };
});
