import ChipInput from '@components/ChipInput';
import MultiSelect from '@components/MultiSelect';
import { brazilianStates, ESFERAS, modalidadesMap, PORTAL_NAMES } from '@constants';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TextareaAutosize,
  Typography,
  useTheme,
} from '@mui/material';
import { useLicitacoesStore } from '@store/licitacoesStore';
import { useProfileStore } from '@store/profileStore';
import { Portal, UserPreferences } from '@types';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Preferences: React.FC = () => {
  const theme = useTheme();
  const { setUserPreferences, userPreferences: storedPreferences, company } = useProfileStore();
  const { fetchCitiesByState, cities } = useLicitacoesStore();
  const navigate = useNavigate();

  const isOnboarding = !storedPreferences;
  const [activeStep, setActiveStep] = useState(0);

  const steps = ['Localização', 'Modalidades e Palavras-chave', 'Portais e Resumo'];

  const [userPreferences, setLocalUserPreferences] = useState<UserPreferences>(() => {
    const defaultPreferences = {
      states: [],
      modalidades: [],
      keywords: [],
      cities: [],
      companySummary: '',
      portals: [],
      esferas: [],
    };

    if (storedPreferences) {
      return {
        ...defaultPreferences,
        ...storedPreferences,
        states: Array.isArray(storedPreferences.states) ? storedPreferences.states : [],
        modalidades: Array.isArray(storedPreferences.modalidades)
          ? storedPreferences.modalidades
          : [],
        keywords: Array.isArray(storedPreferences.keywords) ? storedPreferences.keywords : [],
        cities: Array.isArray(storedPreferences.cities) ? storedPreferences.cities : [],
        portals: Array.isArray(storedPreferences.portals) ? storedPreferences.portals : [],
        esferas: Array.isArray(storedPreferences.esferas) ? storedPreferences.esferas : [],
      };
    }

    // If it's onboarding, set initial preferences based on company info

    if (company && company.endereco) {
      return {
        ...defaultPreferences,
        states: [company.endereco.uf],
        cities: [company.endereco.municipio_IBGE.toString()],
        companySummary: `${company.razaoSocial} é uma empresa ${company.porte?.descricao || ''} que atua principalmente no setor de ${company.cnaePrincipal?.descricao || 'diversos setores'}.`,
      };
    }

    return defaultPreferences;
  });

  const [availableCities, setAvailableCities] = useState<{ label: string; value: string }[]>([]);
  const [companySummaryError, setCompanySummaryError] = useState('');

  useEffect(() => {
    const fetchCities = async () => {
      for (const state of userPreferences.states) {
        if (state !== 'ALL' && !cities[state]) {
          await fetchCitiesByState(state);
        }
      }
    };
    fetchCities();
  }, [userPreferences.states, fetchCitiesByState, cities]);

  useEffect(() => {
    const newAvailableCities = userPreferences.states.flatMap(
      (state) =>
        cities[state]?.map((city) => ({
          label: city.label,
          value: city.value.toString(),
        })) || [],
    );
    setAvailableCities(newAvailableCities);
  }, [userPreferences.states, cities]);

  const handlePreferenceChange = (key: keyof UserPreferences, value: any) => {
    setLocalUserPreferences((prev) => ({ ...prev, [key]: value }));
  };

  const handleKeywordsChange = (newKeywords: string[]) => {
    handlePreferenceChange('keywords', newKeywords);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleConfirmPreferences = async () => {
    if (userPreferences.companySummary.length < 20) {
      setCompanySummaryError('O resumo deve ter pelo menos 20 caracteres.');
      return;
    }

    await setUserPreferences(userPreferences);

    if (isOnboarding) {
      navigate('/licitacoes?onboarding=1');
    } else {
      navigate('/licitacoes');
    }
  };

  const handleSkip = () => {
    navigate('/licitacoes?onboarding=1');
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <>
            <Grid item xs={12}>
              <MultiSelect<string>
                label="Estados de interesse (Deixe em branco para todos os estados)"
                options={brazilianStates.map((state) => ({
                  value: state.value,
                  label: state.label,
                }))}
                value={userPreferences.states}
                onChange={(value) => handlePreferenceChange('states', value)}
              />
            </Grid>
            <Grid item xs={12}>
              <MultiSelect<string>
                label="Cidades de interesse (Deixe em branco para todas as cidades)"
                options={availableCities}
                value={userPreferences.cities}
                onChange={(value) => handlePreferenceChange('cities', value)}
                disabled={userPreferences.states.length === 0}
              />
            </Grid>
            <Grid item xs={12}>
              <MultiSelect<string>
                label="Esferas de interesse (Deixe em branco para todas as esferas)"
                options={Object.entries(ESFERAS).map(([key, value]) => ({
                  value: key,
                  label: value,
                }))}
                value={userPreferences.esferas}
                onChange={(value) => handlePreferenceChange('esferas', value)}
              />
            </Grid>
          </>
        );
      case 1:
        return (
          <>
            <Grid item xs={12}>
              <MultiSelect<string>
                label="Modalidades de interesse (Deixe em branco para todas as modalidades)"
                options={Object.entries(modalidadesMap).map(([value, { name }]) => ({
                  value,
                  label: name,
                }))}
                value={userPreferences.modalidades}
                onChange={(value) => handlePreferenceChange('modalidades', value)}
              />
            </Grid>
            <Grid item xs={12}>
              <ChipInput
                value={userPreferences.keywords}
                onChangeValue={handleKeywordsChange}
                placeholder="Digite uma palavra-chave e pressione Enter"
              />
            </Grid>
          </>
        );
      case 2:
        return (
          <>
            <Grid item xs={12}>
              <MultiSelect<string>
                label="Portais de interesse (Deixe em branco para todos os portais)"
                options={Object.entries(PORTAL_NAMES).map(([key, value]) => ({
                  value: key.toString(),
                  label: value,
                }))}
                value={userPreferences.portals}
                onChange={(value) => handlePreferenceChange('portals', value)}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={PORTAL_NAMES[value as Portal]} />
                    ))}
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                minRows={4}
                value={userPreferences.companySummary}
                onChange={(e) => handlePreferenceChange('companySummary', e.target.value)}
                placeholder="Descreva que tipo de licitações sua empresa está interessada ou faça um resumo sobre sua empresa"
                maxLength={500}
                style={{
                  width: '100%',
                  padding: '10px',
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  borderColor: theme.palette.primary.main,
                  borderRadius: '4px',
                  fontFamily: 'inherit',
                  fontSize: 'inherit',
                }}
              />
              {companySummaryError && (
                <Typography color="error" variant="caption">
                  {companySummaryError}
                </Typography>
              )}
              <Typography variant="caption" color="textSecondary">
                {`${userPreferences.companySummary.length}/500 caracteres`}
              </Typography>
            </Grid>
          </>
        );
      default:
        return null;
    }
  };

  const renderPreferences = () => {
    if (isOnboarding) {
      return renderStepContent(activeStep);
    } else {
      return (
        <>
          {renderStepContent(0)}
          {renderStepContent(1)}
          {renderStepContent(2)}
        </>
      );
    }
  };

  return (
    <Box sx={{ my: 4 }}>
      {isOnboarding && (
        <Paper
          elevation={3}
          sx={{
            p: 3,
            mb: 4,
            backgroundColor: theme.palette.background.paper,
            borderRadius: 2,
          }}
        >
          <Box display="flex" alignItems="center" mb={2}>
            <InfoIcon color="info" sx={{ mr: 2, fontSize: 40 }} />
            <Typography variant="h6" color="info.main">
              Defina suas preferências
            </Typography>
          </Box>
          <Typography color="text.secondary" paragraph>
            Suas preferências serão utilizadas por nossa inteligência artificial para recomendar
            licitações personalizadas para sua empresa.
          </Typography>
          <Typography color="text.secondary">
            Quanto mais detalhadas forem suas informações, melhores serão as recomendações. Continue
            atualizando suas preferências para obter os melhores resultados.
          </Typography>
        </Paper>
      )}

      <Typography variant="h4" component="h1" gutterBottom color="primary">
        {isOnboarding ? 'Configuração de Preferências' : 'Preferências das licitações'}
      </Typography>

      {isOnboarding && (
        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}

      <Card sx={{ backgroundColor: theme.palette.background.paper }}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            {renderPreferences()}
          </Grid>

          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
            {isOnboarding ? (
              <>
                <Button variant="outlined" onClick={handleBack} disabled={activeStep === 0}>
                  Voltar
                </Button>
                <Button
                  variant="contained"
                  onClick={activeStep === steps.length - 1 ? handleConfirmPreferences : handleNext}
                  disabled={
                    activeStep === steps.length - 1 && userPreferences.companySummary.length < 20
                  }
                >
                  {activeStep === steps.length - 1 ? 'Concluir' : 'Próximo'}
                </Button>
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Pular
                </Button>
              </>
            ) : (
              <>
                <Button variant="outlined" onClick={() => navigate('/configuracoes/empresa')}>
                  Voltar
                </Button>
                <Button
                  variant="contained"
                  onClick={handleConfirmPreferences}
                  disabled={userPreferences.companySummary.length < 20}
                >
                  Salvar Preferências
                </Button>
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Preferences;
