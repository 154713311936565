import { AttachMoney, OpenInNew } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { isFuture, parseISO } from 'date-fns';
import React from 'react';
import { ComprasnetLicitacao, Licitacao } from '../../types';
import { getComprasNetId } from '../../utils';

interface ComprasnetDetailsProps {
  details: ComprasnetLicitacao;
  licitacao: Licitacao;
}

const ComprasnetDetails: React.FC<ComprasnetDetailsProps> = ({ details, licitacao }) => {
  let comprasNetId = null;
  let portalLink = null;
  let portalLinkCadastroProposta = null;
  const isPastAbertura = React.useMemo(() => {
    if (!licitacao.abertura_datetime) return false;
    const aberturaDate = parseISO(licitacao.abertura_datetime);
    return isFuture(aberturaDate);
  }, [licitacao.abertura_datetime]);

  if (details.uasg && details.numero) {
    comprasNetId = getComprasNetId(licitacao);

    if (comprasNetId) {
      portalLink = `https://cnetmobile.estaleiro.serpro.gov.br/comprasnet-web/public/landing?destino=acompanhamento-compra&compra=${comprasNetId}`;
      if (!isPastAbertura) {
        portalLinkCadastroProposta = `https://cnetmobile.estaleiro.serpro.gov.br/comprasnet-web/seguro/fornecedor/cadastro-propostas?compra=${comprasNetId}`;
      } else {
        // portalLinkCadastroProposta = `https://cnetmobile.estaleiro.serpro.gov.br/comprasnet-web/seguro/fornecedor/acompanhamento-compra?compra=${comprasNetId}`;
        portalLinkCadastroProposta = `https://cnetmobile.estaleiro.serpro.gov.br/comprasnet-web/seguro/fornecedor/compras`;
      }
    }
  }
  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" color="primary">
            Informações adicionais do portal
          </Typography>

          {portalLink && (
            <Button
              href={portalLink}
              target="_blank"
              rel="noopener noreferrer"
              variant="contained"
              color="secondary"
              startIcon={<OpenInNew />}
            >
              Acompanhar no portal
            </Button>
          )}
          {portalLinkCadastroProposta && (
            <Button
              href={portalLinkCadastroProposta}
              target="_blank"
              rel="noopener noreferrer"
              variant="contained"
              color="primary"
              startIcon={<AttachMoney />}
            >
              {isPastAbertura ? 'Acompanhar proposta' : 'Cadastrar Proposta'}
            </Button>
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography>
              <b>Órgão:</b> {details.orgao}
            </Typography>
            <Typography>
              <b>UASG:</b> {details.uasg}
            </Typography>
            <Typography>
              <b>Modalidade:</b> {details.modalidade}
            </Typography>
            <Typography>
              <b>Edital:</b> {details.numero}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              <b>Data do Edital:</b> {details.dataEditalAPartirDe}
            </Typography>
            <Typography>
              <b>Telefone:</b> {details.telefone || 'Não informado'}
            </Typography>
            <Typography>
              <b>Fax:</b> {details.fax || 'Não informado'}
            </Typography>
            <Typography>
              <b>Entrega da Proposta:</b> {details.entregaProposta}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography>
              <b>Endereço:</b> {details.endereco}
            </Typography>
          </Grid>
          {details.objeto && (
            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Typography>
                <b>Objeto:</b> {details.objeto}
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ComprasnetDetails;
