import AddLicitacaoModal from '@components/Kanban/AddLicitacaoModal';
import Board from '@components/Kanban/Board';
import Header from '@components/Layout/Header';
import { DEFAULT_BOARD_NAME } from '@constants';
import { Box, Button, Container, Typography, useTheme } from '@mui/material';
import { useKanbanStore } from '@store/kanbanStore';
import React, { useCallback, useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';

const KanbanBoard: React.FC = () => {
  const theme = useTheme();
  const { board, fetchBoard, moveKanbanLicitacao } = useKanbanStore();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  useEffect(() => {
    fetchBoard();
  }, [fetchBoard]);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;

      const { source, destination, draggableId } = result;
      if (source.droppableId !== destination.droppableId || source.index !== destination.index) {
        moveKanbanLicitacao(draggableId, destination.droppableId, destination.index);
      }
    },
    [moveKanbanLicitacao],
  );

  const handleOpenAddModal = useCallback(() => {
    setIsAddModalOpen(true);
  }, []);

  const handleCloseAddModal = useCallback(() => {
    setIsAddModalOpen(false);
  }, []);

  if (!board || !board.columns) return null;

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Container maxWidth="lg">
        <Header />
        <Box my={4}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h4" component="h1" color="primary">
              {board.title || DEFAULT_BOARD_NAME}
            </Typography>
            <Button variant="contained" color="primary" onClick={handleOpenAddModal}>
              Adicionar Licitação
            </Button>
          </Box>
          <Board columns={board.columns} onDragEnd={onDragEnd} />
        </Box>
      </Container>
      <AddLicitacaoModal
        open={isAddModalOpen}
        onClose={handleCloseAddModal}
        columnId={board.columns ? board.columns[0].id : 'fi'}
      />
    </Box>
  );
};

export default KanbanBoard;
