import { DEFAULT_BOARD_ID, DEFAULT_BOARD_NAME, FIXED_KANBAN_COLUMNS } from '@constants';
import Api from '@services/api';
import { KanbanBoard, KanbanColumn, KanbanLicitacao } from '@types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface KanbanState {
  board: KanbanBoard;
  isLoading: boolean;
  error: string | null;
  fetchBoard: () => Promise<void>;
  addKanbanLicitacao: (
    columnId: string,
    licitacaoId: string,
    extraData: Partial<KanbanLicitacao>,
  ) => Promise<void>;
  updateKanbanLicitacao: (licitacaoId: string, updates: Partial<KanbanLicitacao>) => Promise<void>;
  removeKanbanLicitacao: (licitacaoId: string) => Promise<void>;
  moveKanbanLicitacao: (
    licitacaoId: string,
    newColumnId: string,
    newPosition: number,
  ) => Promise<void>;
  columns: KanbanColumn[];
  setColumns: (columns: KanbanColumn[]) => void;
  resetStore: () => void;
}

export const useKanbanStore = create<KanbanState>()(
  persist(
    (set) => ({
      board: {
        id: DEFAULT_BOARD_ID,
        title: DEFAULT_BOARD_NAME,
        columns: FIXED_KANBAN_COLUMNS,
      },
      isLoading: false,
      error: null,

      fetchBoard: async () => {
        set({ isLoading: true, error: null });
        try {
          const boardData = await Api.getKanbanBoard();
          set({ board: boardData, isLoading: false });
        } catch (error) {
          console.error(error);
          set({ error: 'Failed to fetch board', isLoading: false });
        }
      },

      addKanbanLicitacao: async (
        columnId: string,
        licitacaoId: string,
        extraData: Partial<KanbanLicitacao>,
      ) => {
        try {
          const updatedBoard = await Api.addKanbanLicitacao(columnId, licitacaoId, extraData);
          set({ board: updatedBoard });
        } catch (error) {
          console.error(error);
          set({ error: 'Failed to add licitacao to kanban on server' });
        }
      },

      updateKanbanLicitacao: async (licitacaoId: string, updates: Partial<KanbanLicitacao>) => {
        try {
          const updatedBoard = await Api.updateKanbanLicitacao(licitacaoId, updates);
          set({ board: updatedBoard });
        } catch (error) {
          console.error(error);
          set({ error: 'Failed to update licitacao in kanban on server' });
        }
      },

      removeKanbanLicitacao: async (licitacaoId: string) => {
        try {
          const updatedBoard = await Api.removeKanbanLicitacao(licitacaoId);
          set({ board: updatedBoard });
        } catch (error) {
          console.error(error);
          set({ error: 'Failed to remove licitacao from kanban on server' });
        }
      },

      moveKanbanLicitacao: async (
        licitacaoId: string,
        newColumnId: string,
        newPosition: number,
      ) => {
        set((state) => {
          const updatedColumns = state.board.columns.map((column) => {
            if (column.licitacoes.some((l) => l.id_licitacao === licitacaoId)) {
              // Remove from old column
              return {
                ...column,
                licitacoes: column.licitacoes.filter((l) => l.id_licitacao !== licitacaoId),
                licitacoesIds: column.licitacoesIds.filter((id) => id !== licitacaoId),
              };
            }
            if (column.id === newColumnId) {
              // Add to new column
              const movedLicitacao = state.board.columns
                .flatMap((c) => c.licitacoes)
                .find((l) => l.id_licitacao === licitacaoId);
              if (movedLicitacao) {
                const newLicitacoes = [...column.licitacoes];
                newLicitacoes.splice(newPosition, 0, movedLicitacao);
                return {
                  ...column,
                  licitacoes: newLicitacoes,
                  licitacoesIds: newLicitacoes.map((l) => l.id_licitacao),
                };
              }
            }
            return column;
          });

          return {
            board: {
              ...state.board,
              columns: updatedColumns,
            },
          };
        });

        try {
          const updatedBoard = await Api.moveKanbanLicitacao(licitacaoId, newColumnId, newPosition);
          set({ board: updatedBoard });
        } catch (error) {
          console.error(error);
          set({ error: 'Failed to move licitacao in kanban on server' });
        }
      },

      columns: [],
      setColumns: (columns) => set({ columns }),

      resetStore: () =>
        set({
          board: {
            id: DEFAULT_BOARD_ID,
            title: DEFAULT_BOARD_NAME,
            columns: FIXED_KANBAN_COLUMNS,
          },
          isLoading: false,
          error: null,
          columns: [],
        }),
    }),
    {
      name: 'kanban-storage',
      partialize: (state) => ({ board: state.board }),
    },
  ),
);
