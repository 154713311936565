import Api from '@services/api';
import { Favorite, Licitacao } from '@types';
import { create } from 'zustand';

interface FavoritesState {
  favorites: Favorite[];
  favoriteLicitacoes: Licitacao[];
  isLoading: boolean;
  error: string | null;
  fetchFavorites: () => Promise<void>;
  fetchFavoritesLicitacoes: () => Promise<void>;
  addFavorite: (licitacaoId: string) => Promise<void>;
  removeFavorite: (licitacaoId: string) => Promise<void>;
  isFavorite: (licitacaoId: string) => boolean;
  updateFavoriteAnnotation: (licitacaoId: string, annotation: string) => Promise<void>;
  getFavoriteAnnotation: (licitacaoId: string) => string;
  resetStore: () => void;
}

export const useFavoritesStore = create<FavoritesState>((set, get) => ({
  favorites: [],
  favoriteLicitacoes: [],
  isLoading: false,
  error: null,

  fetchFavorites: async () => {
    set({ isLoading: true, error: null });
    try {
      const favorites = await Api.getFavorites();
      set({ favorites, isLoading: false });
    } catch (error) {
      console.error('Error fetching favorites:', error);
      set({ error: 'Erro ao buscar favoritos', isLoading: false });
    }
  },

  fetchFavoritesLicitacoes: async () => {
    set({ isLoading: true, error: null });
    try {
      const favoriteLicitacoes = await Api.getFavoritesLicitacoes();
      set({ favoriteLicitacoes, isLoading: false });
    } catch (error) {
      console.error('Error fetching favorite licitacoes:', error);
      set({ error: 'Erro ao buscar licitações favoritas', isLoading: false });
    }
  },

  addFavorite: async (licitacaoId: string) => {
    try {
      const newFavorite = await Api.addFavorite(licitacaoId);
      set((state) => ({
        favorites: [...state.favorites, newFavorite],
      }));
      await get().fetchFavoritesLicitacoes();
    } catch (error) {
      console.error('Error adding favorite:', error);
      set({ error: 'Erro ao adicionar favorito' });
    }
  },

  removeFavorite: async (licitacaoId: string) => {
    try {
      await Api.removeFavorite(licitacaoId);
      set((state) => ({
        favorites: state.favorites.filter((fav) => fav.licitacaoId !== licitacaoId),
        favoriteLicitacoes: state.favoriteLicitacoes.filter((l) => l.id_licitacao !== licitacaoId),
      }));
    } catch (error) {
      console.error('Error removing favorite:', error);
      set({ error: 'Erro ao remover favorito' });
    }
  },

  isFavorite: (licitacaoId: string) => {
    return get().favorites.some((fav) => fav.licitacaoId === licitacaoId);
  },

  updateFavoriteAnnotation: async (licitacaoId: string, annotation: string) => {
    try {
      await Api.updateFavoriteAnnotation(licitacaoId, annotation);
      set((state) => ({
        favorites: state.favorites.map((fav) =>
          fav.licitacaoId === licitacaoId ? { ...fav, annotation } : fav,
        ),
        favoriteLicitacoes: state.favoriteLicitacoes.map((licitacao) =>
          licitacao.id_licitacao === licitacaoId ? { ...licitacao, annotation } : licitacao,
        ),
      }));
    } catch (error) {
      console.error('Error updating annotation for favorite:', error);
      set({ error: 'Erro ao atualizar anotação do favorito' });
    }
  },

  getFavoriteAnnotation: (licitacaoId: string) => {
    const favorite = get().favorites.find((fav) => fav.licitacaoId === licitacaoId);
    return favorite ? favorite.annotation || '' : '';
  },

  resetStore: () =>
    set({
      favorites: [],
      favoriteLicitacoes: [],
      isLoading: false,
      error: null,
    }),
}));
