import Header from '@components/Layout/Header';
import LicitacaoCard from '@components/Licitacao/LicitacaoCard';
import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { useLicitacoesStore } from '@store/licitacoesStore';
import { useProfileStore } from '@store/profileStore';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const RecommendedLicitacoes: React.FC = () => {
  const theme = useTheme();
  const { myLicitacoes, fetchMyLicitacoes, isLoading, error, isFetchingMyLicitacoes } =
    useLicitacoesStore();
  const { company, userPreferences } = useProfileStore();
  const [refreshing, setRefreshing] = useState(false);
  const isInitialMount = useRef(true);

  const isCompanyInfoComplete = company && company.cnaePrincipal && company.cnaePrincipal.id;
  const isPreferencesComplete = userPreferences && userPreferences.companySummary;
  const isOnboardingComplete = isCompanyInfoComplete && isPreferencesComplete;

  const loadData = useCallback(async () => {
    if (
      !isLoading &&
      !isFetchingMyLicitacoes &&
      myLicitacoes.length === 0 &&
      !error &&
      isOnboardingComplete
    ) {
      await fetchMyLicitacoes();
    }
  }, [
    isLoading,
    isFetchingMyLicitacoes,
    myLicitacoes.length,
    error,
    fetchMyLicitacoes,
    isOnboardingComplete,
  ]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      if (myLicitacoes.length === 0 && isOnboardingComplete) {
        loadData();
      }
    }
  }, [loadData, myLicitacoes.length, isOnboardingComplete]);

  const handleRefresh = useCallback(() => {
    setRefreshing(true);
    fetchMyLicitacoes().finally(() => setRefreshing(false));
  }, [fetchMyLicitacoes]);

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Container maxWidth="lg">
        <Header />
        <Box display="flex" justifyContent="space-between" alignItems="center" my={4}>
          <Typography variant="h4" component="h1" color="primary">
            Licitações Recomendadas
          </Typography>
          <IconButton
            onClick={handleRefresh}
            disabled={isLoading || refreshing || !isOnboardingComplete}
            sx={{
              color: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            <RefreshIcon />
          </IconButton>
        </Box>
        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
        <Grid container spacing={2}>
          {myLicitacoes.map((item, index) => (
            <LicitacaoCard
              key={item.id_licitacao}
              item={item}
              isFirst={index === 0}
              searchKeywords={[]}
            />
          ))}
        </Grid>
        {(isLoading || refreshing) && (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress color="primary" />
          </Box>
        )}
        {!isLoading && !error && (myLicitacoes.length === 0 || !isOnboardingComplete) && (
          <Paper
            elevation={3}
            sx={{
              p: 3,
              mt: 4,
              backgroundColor: theme.palette.background.paper,
              borderRadius: 2,
            }}
          >
            <Box display="flex" alignItems="center" mb={2}>
              <InfoIcon color="info" sx={{ mr: 2, fontSize: 40 }} />
              <Typography variant="h6" color="info.main">
                {isOnboardingComplete
                  ? 'Ainda não temos recomendações para você'
                  : 'Precisamos de mais informações para recomendar licitações'}
              </Typography>
            </Box>
            {isOnboardingComplete ? (
              <>
                <Typography color="text.secondary" paragraph>
                  Não se preocupe! Nosso sistema está trabalhando para encontrar as melhores
                  licitações para o seu perfil.
                </Typography>
                <Typography color="text.secondary">
                  Com o tempo, aprenderemos mais sobre suas preferências e começaremos a fornecer
                  recomendações personalizadas. Continue usando o sistema e atualizando suas
                  preferências para obter os melhores resultados.
                </Typography>
              </>
            ) : (
              <>
                <Typography color="text.secondary" paragraph>
                  Para podermos recomendar licitações relevantes para você, precisamos que você
                  complete seu perfil e preferências.
                </Typography>
                <Button
                  component={RouterLink}
                  to="/configuracoes/empresa"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                >
                  Completar Perfil
                </Button>
              </>
            )}
          </Paper>
        )}
      </Container>
    </Box>
  );
};

export default RecommendedLicitacoes;
