import { Box, Chip, FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import React, { useState } from 'react';

interface Option {
  value: string | number;
  label: string;
}

interface MultiSelectProps<T extends Option['value']>
  extends Omit<SelectProps, 'onChange' | 'renderValue'> {
  options: Option[];
  value: T[];
  onChange: (value: T[]) => void;
  label: string;
  renderValue?: (selected: T[], options: Option[]) => React.ReactNode;
  allOptionValue?: string; // Changed to optional string
}

function MultiSelect<T extends Option['value']>({
  options,
  value,
  onChange,
  label,
  renderValue,
  allOptionValue = '', // Default to empty string
  ...selectProps
}: MultiSelectProps<T>) {
  const [open, setOpen] = useState(false);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const selectedValue = event.target.value as T[];

    let newValue: T[];
    if (allOptionValue !== '') {
      if (selectedValue.includes(allOptionValue as T)) {
        newValue = [allOptionValue as T];
      } else if (value.includes(allOptionValue as T) && selectedValue.length > 0) {
        newValue = selectedValue.filter((v) => v !== allOptionValue);
      } else {
        newValue = selectedValue;
      }
    } else {
      newValue = selectedValue;
    }

    onChange(newValue);
    setOpen(false);
  };

  const defaultRenderValue = (selected: T[], options: Option[]) => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {selected.map((value) => (
        <Chip
          key={value}
          label={options.find((option) => option.value === value)?.label || value}
        />
      ))}
    </Box>
  );

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={value}
        onChange={handleChange}
        renderValue={(selected) =>
          renderValue
            ? renderValue(selected as T[], options)
            : defaultRenderValue(selected as T[], options)
        }
        {...selectProps}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MultiSelect;
