import ChipInput from '@components/ChipInput';
import MultiSelect from '@components/MultiSelect';
import { brazilianStates, ESFERAS, modalidadesMap, PORTAL_NAMES } from '@constants';
import CNAES from '@data/CNAE.json';
import { Autocomplete, Box, FormControl, TextField, useTheme } from '@mui/material';
import Api from '@services/api';
import { Esfera, LicitacoesSearchParams, Portal } from '@types';
import React, { useEffect, useState } from 'react';

const cnaesList = CNAES.map((cnae) => ({
  label: cnae.cod + ' - ' + cnae.desc,
  value: cnae.cod,
}));

const modalidadesOptions = Object.entries(modalidadesMap).map(([value, { name }]) => ({
  value,
  label: name,
}));

const portalOptions = Object.entries(PORTAL_NAMES).map(([key, value]) => ({
  value: Number(key),
  label: value,
}));

const stateOptions = brazilianStates.map((state) => ({
  value: state.value,
  label: state.label,
}));

const esferaOptions = Object.entries(ESFERAS).map(([key, value]) => ({
  value: key,
  label: value,
}));

interface BulletinSearchParamsProps {
  onParamsChange: (params: LicitacoesSearchParams) => void;
  initialValues?: LicitacoesSearchParams;
}

const BulletinSearchParams: React.FC<BulletinSearchParamsProps> = ({
  onParamsChange,
  initialValues = {},
}) => {
  const theme = useTheme();

  const [uf, setUf] = useState<string[]>(initialValues.uf || []);
  const [selectedCity, setSelectedCity] = useState<string[]>(initialValues.municipio_ibge || []);
  const [modalidade, setModalidade] = useState<string[]>(initialValues.modalidade || []);
  const [selectedPortal, setSelectedPortal] = useState<string[]>(
    initialValues.id_portal?.map(String) || [],
  );
  const [cities, setCities] = useState<{ label: string; value: string }[]>([]);
  const [minValue, setMinValue] = useState(initialValues.valor_min || '');
  const [maxValue, setMaxValue] = useState(initialValues.valor_max || '');
  const [selectedCnaes, setSelectedCnaes] = useState<{ label: string; value: string }[]>(
    initialValues.cnae?.map(
      (code) => cnaesList.find((c) => c.value === code) || { label: code, value: code },
    ) || [],
  );
  const [codigoUasg, setCodigoUasg] = useState<{ label: string; value: string } | null>(
    initialValues.codigo_uasg ? { label: '', value: initialValues.codigo_uasg[0] } : null,
  );
  const [item, setItem] = useState(initialValues.item || '');
  const [edital, setEdital] = useState(initialValues.edital || '');
  const [keywords, setKeywords] = useState<string[]>(
    Array.isArray(initialValues.palavra_chave)
      ? initialValues.palavra_chave
      : initialValues.palavra_chave
        ? [initialValues.palavra_chave]
        : [],
  );
  const [currentKeywordInput, setCurrentKeywordInput] = useState('');
  const [selectedEsfera, setSelectedEsfera] = useState<string[]>(
    initialValues.esfera?.map(String) || [],
  );

  useEffect(() => {
    const params: LicitacoesSearchParams = {
      uf,
      municipio_ibge: selectedCity,
      modalidade,
      id_portal: selectedPortal.map(String) as Portal[],
      valor_min: minValue ? String(minValue) : undefined,
      valor_max: maxValue ? String(maxValue) : undefined,
      cnae: selectedCnaes.map((cnae) => cnae.value),
      codigo_uasg: codigoUasg ? [codigoUasg.value] : undefined,
      item,
      edital,
      palavra_chave: [...keywords, currentKeywordInput].filter(Boolean),
      esfera: selectedEsfera.map(String) as Esfera[],
    };
    onParamsChange(params);
  }, [
    uf,
    selectedCity,
    modalidade,
    selectedPortal,
    minValue,
    maxValue,
    selectedCnaes,
    codigoUasg,
    item,
    edital,
    keywords,
    currentKeywordInput,
    selectedEsfera,
    onParamsChange,
  ]);

  const handleStateChange = async (selectedStates: string[]) => {
    if (selectedStates.length === 1) {
      const citiesData = await Api.getCitiesByStateUF(selectedStates[0]);
      setCities(citiesData.map((city: any) => ({ label: city.nome, value: city.id })));
    } else {
      setCities([]);
      setSelectedCity([]);
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <MultiSelect<string>
          label="Estado"
          options={stateOptions}
          value={uf}
          onChange={(value) => {
            setUf(value);
            handleStateChange(value);
          }}
          sx={{ mr: 1, flex: 1 }}
        />
        <MultiSelect<string>
          label="Cidade"
          options={cities}
          value={selectedCity}
          onChange={(value) => setSelectedCity(value)}
          disabled={uf.length === 0}
          sx={{ flex: 1 }}
        />
      </Box>

      <Box sx={{ display: 'flex', mb: 2 }}>
        <MultiSelect<string>
          label="Modalidades"
          options={modalidadesOptions}
          value={modalidade}
          onChange={(value) => setModalidade(value)}
          sx={{ mr: 1, flex: 1 }}
        />
        <MultiSelect<number>
          label="Portais"
          options={portalOptions}
          value={selectedPortal.map(Number)}
          onChange={(value) => setSelectedPortal(value.map(String))}
          sx={{ flex: 1 }}
        />
      </Box>

      <Box sx={{ display: 'flex', mb: 2 }}>
        <FormControl fullWidth sx={{ mr: 1 }}>
          <Autocomplete
            multiple
            options={cnaesList}
            value={selectedCnaes}
            onChange={(event, newValue) => setSelectedCnaes(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="CNAE"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: theme.palette.primary.main,
                    },
                    '&:hover fieldset': {
                      borderColor: theme.palette.primary.light,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: theme.palette.primary.dark,
                    },
                  },
                }}
              />
            )}
            isOptionEqualToValue={(option, value) => option.value === value.value}
          />
        </FormControl>
        <FormControl fullWidth sx={{ ml: 1 }}>
          <Autocomplete
            options={[]}
            value={codigoUasg}
            onChange={(event, newValue) => setCodigoUasg(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Código UASG"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: theme.palette.primary.main,
                    },
                    '&:hover fieldset': {
                      borderColor: theme.palette.primary.light,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: theme.palette.primary.dark,
                    },
                  },
                }}
              />
            )}
            isOptionEqualToValue={(option, value) => option.value === value.value}
          />
        </FormControl>
      </Box>

      <Box sx={{ display: 'flex', mb: 2 }}>
        <TextField
          label="Valor Mínimo"
          value={minValue}
          onChange={(e) => setMinValue(e.target.value)}
          type="number"
          fullWidth
          sx={{
            mr: 1,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: theme.palette.primary.main,
              },
              '&:hover fieldset': {
                borderColor: theme.palette.primary.light,
              },
              '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.dark,
              },
            },
          }}
        />
        <TextField
          label="Valor Máximo"
          value={maxValue}
          onChange={(e) => setMaxValue(e.target.value)}
          type="number"
          fullWidth
          sx={{
            ml: 1,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: theme.palette.primary.main,
              },
              '&:hover fieldset': {
                borderColor: theme.palette.primary.light,
              },
              '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.dark,
              },
            },
          }}
        />
      </Box>

      <Box sx={{ display: 'flex', mb: 2 }}>
        <TextField
          label="Item dentro dos lotes das licitações"
          value={item}
          onChange={(e) => setItem(e.target.value)}
          fullWidth
          sx={{
            mr: 1,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: theme.palette.primary.main,
              },
              '&:hover fieldset': {
                borderColor: theme.palette.primary.light,
              },
              '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.dark,
              },
            },
          }}
        />
        <TextField
          label="Número do Edital"
          value={edital}
          onChange={(e) => setEdital(e.target.value)}
          fullWidth
          sx={{
            ml: 1,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: theme.palette.primary.main,
              },
              '&:hover fieldset': {
                borderColor: theme.palette.primary.light,
              },
              '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.dark,
              },
            },
          }}
        />
      </Box>

      <Box sx={{ display: 'flex', mb: 2 }}>
        <ChipInput
          value={keywords}
          onChangeValue={setKeywords}
          onInputChange={setCurrentKeywordInput}
          placeholder="Digite palavras-chave e pressione Enter"
          sx={{
            flex: 1,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: theme.palette.primary.main,
              },
              '&:hover fieldset': {
                borderColor: theme.palette.primary.light,
              },
              '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.dark,
              },
            },
          }}
        />
      </Box>

      <Box sx={{ display: 'flex', mb: 2 }}>
        <MultiSelect<string>
          label="Esfera"
          options={esferaOptions}
          value={selectedEsfera}
          onChange={(value) => setSelectedEsfera(value)}
          sx={{ flex: 1 }}
        />
      </Box>
    </Box>
  );
};

export default BulletinSearchParams;
