import { Box } from '@mui/material';
import { KanbanColumn } from '@types';
import React from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import Column from './Column';

interface BoardProps {
  columns: KanbanColumn[];
  onDragEnd: (result: DropResult) => void;
}

const Board: React.FC<BoardProps> = ({ columns, onDragEnd }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box
        sx={{
          display: 'flex',
          overflowX: 'auto',
          minHeight: 'calc(100vh - 200px)',
          py: 2,
          gap: 2,
        }}
      >
        {columns.map((column) => (
          <Column key={column.id} column={column} />
        ))}
      </Box>
    </DragDropContext>
  );
};

export default React.memo(Board);
