import PageContainer from '@components/PageContainer';
import { useAuth } from '@contexts/AuthContext';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { GoogleIcon } from '@theme/Icons';
import { FirebaseError } from 'firebase/app';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const { signIn, signInWithGoogle, error } = useAuth();
  const navigate = useNavigate();

  const validateInputs = () => {
    let isValid = true;

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      setEmailErrorMessage('Por favor, insira um endereço de e-mail válido.');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!password || password.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage('A senha deve ter pelo menos 6 caracteres.');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    return isValid;
  };

  useEffect(() => {
    if (error instanceof FirebaseError) {
      handleFirebaseError(error);
    } else if (error instanceof Error) {
      setEmailError(true);
      setPasswordError(true);
      setEmailErrorMessage('Ocorreu um erro. Tente novamente.');
      setPasswordErrorMessage('Ocorreu um erro. Tente novamente.');
    }
  }, [error]);

  const handleFirebaseError = (firebaseError: FirebaseError) => {
    switch (firebaseError.code) {
      case 'auth/invalid-credential':
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        setEmailError(true);
        setPasswordError(true);
        setEmailErrorMessage('E-mail ou senha inválidos.');
        setPasswordErrorMessage('E-mail ou senha inválidos.');
        break;
      case 'auth/too-many-requests':
        setEmailError(true);
        setPasswordError(true);
        setEmailErrorMessage('Muitas tentativas. Tente novamente mais tarde.');
        setPasswordErrorMessage('Muitas tentativas. Tente novamente mais tarde.');
        break;
      case 'auth/user-disabled':
        setEmailError(true);
        setEmailErrorMessage('Esta conta foi desativada.');
        break;
      case 'auth/popup-closed-by-user':
        // This is specific to Google sign-in
        console.log('Login cancelado pelo usuário.');
        break;
      case 'auth/cancelled-popup-request':
        // This is specific to Google sign-in
        console.log('Requisição de popup cancelada.');
        break;
      default:
        setEmailError(true);
        setPasswordError(true);
        setEmailErrorMessage('Ocorreu um erro. Tente novamente.');
        setPasswordErrorMessage('Ocorreu um erro. Tente novamente.');
    }
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateInputs()) {
      try {
        await signIn(email, password);
        navigate('/licitacoes');
      } catch (error) {
        console.error('Login falhou:', error);
        if (error instanceof FirebaseError) {
          handleFirebaseError(error);
        } else if (error instanceof Error) {
          setEmailError(true);
          setPasswordError(true);
          setEmailErrorMessage('Ocorreu um erro. Tente novamente.');
          setPasswordErrorMessage('Ocorreu um erro. Tente novamente.');
        }
      }
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      navigate('/licitacoes');
    } catch (catchError) {
      console.error('Login com Google falhou:', catchError);
      if (catchError instanceof FirebaseError) {
        handleFirebaseError(catchError);
      } else if (catchError instanceof Error) {
        setEmailError(true);
        setPasswordError(true);
        setEmailErrorMessage('Ocorreu um erro. Tente novamente.');
        setPasswordErrorMessage('Ocorreu um erro. Tente novamente.');
      }
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <PageContainer>
        <Typography
          component="h1"
          variant="h4"
          sx={{
            width: '100%',
            fontSize: 'clamp(2rem, 10vw, 2.15rem)',
            textAlign: 'center',
          }}
        >
          Entrar em Licitou
        </Typography>
        <Box
          component="form"
          onSubmit={handleLogin}
          noValidate
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: 2,
          }}
        >
          <FormControl>
            <FormLabel htmlFor="email">E-mail</FormLabel>
            <TextField
              error={emailError}
              id="email"
              type="email"
              name="email"
              placeholder="seu@email.com"
              autoComplete="email"
              autoFocus
              required
              fullWidth
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password">Senha</FormLabel>
            <TextField
              error={passwordError}
              helperText={passwordErrorMessage || emailErrorMessage}
              name="password"
              placeholder="••••••"
              type="password"
              id="password"
              autoComplete="current-password"
              required
              fullWidth
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Lembrar-me"
          />
          {emailErrorMessage ||
            (passwordErrorMessage && (
              <Typography color="error" align="center">
                {emailErrorMessage || passwordErrorMessage}
              </Typography>
            ))}
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 2 }}>
            Entrar
          </Button>
          <Typography sx={{ textAlign: 'center' }}>
            Não tem uma conta?{' '}
            <Link href="https://licitou.com.br/assine" variant="body2">
              Cadastre-se
            </Link>
          </Typography>
        </Box>
        <Divider sx={{ my: 2 }}>ou</Divider>
        <Button
          fullWidth
          variant="outlined"
          onClick={handleGoogleSignIn}
          startIcon={<GoogleIcon />}
        >
          Entrar com Google
        </Button>
      </PageContainer>
    </React.Fragment>
  );
};

export default Login;
