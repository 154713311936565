import { SvgIconComponent } from '@mui/icons-material';
import { Theme as MuiTheme } from '@mui/material/styles';
import { NavigateFunction, Params } from 'react-router-dom';

// Route Param Lists

export type RootRouteParams = {
  Login: undefined;
  CompanyInfo: undefined;
  Preferences: undefined;
  Licitacoes: undefined;
  MyLicitacoes: undefined;
  LicitacaoDetails: { id_licitacao: string };
};

// Page Props

export type LoginPageProps = {
  navigate: NavigateFunction;
};

export type CompanyInfoPageProps = {
  navigate: NavigateFunction;
};

export type PreferencesPageProps = {
  navigate: NavigateFunction;
};

export type LicitacoesPageProps = {
  navigate: NavigateFunction;
};

export type MyLicitacoesPageProps = {
  navigate: NavigateFunction;
};

export type LicitacaoDetailsPageProps = {
  navigate: NavigateFunction;
  params: Params<string>;
};

export interface UserProfile {
  id: string;
  name: string;
  companyInfo?: Company | null;
  userPreferences?: UserPreferences | null;
  userId: string;
}

export interface UASG {
  id: number;
  nome: string;
  id_orgao: number;
  id_orgao_superior: number;
  id_municipio: number;
  cnpj: string;
  cep: string;
  total_fornecedores_cadastrados: number;
  total_fornecedores_recadastrados: number;
  unidade_cadastradora: boolean;
  ativo: boolean;
}
export interface CNAE {
  cod: string;
  desc: string;
}

export interface LicitacoesSearchParams {
  uf?: string[]; // Array of length 2 strings, uppercase
  palavra_chave?: string[]; // Max length 100
  pagina?: number; // Integer, min 1
  licitacoesPorPagina?: number; // Integer, min 1, max 100
  municipio_ibge?: string[]; // Array of length 7 strings
  data_abertura_inicio?: string; // ISO date string
  data_abertura_fim?: string; // ISO date string
  id_portal?: Portal[]; // Array of Portal enum values
  esfera?: Esfera[];
  valor_min?: string;
  valor_max?: string;
  modalidade?: string[]; // Array of modalidade strings
  cnae?: string[]; // Array of CNAE ID strings
  item?: string;
  codigo_uasg?: string[]; // Array of UASG code strings
  match_exact?: boolean;
  edital?: string;
}

export interface LicitacaoDocument {
  nome: string;
  tipo: string;
  dataHora: string;
  downloadUrl?: string;
  fileDescription?: string;
  createdAt?: string;
  url?: string;
  tipoDownLoad?: number;
  tituloDocumento?: string;
  parametros?: any;
}

export interface LicitacaoItem {
  numeroItem: number;
  descricao: string;
  materialOuServico: string;
  materialOuServicoNome: string;
  valorUnitarioEstimado: number;
  valorTotal: number;
  quantidade: number;
  unidadeMedida: string;
  orcamentoSigiloso: boolean;
  itemCategoriaId: number;
  itemCategoriaNome: string;
}

export interface Licitacao {
  id_licitacao: string;
  titulo: string;
  municipio_IBGE: string;
  uf: string;
  orgao: string;
  abertura_datetime: string | null;
  fechamento_datetime: string | null;
  publicacao_datetime: string | null;
  objeto: string;
  link: string;
  linkExterno: string;
  municipio: string;
  id_tipo: number;
  tipo: string;
  valor: number;
  id_portal: Portal;
  esfera: Esfera;
  portal: string;
  created_at: string;
  updated_at: string;
  id_cnae: string;
  cnae: string;
  item: string;
  emailContato: string;
  telefoneContato: string;
  numero_edital: string;
  linkEdital: string;
  codigo_uasg: string;
  portal_data: any;
  status: string;
  documents: LicitacaoDocument[];
  items: LicitacaoItem[];
}

export interface MyLicitacoes extends Licitacao {
  licitacaoId: string;
  userId: string;
  explicacao: string;
  annotation: string;
}

export interface LicitacoesAbertasResponse {
  totalLicitacoes: number;
  paginas: number;
  licitacoesPorPagina: number;
  licitacoesNestaPagina: number;
  totalErros: number;
  erros?: Array<{
    codigo: string;
    descricao: string;
  }>;
  licitacoes: Licitacao[];
}

export interface MyLicitacoesResponse {
  erros?: Array<{
    codigo: string;
    descricao: string;
  }>;
  message: string;
  filteredLicitacoes: MyLicitacoes[];
}

export interface CompanyCNAE {
  id: string;
  subclasse: string;
  descricao: string;
}

export interface Company {
  cnpj: string;
  razaoSocial: string;
  nomeFantasia: string;
  capitalSocial: string;
  porte: {
    id: string;
    descricao: string;
  };
  endereco: {
    cep: string;
    tipoLogradouro: string;
    logradouro: string;
    numero: string;
    complemento: string;
    bairro: string;
    municipio: string;
    municipio_IBGE: number;
    uf: string;
    pais: string;
  };
  contato: {
    ddd: string;
    telefone: string;
    email: string;
  };
  cnaePrincipal: CompanyCNAE;
  cnaesSecundarias: CompanyCNAE[];
  situacaoCadastral: string;
  dataInicioAtividade: string;
  tipo: string; // Matriz ou Filial
  simples: boolean | null;
}

export interface City {
  codigo_ibge: number;
  nome_municipio: string;
  codigo_uf: number;
  uf: string;
  estado: string;
}

export interface UserPreferences {
  states: string[];
  cities: string[];
  modalidades: string[];
  keywords: string[];
  companySummary: string;
  portals: Portal[];
  esferas: Esfera[];
}

// Add this interface to define the structure of modalidade info

export interface ModalidadeInfo {
  name: string;
  icon: SvgIconComponent;
  color: string;
  description: string;
}
export enum Portal {
  ComprasGovernamentais = '1',
  LicitacoesEBancoDoBrasil = '2',
  BECSP = '3',
  PortalNacionalDeComprasPublicas = '4',
  PRODESP = '5',
  BLLCompras = '6',
  BNCCompras = '7',
  BBMNET = '8',
  PortalDeComprasPublicas = '9',
  LicitaNET = '10',
  ComprasBR = '11',
  LicitarDigital = '12',
  Banrisul = '13',
  DOU = '14',
  DemaisPortais = '9999',
}

export enum Esfera {
  Federal = '1',
  Estadual = '2',
  MunicipalDistrital = '3',
  AdministracaoIndireta = '4',
  NaoClassificado = '0',
}

export interface KanbanLicitacao extends Licitacao {
  columnId: string;
  position: number;
  bidValue?: number;
  importance?: 'low' | 'medium' | 'high';
  notes?: string;
}
export interface KanbanColumn {
  id: string;
  title: string;
  position: number;
  licitacoesIds: string[];
  licitacoes: KanbanLicitacao[];
}
export interface KanbanBoard {
  id: string;
  title: string;
  columns: KanbanColumn[];
}
export interface Favorite {
  id: string;
  licitacaoId: string;
  userId: string;
  createdAt: string;
  annotation: string;
}
export interface Following {
  id: string;
  licitacaoId: string;
  userId: string;
  createdAt: string;
  annotation: string;
}

export interface CustomTheme extends MuiTheme {}

export interface CurrentPlan {
  name: string;
  id: string;
  status: string;
  cancelAtPeriodEnd: boolean;
  currentPeriodEnd: string;
}

export interface PaymentMethod {
  id: string;
  brand: string;
  last4: string;
  expMonth: number;
  expYear: number;
}
export interface PaymentInfo {
  currentPlans: CurrentPlan[];
  nextBillingDates: string[];
  paymentMethods: PaymentMethod[];
  paymentHistory: Array<{
    date: string;
    amount: number;
    status: string;
  }>;
}

export type ColorScheme = 'light' | 'dark';

export interface LicitanetNotice {
  identifier: number;
  datUpload: string;
  name: string;
  link: string;
}

export interface LicitanetLicitacao {
  identifier: number;
  status: string;
  description: string;
  datStartSession: string;
  datFinishSession: string | null;
  datPublication: string;
  datClarificationRequestLimit: string;
  allowedClarificationRequest: boolean;
  linkClarify: string;
  linkImpeachment: string;
  document: number;
  buyer: string;
  phone: number;
  email: string;
  city: string;
  uf: string;
  crier: string;
  documentCrier: number;
  numBatches: number;
  year: number;
  number: number;
  biddingProcess: string;
  isSuspended: boolean;
  isCanceled: boolean;
  typeBid: number;
  decree: number;
  typeModel: number;
  typeModelText: string;
  disputeMode: number;
  disputeModeText: string;
  isRevoked: boolean;
  isPriceRecord: boolean;
  isCovid: boolean;
  regionalCities: any[]; // You might want to define a more specific type if needed
  regionalBenefit: number;
  regionalBenefitCeiling: number;
  isExclusiveRegionalBenefit: boolean;
  visitorDisputeRoom: string;
  acquisition: string;
  isAutomaticDispute: boolean;
  notices: LicitanetNotice[];
  files: any[]; // You might want to define a more specific type if needed
  requestsClarification: any[]; // You might want to define a more specific type if needed
  challengeRequests: any[]; // You might want to define a more specific type if needed
  biddingLaw: number;
}

export interface ProdespEvento {
  evento: string;
  dataPublicacao: string;
}

export interface ProdespLicitacao {
  minutaContratoEdital: string;
  downloadEditalUrl: string;
  organizacao: string;
  uo: string;
  ugo: string;
  uge: string;
  orgao: string;
  modalidade: string;
  numero: string;
  processo: string;
  publicadoEm: string;
  localExecucao: string;
  abertura: string;
  area: string;
  subarea: string;
  registroPreco: string;
  objetoLicitacao: string;
  eventos: ProdespEvento[];
}

export interface BbmnesLicitacao {
  promotor: string;
  nEdital: string;
  nProcAdm: string;
  modalidade: string;
  fase: string;
  condutor: string;
  autoridade: string;
  tipoContrato: string;
  publicacao: string;
  inicioRecProposta: string;
  fimRecProposta: string;
  inicioDisputa: string;
  fimImpugnacao: string;
  fimEsclarecimentos: string;
  recebRecursos: string;
  recebContraRazao: string;
  manifRecursos: string;
  regulamento: string;
  validade: string;
  prazoPagto: string;
  tipoDeLance: string;
  taxaAdm: string;
  modoDeDisputa: string;
  tempoInicial: string;
  tempoFinal: string;
  anoReferencia: string;
  exclusivoME: string;
  exclusivoRegional: string;
  exclusivoLocal: string;
  mensagens: string;
  cadastroReserva: string;
  inversaoDeFases: string;
  intervaloDeLancesEm: string;
  valorTotalDoProcesso: string;
  fonePromotor: string;
  emailPromotor: string;
  objeto: string;
  observacao: string;
}
export interface BllBncFile {
  nome: string;
  dataHora: string;
  downloadUrl: string;
}

export interface BllBncItem {
  numero: string;
  especificacao: string;
  unidade: string;
  quantidade: number;
  valorReferencia: number;
  infoRequerida: boolean;
  arquivoRequerido: boolean;
}

export interface BllBncLote {
  numero: string;
  id: string;
  itens: BllBncItem[];
}

export interface BllBncLotes {
  lotes: BllBncLote[];
}
export interface BllBncLicitacao {
  promotor: string;
  nEdital: string;
  nProcAdm: string;
  modalidade: string;
  fase: string;
  condutor: string;
  autoridade: string;
  tipoContrato: string;
  publicacao: string;
  inicioRecProposta: string;
  fimRecProposta: string;
  inicioDisputa: string;
  fimImpugnacao: string;
  fimEsclarecimentos: string;
  recebRecursos: string;
  recebContraRazao: string;
  manifRecursos: string;
  regulamento: string;
  validade: string;
  prazoPagto: string;
  tipoDeLance: string;
  taxaAdm: string;
  modoDeDisputa: string;
  tempoInicial: string;
  tempoFinal: string;
  anoReferencia: string;
  exclusivoME: string;
  exclusivoRegional: string;
  exclusivoLocal: string;
  mensagens: string;
  cadastroReserva: string;
  inversaoDeFases: string;
  intervaloDeLancesEm: string;
  valorTotalDoProcesso: string;
  fonePromotor: string;
  emailPromotor: string;
  objeto: string;
  observacao: string;
  files: BllBncFile[];
  lotes: BllBncLote[];
  processId: string;
}
export interface ComprasbrDocumento {
  id: number;
  dataCadastro: string;
  dataAlteracao: string | null;
  usuarioCadastro: string;
  usuarioAlteracao: string | null;
  tipo: string;
  arquivoNome: string;
  arquivoUri: string;
  uriVirtualizado: string | null;
  situacao: string;
  relatorioNome: string | null;
  externo: boolean;
  sequencialCompraPublica: string;
  deveSerVirtualizado: boolean | null;
  idVirtualizado: string | null;
  virtualizadoJuntado: boolean | null;
  virtualizadoCancelado: boolean | null;
  origem: string | null;
  dataHoraVirtualizacao: string | null;
  faseVirtualizacao: string | null;
  tipoDocumentoDesentranhado: string | null;
  idDocumentoDesentranhado: string | null;
  conteudoBase64: string | null;
  aceitoPNCP: boolean | null;
}

export interface ComprasbrLote {
  id: number;
  codigo: string;
  descricao: string;
  fase: string;
  situacao: string;
  melhorLance: string;
}
export interface ComprasbrLicitacao {
  id: number;
  dataAbertura: string;
  dataIniEnvioProposta: string;
  dataFimEnvioProposta: string;
  numProcesso: string;
  numeroEdital: string;
  tipoLicitacao: string;
  tipoDisputa: string;
  status: string;
  modalidade: string;
  objeto: string;
  fase: string;
  pregoeiro: string;
  orgao: {
    id: number;
    nome: string;
    sigla: string;
    logradouro: string;
    numeroLogradouro: string;
    bairro: string;
    municipio: {
      idMunicipio: number;
      descricao: string;
      uf: string;
      idMunicipioIntegracao: string | null;
      codigoIbge: string;
    };
    deveVincularPncp: boolean;
  };
  modoDisputa: string;
  documentos: ComprasbrDocumento[];
  legislacao: string;
  dataLimiteEsclarecimentoImpugnacao: string;
  esclarecimentoImpugnacao: boolean;
  inversaoFases: boolean;
  sequencialCompraPublica: string;
  anoCompra: number;
  amparoLegal: {
    id: number;
    lei: string;
    codigo: string;
    situacao: string;
    ordem: number;
    pncp: boolean;
  };
  dataPublicacao: string;
  pncp: boolean;
  valorUnitario: boolean;
  valorTotal: boolean;
  lotes: ComprasbrLote[];
}
export interface ComprasbrDispensaAnexo {
  id: number;
  documentoId: number;
  criadoEm: string;
  nome: string;
  uri: string;
}

export interface ComprasbrDispensaItem {
  id: number;
  sequencia: number;
  descricao: string;
  situacao: string;
}

export interface ComprasbrDispensaInfo {
  id: number;
  processo: string;
  comprador: string;
  criadoPor: string;
  objeto: string;
  numero: number;
  ano: number;
  tipo: string;
  dataHoraAbertura: string;
  dataHoraEncerramento: string;
  dataHoraPublicacao: string;
  dataHoraInicioEnvioPropostas: string;
  dataHoraFimEnvioPropostas: string;
  disputaPor: string;
  situacao: string;
  municipio: string;
  estado: string;
  anexos: ComprasbrDispensaAnexo[];
  lotes: any[];
  itens: ComprasbrDispensaItem[];
}

export interface FirestoreUser {
  email: string;
  phoneNumber: string;
  isActive: boolean;
  name: string;
  displayName: string;
  photoURL: string;
  uid: string;
  createdAt: {
    seconds: number;
    nanoseconds: number;
  };
  updatedAt: {
    seconds: number;
    nanoseconds: number;
  };
  companyInfo: Company;
  userPreferences: UserPreferences;
  stripeCustomerId: string | null;
  onboardingComplete: boolean;
  subscriptionStatus: string;
  gracePeriodEnd: number | null;
  hasHadActivePlan: boolean;
  role: string;
  profiles: UserProfile[];
}

export interface ComprasbrDispensaLicitacao extends ComprasbrLicitacao {
  id: number;
  processo: string;
  comprador: string;
  criadoPor: string;
  numero: number;
  ano: number;
  tipo: string;
  dataHoraEncerramento: string;
  dataHoraPublicacao: string;
  disputaPor: string;
  municipio: string;
  estado: string;
  itens: ComprasbrDispensaItem[];
}
export interface PncpItem {
  numeroItem: number;
  descricao: string;
  materialOuServico: string;
  materialOuServicoNome: string;
  valorUnitarioEstimado: number;
  valorTotal: number;
  quantidade: number;
  unidadeMedida: string;
  orcamentoSigiloso: boolean;
  itemCategoriaId: number;
  itemCategoriaNome: string;
  patrimonio: string | null;
  codigoRegistroImobiliario: string | null;
  criterioJulgamentoId: number;
  criterioJulgamentoNome: string;
  situacaoCompraItem: number;
  situacaoCompraItemNome: string;
  tipoBeneficio: number;
  tipoBeneficioNome: string;
  incentivoProdutivoBasico: boolean;
  dataInclusao: string;
  dataAtualizacao: string;
  temResultado: boolean;
  imagem: number;
  aplicabilidadeMargemPreferenciaNormal: boolean;
  aplicabilidadeMargemPreferenciaAdicional: boolean;
  percentualMargemPreferenciaNormal: number | null;
  percentualMargemPreferenciaAdicional: number | null;
  ncmNbsCodigo: string | null;
  ncmNbsDescricao: string | null;
}

export interface PncpHistorico {
  justificativa: string | null;
  tipoLogManutencao: number;
  categoriaLogManutencao: number;
  logManutencaoDataInclusao: string;
  compraOrgaoCnpj: string;
  compraAno: number;
  compraSequencial: number;
  itemNumero: number | null;
  itemResultadoNumero: number | null;
  itemResultadoSequencial: number | null;
  documentoTipo: string | null;
  documentoTitulo: string | null;
  documentoSequencial: number | null;
  tipoLogManutencaoNome: string;
  categoriaLogManutencaoNome: string;
  usuarioNome: string;
}

export interface PncpArquivo {
  uri: string;
  url: string;
  sequencialDocumento: number;
  dataPublicacaoPncp: string;
  cnpj: string;
  anoCompra: number;
  sequencialCompra: number;
  statusAtivo: boolean;
  titulo: string;
  tipoDocumentoId: number;
  tipoDocumentoDescricao: string;
  tipoDocumentoNome: string;
}

export interface PncpLicitacao {
  valorTotalEstimado: number;
  valorTotalHomologado: number | null;
  orcamentoSigilosoCodigo: number;
  orcamentoSigilosoDescricao: string;
  numeroControlePNCP: string;
  linkSistemaOrigem: string;
  linkProcessoEletronico: string | null;
  anoCompra: number;
  sequencialCompra: number;
  numeroCompra: string;
  processo: string;
  orgaoEntidade: {
    cnpj: string;
    razaoSocial: string;
    esferaId: string;
    poderId: string;
  };
  unidadeOrgao: {
    codigoUnidade: string;
    nomeUnidade: string;
    municipioNome: string;
    codigoIbge: string;
    ufSigla: string;
    ufNome: string;
  };
  modalidadeId: number;
  modalidadeNome: string;
  modoDisputaId: number;
  modoDisputaNome: string;
  tipoInstrumentoConvocatorioCodigo: number;
  tipoInstrumentoConvocatorioNome: string;
  amparoLegal: {
    codigo: number;
    nome: string;
    descricao: string;
  };
  objetoCompra: string;
  informacaoComplementar: string;
  srp: boolean;
  dataPublicacaoPncp: string;
  dataAberturaProposta: string;
  dataEncerramentoProposta: string;
  situacaoCompraId: number;
  situacaoCompraNome: string;
  existeResultado: boolean;
  dataInclusao: string;
  dataAtualizacao: string;
  usuarioNome: string;
  itens: PncpItem[];
  historico: PncpHistorico[];
  arquivos: PncpArquivo[];
}

export interface ComprasnetLicitacao {
  orgao: string;
  uasg: string;
  modalidade: string;
  numero: string;
  objeto: string;
  dataEditalAPartirDe: string;
  endereco: string;
  telefone: string;
  fax: string;
  entregaProposta: string;
  downloadUrl: string;
  itens: Array<{
    numero: string;
    titulo: string;
    descricao: string;
    tratamentoDiferenciado: string;
    aplicabilidadeDecreto7174: string;
    aplicabilidadeMargemPreferencia: string;
    quantidade: number;
    unidadeFornecimento: string;
  }>;
}

export interface BanrisulDocumento {
  tipo: string;
  nome: string;
  tamanho: string;
  dataDisponibilizacao: string;
  downloadUrl: string;
}

export interface BanrisulLote {
  id: number;
  adjudicationDate: number | null;
  adjudicationUser: string | null;
  adjudicationValue: number | null;
  appealStatus: string;
  bidUnit: string | null;
  cancelDate: number | null;
  cancelReason: string | null;
  cancelUser: string | null;
  coordinator: string | null;
  decimalPositions: number;
  deltaPercentual: number | null;
  deltaValue: number | null;
  description: string | null;
  disputeTime: number;
  endDate: number;
  incrementalDelta: string;
  negativeValue: boolean | null;
  negotiating: boolean;
  number: number;
  openDisputeRestartStatus: string | null;
  openProposals: boolean;
  procurementId: number;
  simplifiedIssuanceNumber: string | null;
  proposalFileRequired: boolean;
  proposalsOpenDate: number;
  proposalsReceiveStartDate: number;
  proposalsReceiveEndDate: number;
  realStartDate: number;
  startDate: number;
  status: string;
  statusDescription: string;
  title: string;
  unitaryValue: boolean;
  processingType: string;
  approved: boolean;
  approvalDate: number | null;
  approvalUser: string | null;
}

export interface BanrisulLicitacao {
  situacao: string;
  centralDeCompras: string;
  participacao: string;
  processo: string;
  edital: string;
  modalidade: string;
  dataPublicacao: string;
  local: string;
  comissaoLicitacao: string;
  criterioJulgamento: string;
  modoDisputa: string;
  habilitacao: string;
  recursoAdministrativo: string;
  tipoObjeto: string;
  planilhaOrcamentoBase: string;
  objeto: string;
  orgaoRequisitante: string;
  transferenciaDeCurso: string;
  documentos: BanrisulDocumento[];
  lotes: BanrisulLote[];
}

export interface LicitarDigitalLote {
  id: number;
  auctionNoticeId: number;
  item: number;
  lotDescription: string;
  status: string | null;
  lotStage: string;
  bidAmountDifference: number;
  showReferenceValue: number;
  referenceValue: number;
  isItFrustrated: number;
  isItDesert: number;
  auctionNotice: {
    biddingStageId: number;
    isPhaseReversal: number;
  };
}

export interface LicitarDigitalDocument {
  type: number;
  fileDescription: string;
  createdAt: Date;
  url: string;
}

export interface LicitarDigitalLicitacao {
  biddingStageId: number;
  organizationUnit: {
    id: number;
    organizationUnitName: string;
    organization: {
      id: number;
      organizationName: string;
      exemptProviders: number;
    };
  };
  processNumber: string;
  processType: string;
  purcharseNumber: string;
  regionalBenefit: number;
  isCanceled: boolean;
  isPhaseReversal: number;
  isFinished: boolean;
  publishedDate: string;
  startDateTimeDispute: string | null;
  startDateTimeToSendProposal: string;
  endDateTimeToSendProposal: string;
  pncpLink: string | null;
  cancelType: string | null;
  decimalPlaces: number;
  deadlineCounterReason: string | null;
  deadlineIntentAppeal: string | null;
  deadlineAppeal: string | null;
  deadlineImpugnment: string | null;
  deadlineClarification: string | null;
  rule: {
    id: string;
    description: string;
    team: {
      operational: { description: string };
      executive: { description: string };
      support: { description: string };
    };
  };
  legalSupport: {
    id: number;
    description: string;
  };
  stage: {
    id: number;
    stageName: string;
  };
  segments: Array<{
    id: number;
    categoryName: string;
    level: number;
    masterId: number;
  }>;
  disputeDuration: number | null;
  simpleDescription: string;
  isFavorite: boolean;
  rateTypeBid: number;
  priceRegistration: {
    isItRideAllowed: boolean;
    monthsExpiration: number | null;
    status: boolean;
  };
  agreementOrTransfer: {
    origin: string | null;
    status: boolean;
  };
  judgeCriterion: {
    id: number;
  };
  disputeMethod: {
    id: number;
  };
  team: {
    operator: {
      id: number;
      name: string;
    };
    executive: {
      id: number;
      name: string;
    };
    supportTeam: Array<{
      id: number;
      name: string;
    }>;
  };
  lotes: LicitarDigitalLote[];
  documents: LicitarDigitalDocument[];
}

export interface PcpItem {
  descricao: string;
  unidade: string;
  quantidade: number;
  valorReferencia: number;
  codigo: number;
  participacao: { codigo: number; descricao: string };
  situacao: { codigo: number; descricao: string };
  tipoJulgamento: string;
  loteDescricao: string;
  loteCodigo: number;
}

export interface PcpDocumento {
  nome: string;
  url: string;
  tipo: string;
  dataHora: string;
  tipoDownLoad: number;
  tituloDocumento: string | null;
  parametros: any | null;
}

export interface PcpLicitacao {
  codigoLicitacao: number;
  numeroProcesso: string;
  cidadeEstadoComprador: string | null;
  razaoSocialComprador: string;
  codigoModalidade: number;
  tipoPregao: string;
  tipoLicitacao: string;
  dataHoraAbertura: string;
  dataHoraFechamento: string | null;
  dataHoraInicioRecebimentoPropostas: string;
  dataHoraFinalRecebimentoPropostas: string;
  dataHoraLimiteImpugnacoes: string;
  dataHoraLimiteEsclarecimentos: string;
  dataHoraLimiteRecebimentoPropostas: string | null;
  dataHoraPublicacao: string;
  codigoTipoJulgamento: number;
  tipoJulgamento: string;
  tratamentoFasesLances: string;
  operacao: string;
  nomePregoeiro: string | null;
  nomeAutoridadeCompetente: string;
  nomeApoio: string[];
  origemRecurso: string;
  aplicarDecreto10024: string;
  lei14133: boolean;
  leiDasEstatais13303: boolean;
  resumo: string;
  situacaoEdital: string | null;
  isTratamentoDiferenciado: boolean;
  isBeneficoLocal: boolean;
  statusProcesso: {
    codigo: number;
    descricao: string;
  };
  tipoOperacaoLote: string | null;
  exibirValorReferencia: boolean;
  casaDecimalValor: number;
  casaDecimalQuantidade: number;
  labelOperador: string;
  nomeOperador: string;
  codigoModalidadeLicitacao: number;
  legislacaoAplicavel: string;
  legislacaoInterna: string | null;
  codigoPerfilCompradorResponsavel: number;
  codigoChatVersao: number;
  codigoTipoLicitacao: number;
  codigoTipoPreQualificacao: number | null;
  decreto3555: boolean;
  codigoTipoProcesso: number | null;
  lei8666: boolean;
  enquadramentoLegal: number;
  documents: PcpDocumento[];
  items: PcpItem[];
}

export interface DouLicitacao {
  conteudo: string;
  versaoCertificadaLink: string | null;
  diarioCompletoLink: string | null;
  publicacaoDatetime: string | null;
  numeroEdital: string | null;
  pregoeiro: string | null;
  titulo: string | null;
}

export interface Bulletin {
  id: string;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
  userId: string;
  searchParams: LicitacoesSearchParams;
  licitacoesCount: number;
}
